import { Title } from "@mantine/core";
import { JsonTree } from "./json";

export const ShowError = (props: { error: Error }) => {
  return (
    <>
      <Title order={3}>Oops: {props.error.name}</Title>
      <pre>{props.error.message}</pre>
      <JsonTree data={props.error} />
    </>
  );
};
