import { useElementSize, useViewportSize } from "@mantine/hooks";
import { Content } from "../components/content";
import { GeoJsonMap } from "../components/map";
import { useConfig } from "../config";
import * as geojson from "../geojson";
import { FlinkPink } from "../themes";
import { HubDeliveryArea } from "../types";

export const RegionHubsView = (props: { prefix?: string }) => {
  const { height: pageHeight } = useViewportSize();
  const { ref } = useElementSize<HTMLDivElement>();

  return (
    <Content title="Hubs">
      <div ref={ref}>
        {ref.current && (
          <HubsMap
            prefix={props.prefix}
            height={pageHeight - ref.current.offsetTop - 60}
          />
        )}
      </div>
    </Content>
  );
};

const HubsMap = (props: { prefix?: string; height: number }) => {
  const config = useConfig();

  const prefix = props.prefix?.toLowerCase() || "";
  const filter = props.prefix
    ? (hub: HubDeliveryArea) => hub.slug.toLowerCase().startsWith(prefix)
    : () => true;

  const features: geojson.GeoJSONFeature[] = [];
  config.deliveryAreas.forEach((country) => {
    country.cities.forEach((city) => {
      city.delivery_areas.forEach((hub) => {
        if (!filter(hub)) {
          return;
        }

        const properties = {
          country: country.id.toLowerCase(),
          city: `${country.id}_${city.id}`.toLowerCase(),
          hub: hub.slug,
        };
        features.push(...geojson.polygons(hub.turfs, properties));
        features.push(
          geojson.point(hub.default_location, {
            color: FlinkPink,
            ...properties,
          }),
        );
      });
    });
  });

  return (
    <GeoJsonMap data={geojson.collection(...features)} height={props.height} />
  );
};
