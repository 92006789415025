import {
  Anchor,
  Button,
  Center,
  Flex,
  Grid,
  Image,
  Kbd,
  List,
  Modal,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { toNumber } from "vega";

type Images = Record<string, string>;

const importAll = (require: __WebpackModuleApi.RequireContext) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {} as Images);

const images = importAll(require.context("./img", false, /\.(png|jpe?g|svg)$/));

class Message {
  constructor(
    id: string,
    date: string,
    title: string,
    summary: string,
    content: JSX.Element,
  ) {
    this.id = id;
    this.date = date;
    this.title = title;
    this.summary = summary;
    this.content = content;
  }
  id: string;
  date: string;
  title: string;
  summary: string;
  content: JSX.Element;
}

const messages: Array<Message> = [
  new Message(
    "6",
    "2024-02-29",
    "Multi-Algorithm Stack Retracing",
    "The Stack Retrace tool allows you to select which stacking algorithm you want to experiment with for a given hubstate",
    (
      <Stack w={"98%"}>
        <Text>
          The "Stack Retrace" tool lets you select from a dropdown list of
          different stacking algorithms so that for a given hubstate you can see
          how our decision making would change if we stacked by different
          optimization criteria.
        </Text>
      </Stack>
    ),
  ),
  new Message(
    "5",
    "2023-06-30",
    "Raw State JSON",
    "The State JSON tab now contains the raw hub state JSON, not the version parsed by Zod.",
    (
      <Stack w={"98%"}>
        <Text>
          The State JSON tab now contains the raw JSON, which can be used
          directly in a variety of development use cases. Earlier there were
          some subtle differences, because we used the object as returned by the
          Zod parser.
        </Text>
      </Stack>
    ),
  ),
  new Message(
    "4",
    "2023-06-29",
    "Overhauled Trip View",
    "Trip popup opens in the center of the screen and includes order pills for its orders.",
    (
      <Stack w={"98%"}>
        <Text>
          The trip view opens when clicking the three vertical dots within a
          trip box to the right of the order pills. It now is always placed in
          the center of the window. This solves the awkward positioning of the
          earlier version that would often result in inaccessible content. This
          new arrangement also allowed us to do away the tabs and present all
          the information directly in the popup.
        </Text>
        <Text>
          Since the new popup can potentially hide the trip in the underlying
          view, it now includes the "trip box" with the orders that constitute
          this trip. The trip box in this context has the same features as in
          the trips and proposals view, including showing the currently selected
          order labels, all indicators, and the order hovercard.
        </Text>
        <Text>
          The trip view is now also accessible from the rider activity timeline.
          It can be openend by clicking on the bar representing the trip
          delivery over time.
        </Text>
        <Center>
          <Image
            fit={"contain"}
            src={images["trip_popup.png"]}
            m={16}
            mr={30}
          />
        </Center>
        <Text>
          In live mode, the trip box in the trip view will receive live updates,
          ensuring that it always displays the current state of the trip. The
          trip view will only close if the selected trip is removed from the
          state, which happens when the trip has been completed 2 hours ago or
          when a (tentative) trip proposal is restacked.
        </Text>
      </Stack>
    ),
  ),
  new Message(
    "3",
    "2023-05-17",
    "Simplified Targeted Time Travel",
    "The new and improved targeted time travel tool allows for intuitive navigation to specific historic hub states, using either a timestamp or an ID.",
    (
      <Stack w={"98%"}>
        <Text>
          The targeted time travel feature simplifies the navigation to specific
          historic hub states in the hub view, which is helpful when trying to
          understand or investigate a hub's history from the dispatching point
          of view.
        </Text>
        <Text>
          This feature can be found within the state hovercard, which opens when
          hovering over the state timestamp in the top of the hub view. Clicking
          one of the edit buttons or directly into the field to be edited starts
          the edit mode, which increases the close delay of the hovercard to 3
          seconds.
        </Text>
        <Center>
          <Image
            fit={"contain"}
            src={images["state-hovercard-edit.png"]}
            m={16}
            mr={30}
          />
        </Center>
        <Text>
          The selected field can then be edited directly, or, when editing the
          timestamp, the time can be set using a slider. The range of the slider
          depends on whether the current state is older than 24 hours. If it is,
          the slider ranges from -24 hours to +24 hours from the state's time.
          Otherwise, it ranges from 48 hours ago to the present. Each tick on
          the slider represents 4 hours.
        </Text>
        <Text>
          Please note that searching for a timestamp always returns the most
          recent state starting from the entered time. Therefore, long time
          periods without states (e.g., during the night) will be skipped,
          potentially resulting in states that are several hours older than the
          entered time.
        </Text>
        <Text>
          Click the check button to submit or hit the <Kbd>Enter</Kbd> key. To
          cancel click the X button or hit <Kbd>Esc</Kbd>.
        </Text>
        <Center sx={{ alignItems: "start" }}>
          <Image fit={"contain"} src={images["edit-id.png"]} m={16} />
          <Image fit={"contain"} src={images["edit-time.png"]} m={16} mr={30} />
        </Center>
      </Stack>
    ),
  ),
  new Message(
    "2",
    "2023-05-10",
    "Trip Timeline",
    "A new timeline in the trip view shows the progress of that trip and its orders, with an emphasis on the phases, events and PDT of each order.",
    (
      <Stack>
        <List listStyleType={"disc"} spacing={16} pr={40}>
          <List.Item>
            A timeline has been added to the trip view, that shows a trip's
            progress, which includes the phases, states and PDT of each order.
            The map and the timeline of the trip view facilitate investigation
            and understanding of the temporal and spatial stacking compatibility
            of orders.
          </List.Item>
          <List.Item>
            To open the trip view click the three dots in the trip container in
            the trips and proposals view.
          </List.Item>
          <Image fit={"contain"} src={images["trip_timeline_3.png"]} m={16} />
          <List.Item>
            The timeline shows the orders of the trip in their delivery sequence
            from top to bottom.
          </List.Item>
          <List.Item>
            The phases have the same coloring as in the trips and proposals view
            and the icons describe the phase transition events.
          </List.Item>
          <List.Item>
            The PDTs are shown as vertical bars with the coloring showing
            whether the PDT was kept (green) or not (red). When the delivery of
            an order is ongoing and the PDT has not been exceeded the bar is
            colored black. For these orders the promised delivery duration is
            displayed as a horizontal line.
          </List.Item>
          <List.Item>
            Each phase line, event icon and PDT bar has a tooltip with more
            information.
          </List.Item>
          <Center>
            <Image fit={"contain"} src={images["trip_timeline_1.png"]} m={10} />
            <Image fit={"contain"} src={images["trip_timeline_2.png"]} m={10} />
          </Center>
          <List.Item>
            The trip map now has the same coloring and form of sequence
            information as the hub area map.
          </List.Item>
        </List>
      </Stack>
    ),
  ),
  new Message(
    "1",
    "2023-05-02",
    "Release Notes / Message of the Day",
    "A convenient way of broadcasting important or interesting release notes of the dispatching UI to its users.",
    (
      <Stack>
        <List listStyleType={"disc"} spacing={16} pr={40}>
          <List.Item>
            New features or interesting updates that are introduced to the
            dispatching UI will now be displayed in the 'What's new' modal.
          </List.Item>
          <List.Item>
            Clicking 'Show more' marks the story as read, which prevents it from
            being shown again. The 'Mark all as seen' button does this for all
            unread stories.
          </List.Item>
          <Image
            fit={"contain"}
            src={images["whats_new.png"]}
            height={400}
            m={16}
          />
          <List.Item>
            A collection of all news stories since the introduction of this
            feature is available via the 'What's new' link with the newspaper
            icon in the navbar.
          </List.Item>
          <Image
            fit={"contain"}
            src={images["whats_new_navbar.png"]}
            height={400}
            m={16}
          />
        </List>
      </Stack>
    ),
  ),
  new Message(
    "0",
    "2023-05-02",
    "Navigation Update",
    "The navbar was overhauled and some functionality was moved around. " +
      "The trips and proposals view of the currently selected hub in the hub portal is the new landing page.",
    (
      <Grid columns={10} align={"flex-start"} w={"95%"}>
        <Grid.Col span={7}>
          <List listStyleType={"disc"} spacing={16} pr={20}>
            <List.Item>
              <b>Hubs:</b> Navigates to the trips and proposals view of the hub
              currently selected in the hub portal. That view is also the new
              landing page of the dispatching UI.
            </List.Item>
            <List.Item>
              <b>Orders:</b> Shows the 50 most recent orders from all hubs.
            </List.Item>
            <List.Item>
              <b>Map:</b> Shows a map with all hubs. Searching for a country or
              a city navigates the user here.
            </List.Item>
            <List.Item>
              <b>Documentation:</b> Opens the dispatching documentation in a new
              tab.
            </List.Item>
            <List.Item>
              <b>What's new:</b> Opens the 'What's new' modal, a list of all
              news stories since the introduction of this feature.
            </List.Item>
            <List.Item>
              <b>Search:</b> Search for a specific hub or any city or country
              flink operates in. The search can also be opened using the{" "}
              <Kbd>/</Kbd> key.
            </List.Item>
            <List.Item>
              <b>Settings:</b> Opens the settings modal. The download hub
              configuration button and the color scheme toggle can now be found
              here.
              <Image
                src={images["settings.png"]}
                height={150}
                fit={"contain"}
                caption={"Settings modal"}
                mt={16}
              />
            </List.Item>
            <List.Item>
              <b>Toggle Navbar:</b> Toggles the width of the navbar.
            </List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={3}>
          <Image
            src={images["navbar_items.png"]}
            fit={"contain"}
            caption={"Dispatching navbar"}
          />
        </Grid.Col>
      </Grid>
    ),
  ),
];

const MessageModal = (props: {
  message: Message;
  isOpened: boolean;
  onClose: () => void;
}) => {
  const { height } = useViewportSize();
  return (
    <Modal
      opened={props.isOpened}
      onClose={props.onClose}
      title={
        <>
          <Title order={3}>{props.message.title}</Title>
          <Text>{props.message.date}</Text>
        </>
      }
      size={1000}
    >
      <ScrollArea.Autosize maxHeight={height - 220} type={"auto"}>
        {props.message.content}
      </ScrollArea.Autosize>
    </Modal>
  );
};

const MessageSummary = (props: { message: Message; onClick: () => void }) => {
  const [isMessageOpened, setIsMessageOpened] = useState(false);
  return (
    <Paper shadow="sm" radius="md" p={"xs"} key={props.message.id} withBorder>
      <Flex
        direction={"row"}
        justify="space-between"
        align={"baseline"}
        mb={6}
        sx={{ width: "100%" }}
      >
        <Title order={3}>{props.message.title}</Title>
        <Text>{props.message.date}</Text>
      </Flex>
      <Text mb={6}>{props.message.summary}</Text>
      <Anchor
        onClick={() => {
          setIsMessageOpened(true);
          if (props.onClick) props.onClick();
        }}
      >
        Show more
      </Anchor>
      <MessageModal
        message={props.message}
        isOpened={isMessageOpened}
        onClose={() => setIsMessageOpened(false)}
      />
    </Paper>
  );
};

export const Motd = (props: {
  showAll: boolean;
  setShowAll: (value: boolean) => void;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [seenMotds] = useState<number[]>(
    localStorage.getItem("motd")?.split(",").sort().reverse().map(toNumber) ||
      [],
  );

  useEffect(() => {
    if (seenMotds.length < messages.length) {
      setIsOpened(true);
    }
  }, []);

  const addIndexToStorage = (id: number) => {
    const motdIds = localStorage.getItem("motd")?.split(",") || [];
    motdIds.push(id + "");
    localStorage.setItem("motd", [...new Set(motdIds)].join(","));
  };

  if (seenMotds.length === messages.length - 1 && !props.showAll) {
    localStorage.setItem("motd", [...Array(messages.length).keys()].join(","));
    const message = messages.filter(
      (message, i) => !seenMotds.includes(messages.length - i - 1),
    );
    return (
      <MessageModal
        message={message[0]}
        isOpened={isOpened}
        onClose={() => setIsOpened(false)}
      />
    );
  }

  return (
    <Modal
      opened={isOpened || props.showAll}
      size={800}
      withCloseButton
      onClose={() => {
        setIsOpened(false);
        props.setShowAll(false);
      }}
      title={<Title order={2}>What's New?</Title>}
    >
      <ScrollArea.Autosize
        maxHeight={600}
        offsetScrollbars
        type={"hover"}
        pb={0}
      >
        <Stack spacing={"sm"}>
          {messages.map((message, i) => {
            if (props.showAll || !seenMotds.includes(messages.length - i - 1)) {
              return (
                <div key={message.id}>
                  <MessageSummary
                    message={message}
                    onClick={() => {
                      if (!props.showAll) {
                        addIndexToStorage(messages.length - i - 1);
                      }
                    }}
                  />
                </div>
              );
            }
          })}
        </Stack>
      </ScrollArea.Autosize>
      {!props.showAll && (
        <>
          <Space h={"sm"}></Space>
          <Button
            onClick={() => {
              localStorage.setItem(
                "motd",
                [...Array(messages.length).keys()].join(","),
              );
              setIsOpened(false);
            }}
            variant={"outline"}
          >
            Mark all as seen
          </Button>
        </>
      )}
    </Modal>
  );
};
