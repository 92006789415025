import { useConfig } from "../config";
import { GeoCoordinates } from "../geojson";
import {
  calculateBearing,
  convertToCircleAngle,
  farthestDistanceToEdgeOfPolygon,
  getBearingOfArrowHeadLine,
  greatCircleDistance,
  oppositeBearing,
  pointOnCircle,
} from "../geometric-funcs";
import { HubDeliveryArea, Order } from "../types";

const arrowHeadLength = 5;
const baseArrowLength = 12;

export const ArrowSVG = (props: { order: Order; color: string }) => {
  const config = useConfig();
  const hub = config.hubs.get(props.order.Hub);

  if (!hub) {
    return <></>;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      stroke-width="2"
      stroke-linecap="round"
    >
      {atHubOrder(props.order.DeliveryCoords, hub.default_location) ? (
        <Point color={props.color} />
      ) : (
        <Arrow
          hub={hub}
          orderLocation={props.order.DeliveryCoords}
          color={props.color}
        />
      )}

      <circle
        cx={10}
        cy={10}
        r={9}
        style={{ fill: "none", ...arrowStyle(props.color) }}
      />
    </svg>
  );
};

const Point = (props: { color: string }) => (
  <circle cx={10} cy={10} r={2} style={{ fill: props.color }} />
);

const Arrow = (props: {
  hub: HubDeliveryArea;
  orderLocation: GeoCoordinates;
  color: string;
}) => {
  const style = arrowStyle(props.color);
  const modifier = distanceModifier(props.hub, props.orderLocation);
  const bearing = convertToCircleAngle(
    calculateBearing(props.hub.default_location, props.orderLocation),
  );

  const p1 = pointOnCircle((baseArrowLength / 2) * modifier, bearing, {
    x: 10,
    y: 10,
  });

  const p2 = pointOnCircle(
    baseArrowLength * modifier,
    oppositeBearing(bearing),
    p1,
  );
  const arrowHead1Bearing = getBearingOfArrowHeadLine(bearing, true);
  const arrowHead2Bearing = getBearingOfArrowHeadLine(bearing, false);
  const p3 = pointOnCircle(arrowHeadLength, arrowHead1Bearing, p1);
  const p4 = pointOnCircle(arrowHeadLength, arrowHead2Bearing, p1);

  return (
    <>
      <line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} style={style} />
      <line x1={p1.x} y1={p1.y} x2={p3.x} y2={p3.y} style={style} />
      <line x1={p1.x} y1={p1.y} x2={p4.x} y2={p4.y} style={style} />
    </>
  );
};

const arrowStyle = (color: string) => {
  return {
    stroke: color,
    strokeWidth: "1",
  };
};

function distanceModifier(hub: HubDeliveryArea, orderLocation: GeoCoordinates) {
  const maxDistance = farthestDistanceToEdgeOfPolygon(
    hub.default_location,
    hub.turfs,
  );
  const distanceToOrder = greatCircleDistance(
    hub.default_location,
    orderLocation,
  );
  return Math.sqrt(distanceToOrder / maxDistance);
}

function emptyCoordinates(coordinates: GeoCoordinates) {
  return coordinates.latitude === 0 && coordinates.longitude === 0;
}

const atHubOrder = (
  orderCoords: GeoCoordinates,
  hubCoords: GeoCoordinates,
): boolean => {
  return (
    emptyCoordinates(orderCoords) ||
    (orderCoords.latitude === hubCoords.latitude &&
      orderCoords.longitude === hubCoords.longitude)
  );
};
