import { Drawer, Flex, Group, Text, Title, Tooltip } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import {
  IconBike,
  IconBinaryTree,
  IconBraces,
  IconBuildingWarehouse,
  IconCalendarTime,
  IconClipboardList,
  IconFlag3,
  IconListDetails,
  IconMap2,
  IconPackages,
  IconPlugConnected,
  IconPlugOff,
} from "@tabler/icons";
import dateFormat from "dateformat";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "wouter";
import { z } from "zod";
import {
  ApiResource,
  HubStateEstimates,
  useHubData,
  useHubState,
} from "../../api";
import { ActionIcon } from "../../components/action-icon";
import { BottleneckHoverCard } from "../../components/bottlenecks-hovercard";
import { ShowError } from "../../components/errors";
import { Loading } from "../../components/loading";
import { StateHoverCard } from "../../components/state-hovercard";
import { AddressableTabs } from "../../components/tabs";
import { useApiResource } from "../../config";
import { useHashParam } from "../../hooks";
import { safeJsonParse, tripDeliveries } from "../../methods";
import { routes } from "../../nav";
import {
  HubSlug,
  HubState,
  HubStateHistorySummary,
  OrderID,
  OrderIDSet,
  scheduledTripProposal,
  Trip,
  UUID,
} from "../../types";
import { StateJson } from "../state-json";
import { FeatureFlags } from "./flags";
import { HubAreaView } from "./hub-area-map";
import { HubMetrics } from "./hub-metrics";
import {
  DeliveryTrip,
  HubTripsAndProposalsOverview,
} from "./hub-trips-and-proposals-overview";
import { PlannedWindowsView } from "./planned-windows";
import { RiderShiftView } from "./rider-shifts";
import { RiderTimeline } from "./rider-timeline";
import { StackingRetraceView } from "./stacking-retrace";
import { StateHistoryNavigation } from "./state-navigation";
import { FullStateTimeline } from "./timeline";
import { TripCard } from "./trip-card";

export enum HashParams {
  Tab = "tab",
  Live = "live",
  Timestamp = "timestamp",
  StateId = "state_id",
  OrderLabels = "order_labels",
  MapViewSelection = "mapview_selection",
  SelectedOrder = "order",
  FancyColors = "color",
}

export const HubStateSearchResolver = (props: { stateId: UUID }) => {
  const result = useHubState({ id: props.stateId });
  const [selectedOrder] = useHashParam(HashParams.SelectedOrder);
  const [, setLocation] = useLocation();

  useEffect(() => {
    if (result.data) {
      setLocation(
        routes.hubs.sub.bySlug.link(
          { slug: result.data.State.Hub },
          {
            [HashParams.StateId]: result.data.State.ID,
            [HashParams.SelectedOrder]: selectedOrder,
          },
        ),
      );
    }
  }, [result.data]);

  if (result.loading) {
    return <Loading />;
  }

  if (result.error) {
    return <ShowError error={result.error} />;
  }

  return <></>;
};

export const HubView = (props: { slug: HubSlug }) => {
  const [selectedOrder, setSelectedOrder] = useHashParam(
    HashParams.SelectedOrder,
  );
  const [openedTripId, setOpenedTripId] = useState<string>();
  const [stateId, setStateId] = useHashParam(HashParams.StateId);
  const [rawTimestamp, setRawTimestamp] = useHashParam(HashParams.Timestamp);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showHubMetrics, setShowHubMetrics] = useState(false);
  const [isLive, setIsLive] = useState(!(stateId || rawTimestamp));
  const timestamp = !rawTimestamp ? undefined : new Date(rawTimestamp);
  const lastStateId = useRef<UUID>();
  const [isFirstHubLoad, setIsFirstHubLoad] = useState(!isLive);

  useEffect(() => {
    if (!isLive) {
      setIsFirstHubLoad(true);
    }
  }, [props.slug]);

  const hubStateResource = useHubState(
    stateId
      ? { hub: props.slug, id: stateId }
      : {
          hub: props.slug,
          timestamp,
        },
  );

  const hubData = useHubData(hubStateResource);

  const response = useApiResource(
    "/historic_states",
    z.array(HubStateHistorySummary),
    {
      manual: !(
        hubStateResource.data &&
        isLive &&
        !isFirstHubLoad &&
        lastStateId.current !== hubStateResource.data.State.ID
      ),
      params: {
        hub: hubStateResource.data?.State.Hub,
        around_id: hubStateResource.data?.State.ID,
        time: dateFormat(new Date(), "HH:MM"),
      },
    },
  );
  const history = response.data;

  const hubStateResourceRef = useRef(hubStateResource);
  hubStateResourceRef.current = hubStateResource;
  const autoRefresh = useInterval(
    () => hubStateResourceRef.current.refetch(),
    10_000,
  );
  useEffect(() => {
    if (isLive) {
      hubStateResource.refetch();
      autoRefresh.start();
    }
    return autoRefresh.stop;
  }, [isLive]);

  useEffect(() => {
    if (selectedOrderNotInState(selectedOrder, hubStateResource.data?.State)) {
      setSelectedOrder(undefined);
    }
  }, [hubStateResource.data]);

  const toggleLive = () => {
    if (isLive) {
      setStateId(hubStateResource.data?.State.ID);
      setRawTimestamp(hubStateResource.data?.State.Timestamp.toISOString());
    } else {
      setStateId(undefined);
      setRawTimestamp(undefined);
    }
    setIsLive(!isLive);
  };

  const selectedIndex =
    hubStateResource.data && history
      ? history.findIndex((s) => s.ID === hubStateResource.data?.State.ID)
      : 0;

  useEffect(() => {
    // Fetches the history when a hub is loaded for the first time
    if (isFirstHubLoad) {
      if (hubStateResource.data) {
        response.refetch();
        setIsFirstHubLoad(false);
      }
      return;
    }

    // Refetch the history when its edge is reached
    if (
      !isLive &&
      hubStateResource.data &&
      (!history ||
        selectedIndex === 0 ||
        selectedIndex === -1 ||
        selectedIndex === history.length - 1)
    ) {
      response.refetch();
    }
  }, [hubStateResource.data]);

  useEffect(() => {
    if (stateId) {
      lastStateId.current = stateId;
    }
  }, [stateId]);

  const openedTrip = useMemo(() => {
    if (hubStateResource.data) {
      return getDeliveryTrips(hubStateResource.data.State).find(
        (trip) => getDeliveryTripId(trip) === openedTripId,
      );
    }
  }, [openedTripId, hubStateResource.data]);

  return (
    <ApiResource accessor={() => hubStateResource}>
      {(data) => {
        return (
          <>
            <Flex left={80} justify="space-between" align="baseline" mb="md">
              <Group sx={{ minWidth: "30%" }}>
                <Title size="h3" mr={20}>
                  {data.State.Hub}
                </Title>
                <BottleneckHoverCard
                  bottlenecks={data.State.Bottlenecks}
                  timestamp={data.State.Timestamp}
                  prevailingBottleneck={data.prevailing_bottleneck}
                />
                {typeof data.pickers_needed === "number" && (
                  <Text>{data.pickers_needed} Pickers Needed</Text>
                )}
              </Group>
              <Flex justify="center" align="center" mb="md" gap={10} w={400}>
                <StateHistoryNavigation
                  response={response}
                  selectedIndex={selectedIndex}
                  isLive={isLive}
                  exitLive={() => {
                    if (isLive) toggleLive();
                  }}
                  setStateId={setStateId}
                  currentTime={data.State.Timestamp}
                  setTimestamp={setRawTimestamp}
                >
                  <StateHoverCard
                    state={data.State}
                    width={254}
                    exitLiveMode={() => setIsLive(false)}
                  />
                </StateHistoryNavigation>
              </Flex>
              <Group sx={{ width: "30%" }} position={"right"}>
                {hubData.representativeEstimate?.data && (
                  <Tooltip
                    label={
                      "The representative delivery time estimated independently of a particular delivery location or cart contents"
                    }
                  >
                    <Text>
                      {"Rep. DT: " +
                        Math.round(hubData.representativeEstimate?.data) +
                        " min"}
                    </Text>
                  </Tooltip>
                )}
                <ActionIcon
                  onClick={() => {
                    setShowHubMetrics(!showHubMetrics);
                  }}
                  icon={<IconBuildingWarehouse size={18} />}
                  tooltip={"Show Hub Metrics"}
                />
                <ActionIcon
                  onClick={() => {
                    setShowTimeline(!showTimeline);
                    if (isLive) toggleLive();
                    else response.refetch();
                  }}
                  icon={<IconListDetails size={18} />}
                  tooltip={"Open Timeline"}
                />
                <ActionIcon
                  color={isLive ? "green" : "yellow"}
                  onClick={() => toggleLive()}
                  icon={
                    isLive ? (
                      <IconPlugConnected size={18} />
                    ) : (
                      <IconPlugOff size={18} />
                    )
                  }
                  tooltip={isLive ? "Start History Mode" : "Start Live Mode"}
                />
              </Group>
            </Flex>
            <Drawer
              opened={showTimeline}
              onClose={() => setShowTimeline(false)}
              title="Timeline"
              padding="md"
              size="xl"
              position="right"
              withOverlay={false}
              lockScroll={false}
            >
              <FullStateTimeline
                hub={props.slug}
                stateId={data.State.ID}
                setStateId={setStateId}
                response={response}
              />
            </Drawer>
            <Drawer
              opened={showHubMetrics}
              onClose={() => setShowHubMetrics(false)}
              title="Hub Metrics"
              padding="md"
              size={"md"}
              position="right"
              withOverlay={false}
              lockScroll={false}
            >
              <HubMetrics state={data.State} />
            </Drawer>
            <AddressableTabs
              param={HashParams.Tab}
              tabs={getHubViewTabs({
                state: data.State,
                rawState: hubStateResource.raw,
                withheldIDs: data.withheld_from_picking,
                openedTripId: openedTripId,
                setOpenedTripId: (tripID) => setOpenedTripId(tripID),
                proposalSchedule: hubData.proposalSchedule?.data,
                estimates: hubData.hubstateEstimates?.data,
              })}
            />
            <TripCard
              trip={openedTrip}
              state={data.State}
              setOpenedTripId={setOpenedTripId}
              withheldFromPicking={data.withheld_from_picking}
              scheduledTrip={hubData.proposalSchedule?.data?.find(
                (prop) => prop.order_ids.join("") === openedTripId,
              )}
              estimates={hubData.hubstateEstimates?.data}
            />
          </>
        );
      }}
    </ApiResource>
  );
};

const getHubViewTabs = (props: {
  state: HubState;
  rawState?: string;
  withheldIDs: OrderIDSet;
  openedTripId?: string;
  setOpenedTripId: (tripID?: string) => void;
  proposalSchedule?: scheduledTripProposal[];
  estimates?: HubStateEstimates;
}) => {
  const state = (() => {
    const rawState = safeJsonParse(props.rawState);
    if (rawState) {
      return rawState.State;
    }
    return undefined;
  })();

  return [
    {
      key: "trips-and-proposals",
      title: "Trips and Proposals",
      icon: <IconPackages size={14} />,
      content: (
        <HubTripsAndProposalsOverview
          state={props.state}
          withheldFromPicking={props.withheldIDs}
          slug={props.state.Hub}
          setOpenedTripId={props.setOpenedTripId}
          proposalSchedule={props.proposalSchedule}
        />
      ),
    },
    {
      key: "area",
      title: "Area",
      icon: <IconMap2 size={14} />,
      content: (
        <HubAreaView
          state={props.state}
          withheldFromPicking={props.withheldIDs}
        />
      ),
    },
    {
      key: "delivery-windows",
      title: "Delivery Windows",
      icon: <IconCalendarTime size={14} />,
      content: (
        <PlannedWindowsView
          stateId={props.state.ID}
          revision={props.state.Revision}
        />
      ),
    },
    {
      key: "riders",
      title: "Rider Shifts",
      icon: <IconClipboardList size={14} />,
      content: (
        <RiderShiftView
          riders={Object.values(props.state.Riders)}
          time={props.state.Timestamp}
        />
      ),
    },
    {
      key: "rider-timeline",
      title: "Rider Activity",
      icon: <IconBike size={14} />,
      content: (
        <RiderTimeline
          state={props.state}
          withheldFromPicking={props.withheldIDs}
          estimates={props.estimates}
        />
      ),
    },
    {
      key: "flags",
      title: "Feature Flags",
      icon: <IconFlag3 size={14} />,
      content: <FeatureFlags hub={props.state.Hub} />,
    },
    {
      key: "json",
      title: "State JSON",
      icon: <IconBraces size={14} />,
      content: <StateJson rawState={state} />,
    },
    {
      icon: <IconBinaryTree size={14} />,
      key: "stacking-retrace",
      title: "Stacking Retrace",
      content: (
        <StackingRetraceView
          state={props.state}
          withheldFromPicking={props.withheldIDs}
        />
      ),
    },
  ];
};

const selectedOrderNotInState = (
  selectedOrder: OrderID | null,
  state: HubState | undefined,
): boolean => {
  if (selectedOrder && state) {
    const trips = [
      ...state.Trips,
      ...state.TripProposals,
      ...state.OutsourcedTrips,
    ];
    return !trips
      .flatMap((t) => tripDeliveries(t))
      .some((o) => o.ID === selectedOrder);
  }
  return false;
};

export const getDeliveryTripId = (trip: DeliveryTrip): string => {
  if (trip.hasOwnProperty("ID")) {
    return (trip as Trip).ID;
  }
  return tripDeliveries(trip).reduce((id, order) => {
    id += order.ID;
    return id;
  }, "");
};

export const getDeliveryTrips = (state: HubState): DeliveryTrip[] => {
  return [...state.Trips, ...state.TripProposals, ...state.OutsourcedTrips];
};
