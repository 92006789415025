import { Accordion, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons";
import { ReactNode } from "react";

export const Collapsible = (params: {
  label: string;
  children: ReactNode;
  inTable?: boolean;
}) => {
  return (
    <Accordion
      variant="filled"
      chevronPosition="left"
      chevron={<IconChevronRight size="1rem" />}
      styles={{
        chevron: {
          "&[data-rotate]": {
            transform: "rotate(90deg)",
          },
        },
      }}
    >
      <Accordion.Item value="data" p={-5}>
        <Accordion.Control p={params.inTable ? 8 : ""}>
          <Text transform={params.inTable ? "none" : "uppercase"}>
            {params.label}
          </Text>
        </Accordion.Control>
        <Accordion.Panel p={-5}>{params.children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
