import { Stack, Textarea } from "@mantine/core";
import { useState } from "react";
import { useApiResource } from "../config";
import { DeliveryOptionValidation } from "../types";

export const TokenTool = () => {
  const [tokenText, setTokenText] = useState("");

  const tokenResponse = useApiResource(
    "/decode_delivery_option_token",
    DeliveryOptionValidation,
    { params: { token: tokenText.trim() } },
  );

  return (
    <Stack>
      <Textarea
        label={"Raw Token"}
        placeholder={"Paste Delivery Option Token Here"}
        value={tokenText}
        onChange={(event) => setTokenText(event.currentTarget.value)}
      />
      <Textarea
        label="Message from Decoder"
        value={tokenResponse.data?.message}
      />
      <Textarea
        minRows={8}
        label="Delivery Option"
        error={!tokenResponse.data?.valid}
        value={JSON.stringify(tokenResponse.data?.delivery_option, null, 2)}
      />
    </Stack>
  );
};
