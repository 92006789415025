import { Stack } from "@mantine/core";
import { ReactElement, useMemo, useState } from "react";
import { GeoJsonMap } from "../components/map";
import { collection, point } from "../geojson";
import { FlinkPink, FlinkProposalBlue, FlinkTripPurple } from "../themes";
import { HubState } from "../types";
import { deliverableFeatures, isEmptyDate } from "../utils";
import { HubTripsAndProposalsOverview } from "./hubs/hub-trips-and-proposals-overview";
import { getDeliveryTripId, getDeliveryTrips } from "./hubs/hubs";
import { TripCard } from "./hubs/trip-card";

export const StaticHubState = (props: {
  hubState: HubState | undefined;
}): ReactElement => {
  var hubState = props.hubState;

  const [openedTripId, setOpenedTripId] = useState<string>();

  const openedTrip = useMemo(() => {
    if (hubState) {
      return getDeliveryTrips(hubState).find(
        (trip) => getDeliveryTripId(trip) === openedTripId,
      );
    }
  }, [openedTripId, hubState]);

  return (
    (hubState && (
      <Stack>
        <>
          <GeoJsonMap
            data={collection(
              point(hubState.Coordinates, { color: FlinkPink }),
              ...deliverableFeatures(hubState.TripProposals, FlinkProposalBlue),
              ...deliverableFeatures(
                hubState.Trips.filter((t) => isEmptyDate(t.CompletedAt)),
                FlinkTripPurple,
              ),
            )}
          />
          <HubTripsAndProposalsOverview
            state={hubState}
            withheldFromPicking={[]}
            slug={hubState.Hub}
            setOpenedTripId={setOpenedTripId}
          />
        </>
        <TripCard
          trip={openedTrip}
          setOpenedTripId={setOpenedTripId}
          state={hubState}
          withheldFromPicking={[]}
        />
      </Stack>
    )) || <></>
  );
};
