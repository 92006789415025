import { Checkbox, Group, Stack, Text } from "@mantine/core";
import { useElementSize, useViewportSize } from "@mantine/hooks";
import { useEffect, useMemo } from "react";
import { GeoJsonMap } from "../../components/map";
import { OrderCard } from "../../components/order-card";
import { useConfig } from "../../config";
import * as geojson from "../../geojson";
import { useHashParam } from "../../hooks";
import { tripDeliveries } from "../../methods";
import {
  DeliveredGreen,
  FlinkPink,
  FlinkProposalBlue,
  FlinkTripPurple,
  OutsourcedTripOrange,
  SelectedOrderYellow,
} from "../../themes";
import {
  HubDeliveryArea,
  HubState,
  OrderIDSet,
  OutsourcedTrip,
  Trip,
  TripProposal,
} from "../../types";
import { getLatestEvent, isEmptyDate, zip } from "../../utils";
import { HashParams } from "./hubs";

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export const HubAreaView = (props: {
  state: HubState;
  withheldFromPicking: OrderIDSet;
  navigation?: boolean;
}) => {
  const [mapViewSelection, setMapViewSelection] = useHashParam(
    HashParams.MapViewSelection,
  );
  const hub = useConfig().hubs.get(props.state.Hub);
  const checkedItems = (mapViewSelection?.split(",") ||
    []) as CheckedLegendItems;

  useEffect(() => {
    if (!mapViewSelection || mapViewSelection.length === 0) {
      setMapViewSelection("hub_turfs,flink_proposal");
    }
  }, []);

  if (!hub) {
    return (
      <Text size={"lg"} weight={"bold"}>
        Unknown Hub Area
      </Text>
    );
  }

  return (
    <Group align={"flex-start"}>
      <HubAreaMap
        state={props.state}
        hub={hub}
        checkedItems={checkedItems}
        withheldFromPicking={props.withheldFromPicking}
        navigation={props.navigation}
      />
      <Stack spacing={0}>
        <Text size={"lg"} weight={"bold"}>
          Legend
        </Text>
        <Checkbox.Group
          value={checkedItems}
          onChange={(items) => {
            setMapViewSelection(items.join(","));
          }}
        >
          <Stack>
            {Object.entries(
              groupBy(Object.values(legendItems), (i) => i.category),
            ).map(([k, v]) => (
              <div key={k}>
                <Text size={"md"} mb={10}>
                  {k}:
                </Text>
                {v.map((item) => (
                  <div key={item.value} style={{ marginBottom: "10px" }}>
                    {item.category === "Color" ? (
                      <Checkbox
                        value={item.value}
                        label={
                          <LegendItem
                            label={item.label}
                            color={item.color}
                            shape={item.shape}
                          />
                        }
                        data-dd-action-name={"Hub area map selection"}
                      />
                    ) : (
                      <LegendItem
                        label={item.label}
                        color={item.color}
                        shape={item.shape}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Stack>
        </Checkbox.Group>
      </Stack>
    </Group>
  );
};

const LegendItem = (props: { label: string; shape: string; color: string }) => (
  <Group ml={6}>
    <div
      style={
        props.shape === "square"
          ? legendSquare(props.color)
          : props.shape === "circle"
          ? legendCircle
          : legendLine
      }
    ></div>
    <Text size={"md"}>{props.label}</Text>
  </Group>
);

const HubAreaMap = (props: {
  state: HubState;
  hub: HubDeliveryArea;
  checkedItems: CheckedLegendItems;
  withheldFromPicking: OrderIDSet;
  navigation?: boolean;
}) => {
  const [selectedOrder, setSelectedOrder] = useHashParam(
    HashParams.SelectedOrder,
  );
  const { width: pageWidth, height: pageHeight } = useViewportSize();
  const { ref } = useElementSize<HTMLDivElement>();

  const collection = useMemo(() => {
    return geojson.collection(
      ...props.checkedItems
        .reduce((acc, curr) => {
          if (curr === "hub_turfs") {
            return [...acc, ...legendItems[curr].func(props.hub)];
          } else if (
            curr === "flink_trip" ||
            curr === "flink_proposal" ||
            curr === "completed" ||
            curr == "outsourced"
          ) {
            return [
              ...acc,
              ...legendItems[curr].func(
                props.state,
                { get: selectedOrder, set: setSelectedOrder },
                props.withheldFromPicking,
              ),
            ];
          }
          return acc;
        }, [] as geojson.GeoJSONFeature[])
        .flat(1),
      geojson.point(props.hub.default_location, {
        color: FlinkPink,
        hub: props.hub.slug,
      }),
      ...geojson.polygons(props.hub.turfs, {
        color: FlinkPink + "00",
        fill: FlinkPink + "00",
      }),
    );
  }, [props.hub, props.state, props.checkedItems]);

  return (
    <div
      ref={ref}
      style={{ width: pageWidth - 450 }}
      data-dd-action-name={"Hub area map"}
    >
      {ref.current && (
        <GeoJsonMap
          data={collection}
          height={pageHeight - ref.current.offsetTop - 60}
          navigation={props.navigation}
        />
      )}
    </div>
  );
};

type HashParam = {
  get: string | null;
  set: (label: string) => void;
};

type TripGeojsonProps = {
  trips: Array<Trip | OutsourcedTrip | TripProposal>;
  color: string | null;
  orderHash: HashParam;
  withheldFromPicking?: OrderIDSet;
  time: Date;
};

const getGeojsonOfTrip = (
  props: TripGeojsonProps,
): geojson.GeoJSONFeature[] => {
  return [
    ...props.trips.flatMap((t) => {
      const tripCompleted =
        !props.withheldFromPicking &&
        !isEmptyDate((t as Trip | OutsourcedTrip).CompletedAt);
      return [
        ...tripDeliveries(t).flatMap((o) => {
          const latestEvent = getLatestEvent(o.Timestamps.Events);

          return [
            ...(o.ID === props.orderHash.get
              ? [
                  geojson.point(o.DeliveryCoords, {
                    selected: true,
                    color: SelectedOrderYellow,
                  }),
                ]
              : []),
            geojson.point(o.DeliveryCoords, {
              orderId: o.ID,
              onDoubleclick: () => {
                props.orderHash.set(o.ID);
              },
              orderCard: (
                <OrderCard
                  order={o}
                  isWithheld={
                    props.withheldFromPicking?.includes(o.ID) || false
                  }
                  isFilled={
                    !(
                      tripCompleted ||
                      latestEvent === "Created" ||
                      latestEvent === "Rider Claimed"
                    )
                  }
                  time={props.time}
                />
              ),
              color:
                o.Timestamps.Events.delivered.Time !== undefined
                  ? DeliveredGreen
                  : props.color,
            }),
          ];
        }),
        ...zip(tripDeliveries(t), tripDeliveries(t).slice(1))
          .slice(0, -1)
          .flatMap(([o1, o2]) =>
            geojson.lineWithArrow([o1.DeliveryCoords, o2.DeliveryCoords], {
              color: tripCompleted ? DeliveredGreen : props.color,
            }),
          ),
      ];
    }),
  ];
};

const legendItems = {
  order: {
    label: "Order",
    color: "gray",
    shape: "circle",
    category: "Shapes",
    value: "order",
  },
  trip: {
    label: "Trip",
    color: "gray",
    shape: "line",
    category: "Shapes",
    value: "trip",
  },
  hub_turfs: {
    label: "Hub Turfs",
    color: FlinkPink,
    shape: "square",
    category: "Color",
    value: "hub_turfs",
    func: (hub: HubDeliveryArea): geojson.GeoJSONFeature[] => {
      return [...geojson.polygons(hub.turfs)];
    },
  },
  completed: {
    label: "Completed",
    color: DeliveredGreen,
    shape: "square",
    category: "Color",
    value: "completed",
    func: (state: HubState, orderHash: HashParam): geojson.GeoJSONFeature[] => {
      return [
        ...getGeojsonOfTrip({
          trips: [
            ...state.Trips.filter((t) => !isEmptyDate(t.CompletedAt)),
            ...state.OutsourcedTrips.filter((t) => !isEmptyDate(t.CompletedAt)),
          ],
          color: DeliveredGreen,
          orderHash: orderHash,
          time: state.Timestamp,
        }),
      ];
    },
  },
  flink_trip: {
    label: "Active Flink Trip",
    color: FlinkTripPurple,
    shape: "square",
    category: "Color",
    value: "flink_trip",
    func: (state: HubState, orderHash: HashParam): geojson.GeoJSONFeature[] => {
      return [
        ...getGeojsonOfTrip({
          trips: state.Trips.filter((t) => isEmptyDate(t.CompletedAt)),
          color: FlinkTripPurple,
          orderHash: orderHash,
          time: state.Timestamp,
        }),
      ];
    },
  },
  flink_proposal: {
    label: "Trip Proposal",
    color: FlinkProposalBlue,
    shape: "square",
    category: "Color",
    value: "flink_proposal",
    func: (
      state: HubState,
      orderHash: HashParam,
      withheldFromPicking: OrderIDSet,
    ): geojson.GeoJSONFeature[] => {
      return [
        ...getGeojsonOfTrip({
          trips: state.TripProposals,
          color: FlinkProposalBlue,
          orderHash: orderHash,
          withheldFromPicking: withheldFromPicking,
          time: state.Timestamp,
        }),
      ];
    },
  },
  outsourced: {
    label: "Active Outsourced Trip",
    color: OutsourcedTripOrange,
    shape: "square",
    category: "Color",
    value: "outsourced",
    func: (state: HubState, orderHash: HashParam): geojson.GeoJSONFeature[] => {
      return [
        ...getGeojsonOfTrip({
          trips: state.OutsourcedTrips.filter((t) =>
            isEmptyDate(t.CompletedAt),
          ),
          color: OutsourcedTripOrange,
          orderHash: orderHash,
          time: state.Timestamp,
        }),
      ];
    },
  },
};

type CheckedLegendItems = Array<keyof typeof legendItems>;

const legendSquare = (color: string) => ({
  backgroundColor: color,
  width: "10px",
  height: "10px",
});

const legendCircle = {
  backgroundColor: "gray",
  width: "10px",
  height: "10px",
  borderRadius: "40px",
};

const legendLine = {
  backgroundColor: "gray",
  width: "10px",
  height: "3px",
};
