/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  AppShell,
  Box,
  Center,
  ColorSchemeProvider,
  MantineProvider,
  Navbar,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { NotificationsProvider } from "@mantine/notifications";
import { openSpotlight } from "@mantine/spotlight";
import {
  IconArrowBarLeft,
  IconArrowBarRight,
  IconHelp,
  IconNews,
  IconSearch,
  IconSettings,
} from "@tabler/icons";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ExternalLinkIcon } from "./components/link";
import { ConfigProvider, useConfig } from "./config";
import { dispatchingUi, dispatchingUiSmall } from "./images";
import { ContentRouter, MainLinks, NavLink } from "./nav";
import { SearchProvider } from "./search";
import { Settings } from "./settings";
import { useColorScheme } from "./themes";
import { Motd } from "./views/motd/motd";

type ShellContext = {
  compactShell: boolean;
  toggleCompactShell: () => void;
};

type OrderIdentifierType = "order_id" | "order_number";

export type OrderIdentifierDisplayContext = {
  selected: OrderIdentifierType;
  omitted: OrderIdentifierType;
  toggle: () => void;
};

type NavigationContext = {
  distance: number;
  setDistance: (value: number) => void;
};

type SettingsContext = {
  idOrNumber: OrderIdentifierDisplayContext;
  navigation: NavigationContext;
};

const SettingsContext = createContext<SettingsContext>({
  idOrNumber: {
    selected: "order_id",
    omitted: "order_number",
    toggle: () => {},
  },
  navigation: {
    distance: 5,
    setDistance: (value: number) => {},
  },
});

export const useIdOrNumberContext = () => {
  return useSettingsContext().idOrNumber;
};

export const useNavigationContext = () => {
  return useSettingsContext().navigation;
};

export const useSettingsContext = () => {
  return useContext(SettingsContext);
};

const PageContext = createContext<ShellContext>({
  compactShell: false,
  toggleCompactShell: () => {},
});

export const usePageContext = () => {
  return useContext(PageContext);
};

export const App = () => {
  const [colorScheme, toggleColorScheme] = useColorScheme();
  const [storedCompactShell, setStoredCompactShell] = useLocalStorage<boolean>({
    key: "opt-compact-shell",
    defaultValue: true,
  });
  const [compactShell, setCompactShell] = useState(storedCompactShell);

  useEffect(() => {
    setCompactShell(storedCompactShell);
  }, [storedCompactShell]);

  return (
    <React.StrictMode>
      <PageContext.Provider
        value={{
          compactShell,
          toggleCompactShell: () => {
            setStoredCompactShell(!storedCompactShell);
          },
        }}
      >
        <div
          css={css`
            & svg {
              display: inline;
            }
          `}
        >
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              withGlobalStyles
              withNormalizeCSS
              theme={{
                colorScheme,
                loader: "bars",
                components: {
                  Spotlight: {
                    styles: (theme) => ({
                      actionHovered: {
                        color:
                          colorScheme === "dark"
                            ? theme.colors.pink[5]
                            : theme.colors.pink[9],
                      },
                    }),
                  },
                },
                globalStyles: (theme) => ({
                  a: {
                    textDecoration: "underline",
                  },
                }),
              }}
            >
              <ConfigProvider>
                <NotificationsProvider limit={3}>
                  <SearchProvider>
                    <Page />
                  </SearchProvider>
                </NotificationsProvider>
              </ConfigProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </div>
      </PageContext.Provider>
    </React.StrictMode>
  );
};

const portalNavWidth = 80;

const Page = () => {
  const page = usePageContext();
  const env = useConfig().env;
  const theme = useMantineTheme();
  const [navigationDistance, setNavigationDistance] = useLocalStorage<number>({
    key: "navigation-time-distance",
    defaultValue: 5,
  });
  const [idOrNumber, setIdOrNumber] = useLocalStorage<OrderIdentifierType>({
    key: "idOrNumber",
    defaultValue: "order_id",
  });
  const omittedIdOrNumber =
    idOrNumber === "order_id" ? "order_number" : "order_id";

  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showAllMotds, setShowAllMotds] = useState<boolean>(false);

  const shellRef = useRef<HTMLDivElement>(null);
  const [left, setLeft] = useState(0);
  useLayoutEffect(() => {
    setLeft(
      shellRef.current ? shellRef.current.getBoundingClientRect().left : 0,
    );
  }, [shellRef.current]);

  const logoSrc = useMemo(
    () =>
      `data:image/svg+xml;base64,${window.btoa(
        page.compactShell ? dispatchingUiSmall.data : dispatchingUi.data,
      )}`,
    [page.compactShell],
  );

  const navbarWidth = page.compactShell ? 60 : 250;
  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const width = docWidth - portalNavWidth - left;

  return (
    <SettingsContext.Provider
      value={{
        idOrNumber: {
          selected: idOrNumber,
          omitted: omittedIdOrNumber,
          toggle: () => {
            setIdOrNumber(omittedIdOrNumber);
          },
        },
        navigation: {
          distance: navigationDistance,
          setDistance: (value: number) => setNavigationDistance(value),
        },
      }}
    >
      <AppShell
        ref={shellRef}
        padding="md"
        navbar={
          <Navbar left={left} width={{ base: navbarWidth }} p="xs">
            <Navbar.Section mt="xs">
              <Center style={{ marginBottom: 10 }}>
                <img height="40px" src={logoSrc}></img>
              </Center>
            </Navbar.Section>
            <Navbar.Section grow mt="xs">
              <MainLinks />
            </Navbar.Section>
            <NavbarSectionLink
              label={<ExternalLinkIcon label="Documentation" />}
              icon={<IconHelp size={16} />}
              onClick={() => {
                window.open(
                  "https://api.goflink.com/last-mile/dispatching-ui/dispatching-docs/",
                  "_blank",
                );
              }}
            />
            <NavbarSectionLink
              icon={<IconNews size={16} />}
              label="What's new?"
              onClick={() => setShowAllMotds(true)}
            />
            <NavbarSectionLink
              icon={<IconSearch size={16} />}
              label="Search..."
              onClick={() => openSpotlight()}
            />
            <NavbarSectionLink
              icon={<IconSettings size={16} />}
              label="Settings"
              onClick={() => setShowSettings(!showSettings)}
            />
            <NavbarSectionLink
              icon={
                page.compactShell ? (
                  <IconArrowBarRight size={16} />
                ) : (
                  <IconArrowBarLeft size={16} />
                )
              }
              label="Toggle Navbar"
              onClick={() => page.toggleCompactShell()}
            />
          </Navbar>
        }
        styles={(theme) => ({
          main: {
            width,
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <Box style={{ fontSize: theme.fontSizes.md }}>
          {env === "staging" && <StagingBadge />}
          <ContentRouter />
        </Box>
      </AppShell>
      <Settings
        opened={showSettings}
        onModalClosed={() => setShowSettings(false)}
      />
      <Motd showAll={showAllMotds} setShowAll={setShowAllMotds} />
    </SettingsContext.Provider>
  );
};

export const NavbarSectionLink = (props: {
  label: ReactNode;
  icon: ReactNode;
  onClick: () => void;
  active?: boolean;
}) => {
  const page = usePageContext();
  return (
    <Tooltip label={props.label} disabled={!page.compactShell}>
      <Navbar.Section>
        <NavLink
          icon={props.icon}
          label={props.label}
          onClick={props.onClick}
          active={props.active}
        />
      </Navbar.Section>
    </Tooltip>
  );
};

const StagingBadge = () => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <Text
      sx={{
        backgroundColor: "#e31c79",
        position: "fixed",
        color: colorScheme === "dark" ? "white" : "black",
        top: "120px",
        right: "-220px",
        display: "flex",
        transform: "rotate(45deg)",
        justifyContent: "center",
        fontSize: "90px",
        opacity: "0.15",
        userSelect: "none",
        letterSpacing: 20,
        zIndex: 10,
        pointerEvents: "none",
        width: "800px",
        alignItems: "center",
      }}
    >
      Staging
    </Text>
  );
};
