import { Box, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import { ReactNode } from "react";

export const ExternalLink = (params: { url: string; children: ReactNode }) => {
  return (
    <Box style={{ display: "inline" }}>
      <a href={params.url} target="_blank">
        {params.children}
        &thinsp;
        <IconExternalLink size={14} />
      </a>
    </Box>
  );
};

export const ExternalLinkIcon = (props: { label: string }) => (
  <div style={{ display: "inline-flex" }}>
    <Text mr={4}>{props.label}</Text>
    <IconExternalLink size={14} style={{ alignSelf: "center" }} />
  </div>
);
