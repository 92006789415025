import { ActionIcon, CopyButton as BaseCopyButton } from "@mantine/core";
import { IconCopy } from "@tabler/icons";
import { ReactNode } from "react";

export const CopyButton = (params: { value: string; icon?: ReactNode }) => {
  const icon = params.icon || <IconCopy size={14} />;

  return (
    <BaseCopyButton value={params.value} timeout={500}>
      {({ copied, copy }) => (
        <>
          &thinsp;
          <ActionIcon
            component="a"
            color={copied ? "green" : undefined}
            onClick={copy}
          >
            &thinsp;{icon}&thinsp;
          </ActionIcon>
        </>
      )}
    </BaseCopyButton>
  );
};
