import {
  Anchor,
  Button,
  Group,
  Input,
  Loader,
  ScrollArea,
  Space,
  Text,
  Timeline,
} from "@mantine/core";
import { useDebouncedState, useViewportSize } from "@mantine/hooks";
import { IconFilter, IconStatusChange } from "@tabler/icons";
import dateFormat from "dateformat";
import {
  ChangeEvent,
  createRef,
  forwardRef,
  Ref,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Command } from "../../components/command";
import { CopyButton } from "../../components/copy";
import { ShowError } from "../../components/errors";
import { Loading } from "../../components/loading";
import { ApiResponse } from "../../config";
import { Nullish } from "../../custom-schemas";
import { useHashParam } from "../../hooks";
import { HubSlug, HubStateHistorySummary, UUID } from "../../types";
import { HashParams } from "./hubs";

export const FullStateTimeline = (props: {
  hub: HubSlug;
  stateId: UUID;
  setStateId: (id: Nullish<string>) => void;
  response: ApiResponse<HubStateHistorySummary[]>;
}) => {
  if (props.response.loading) {
    return <Loading />;
  }

  if (props.response.error) {
    return <ShowError error={props.response.error} />;
  }

  return <StateTimeline data={props.response.data!} {...props} />;
};

const StateTimeline = (props: {
  data: HubStateHistorySummary[];
  hub: HubSlug;
  stateId: UUID;
  setStateId: (id: Nullish<string>) => void;
}) => {
  const { height: viewportHeight } = useViewportSize();
  const [filterRaw, setFilterRaw] = useDebouncedState<string>("", 300);
  const data = filterData(props.data, parseFilter(filterRaw));

  const selectedItemRef: Ref<HTMLDivElement> | undefined = createRef();
  useEffect(() => {
    selectedItemRef.current?.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }, [selectedItemRef]);

  const selectedIndex = useMemo(() => {
    return data.findIndex((s) => s.ID === props.stateId);
  }, [filterRaw, props.stateId]);

  return (
    <>
      <Group>
        <Input
          icon={<IconFilter />}
          placeholder="Filter"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFilterRaw(e.currentTarget.value)
          }
          w={370}
          data-dd-action-name="Timeline filter"
        />
        <Button
          variant={"outline"}
          sx={{ float: "right" }}
          onClick={() =>
            selectedItemRef.current?.scrollIntoView({
              block: "center",
              behavior: "smooth",
            })
          }
          data-dd-action-name="Timeline focus button"
        >
          <Text>Focus</Text>
        </Button>
      </Group>
      <Space h="md" />
      <ScrollArea type="always" h={viewportHeight - 120}>
        <TimelineItems
          data={data}
          stateId={props.stateId}
          ref={selectedItemRef}
          selectedIndex={selectedIndex}
        />
      </ScrollArea>
    </>
  );
};

const TimelineItems = forwardRef(
  (
    props: {
      data: HubStateHistorySummary[];
      stateId: UUID;
      selectedIndex: number;
    },
    ref: Ref<HTMLDivElement> | undefined,
  ) => {
    const [stateId, setStateId] = useHashParam(HashParams.StateId);
    const [lastSelectedId, setLastSelectedId] = useState<UUID>(props.stateId);

    return (
      <Timeline
        active={
          props.selectedIndex !== -1
            ? props.data.length - 1 - props.selectedIndex
            : undefined
        }
        bulletSize={24}
        lineWidth={2}
        reverseActive={true}
      >
        {props.data.map((s) => {
          return (
            <Timeline.Item
              key={s.ID}
              bullet={
                lastSelectedId !== props.stateId && lastSelectedId === s.ID ? (
                  <Loader size="xs" variant="dots" color="yellow" />
                ) : (
                  <IconStatusChange size={12} />
                )
              }
              title={
                <Group spacing={0}>
                  <Anchor
                    onClick={() => {
                      setStateId(s.ID);
                      setLastSelectedId(s.ID);
                    }}
                    data-dd-action-name={"State link"}
                  >
                    <Group spacing={12} sx={{ fontWeight: "bold" }}>
                      <Text>{s.Revision}</Text>
                      {dateFormat(s.Timestamp, "HH:MM:ss")}
                    </Group>
                  </Anchor>
                  <CopyButton value={s.Timestamp.toISOString()} />
                  <Text c="dimmed" fz={10} fw={200}>
                    {s.ID}
                  </Text>
                </Group>
              }
              lineVariant="dashed"
              ref={s.ID === stateId ? ref : undefined}
            >
              <Space h={8}></Space>
              <Command name={s.CommandName} data={s.CommandData} />
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  },
);

type Filter = (summary: HubStateHistorySummary) => boolean;

const parseFilter: (input: string) => Filter | undefined = (input) => {
  const terms = input
    .trim()
    .split(/\s+/)
    .filter((t) => t)
    .map((t) => t.toLowerCase());

  if (terms.length === 0) {
    return undefined;
  }

  return (summary) => {
    if (!summary.CommandData || !summary.CommandName) {
      return false;
    }

    if (matchesAny(summary.CommandName, terms)) {
      return true;
    }

    if (matchesAny(summary.Revision.toString(), terms)) {
      return true;
    }

    return Object.values(summary.CommandData).some((value) =>
      matchesAny(JSON.stringify(value), terms),
    );
  };
};

const matchesAny = (str: string, terms: string[]): boolean => {
  const searched = str.toLowerCase();
  return terms.some((term) => searched.includes(term));
};

const filterData = (
  summaries: HubStateHistorySummary[],
  filter?: Filter,
): HubStateHistorySummary[] => {
  let filteredSummaries: HubStateHistorySummary[] = [];

  if (!filter) {
    return summaries;
  }

  summaries.forEach((summary) => {
    if (filter(summary)) {
      filteredSummaries.push(summary);
    }
  });

  return filteredSummaries;
};
