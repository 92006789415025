import { ColorScheme, useMantineColorScheme } from "@mantine/core";
import {
  useColorScheme as useSystemColorScheme,
  useLocalStorage,
} from "@mantine/hooks";
import { IconMoonStars, IconSun } from "@tabler/icons";
import { useEffect, useState } from "react";
import { NavLink } from "./nav";

export const FlinkPink = "#e31c79";
export const SelectedOrderYellow = "#d2e910";
export const FlinkProposalBlue = "#0d32e5";
export const FlinkTripPurple = "#a527cc";
export const OutsourcedTripOrange = "#e35f12";
export const DeliveredGreen = "#0bb513";

export const useColorScheme = (): [ColorScheme, () => void] => {
  const systemColorScheme = useSystemColorScheme();
  const [storedColorScheme, setStoredColorScheme] = useLocalStorage<
    ColorScheme | ""
  >({
    key: "mantine-color-scheme",
    defaultValue: "",
    getInitialValueInEffect: false,
  });
  const [colorSchemeLoaded] = useState(!!storedColorScheme);

  useEffect(() => {
    // enforce the system's color scheme if there wasn't any stored
    if (!colorSchemeLoaded) {
      setStoredColorScheme(systemColorScheme);
    }
  }, [systemColorScheme]);

  return [
    storedColorScheme || "dark",
    () => setStoredColorScheme(storedColorScheme === "dark" ? "light" : "dark"),
  ];
};

export const ColorSchemeSwitch = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <NavLink
      onClick={() => toggleColorScheme()}
      icon={dark ? <IconSun size={16} /> : <IconMoonStars size={16} />}
      label="Toggle Theme"
    ></NavLink>
  );
};
