import { ReactElement } from "react";
import { ContainerID } from "../types";

const containerIconSize = 7;
const containerIconMargin = 1.5;

const SingleElementContainer = (props: {
  containerSize: number;
  pillColor: string;
  isFilled: boolean;
}) => {
  return (
    <div
      style={{
        height: containerIconSize,
        width: props.containerSize * containerIconSize,
        backgroundColor: props.isFilled ? "white" : props.pillColor,
        borderRadius: "1px",
        display: "inline-block",
        marginRight: containerIconMargin,
        marginLeft: containerIconMargin,
      }}
    ></div>
  );
};

export const ContainerIconGroup = (props: {
  containerIDs: Array<ContainerID>;
  pillColor: string;
  isFilled: boolean;
}): ReactElement => {
  return (
    <div>
      {props.containerIDs.map((id) => {
        return (
          <SingleElementContainer
            key={id}
            containerSize={parseInt(id[3])}
            pillColor={props.pillColor}
            isFilled={props.isFilled}
          />
        );
      })}
    </div>
  );
};
