/* 🤖 this file was generated by svg-to-ts */
export const bike: {
  name: "bike";
  data: string;
} = {
  name: "bike",
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-bike" viewBox="-8 -8 40 40"><circle cx="12" cy="12" r="18" fill="#fff" stroke-width="3"/><path stroke="none" d="M0 0h24v24H0z"/><path d="M2 18a3 3 0 1 0 6 0 3 3 0 1 0-6 0M16 18a3 3 0 1 0 6 0 3 3 0 1 0-6 0M12 19v-4l-3-3 5-4 2 3h3M16 5a1 1 0 1 0 2 0 1 1 0 1 0-2 0"/></svg>`,
};
export const dispatchingUiSmall: {
  name: "dispatching_ui_small";
  data: string;
} = {
  name: "dispatching_ui_small",
  data: `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="23" height="23" viewBox="0 0 5.95 4.665"><path d="M5.95 4.534H0v.603h5.95z" style="fill:#7580f3;fill-opacity:1;stroke-width:.116501"/><path d="M0-.844v5.378h5.95V-.845z" style="fill:#4756ed;fill-opacity:1;stroke-width:.116501"/><text xml:space="preserve" x="1.857" y="2.947" style="font-size:3.175px;fill:#fdfdff;fill-opacity:1;stroke-width:.264583"><tspan x="1.857" y="2.947" style="font-style:normal;font-variant:normal;font-weight:400;font-stretch:normal;font-family:Rockwell;-inkscape-font-specification:Rockwell;fill:#fdfdff;fill-opacity:1;stroke-width:.264583">d</tspan></text></svg>`,
};
export const dispatchingUi: {
  name: "dispatching_ui";
  data: string;
} = {
  name: "dispatching_ui",
  data: `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="120" height="23" viewBox="0 0 30.686 5.982"><path d="M65.96 63.718H35.273v.603H65.96z" style="fill:#7580f3;fill-opacity:1;stroke-width:.264583" transform="translate(-35.273 -58.34)"/><path d="M35.273 58.34v5.378H65.96v-5.379z" style="fill:#4756ed;fill-opacity:1;stroke-width:.264583" transform="translate(-35.273 -58.34)"/><text xml:space="preserve" x="39.173" y="62.131" style="font-size:3.175px;fill:#fdfdff;fill-opacity:1;stroke-width:.264583" transform="translate(-35.273 -58.34)"><tspan x="39.173" y="62.131" style="font-style:normal;font-variant:normal;font-weight:400;font-stretch:normal;font-family:Rockwell;-inkscape-font-specification:Rockwell;fill:#fdfdff;fill-opacity:1;stroke-width:.264583">dispatching-ui</tspan></text></svg>`,
};
export const hand: {
  name: "hand";
  data: string;
} = {
  name: "hand",
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-hand-grab" viewBox="-8 -8 40 40"><circle cx="12" cy="12" r="18" fill="#fff" stroke-width="3"/><path stroke="none" d="M0 0h24v24H0z"/><path d="M8 11V7.5a1.5 1.5 0 0 1 3 0V10M11 9.5v-3a1.5 1.5 0 0 1 3 0V10M14 7.5a1.5 1.5 0 0 1 3 0V10"/><path d="M17 9.5a1.5 1.5 0 0 1 3 0V14a6 6 0 0 1-6 6h-2 .208a6 6 0 0 1-5.012-2.7L7 17c-.312-.479-1.407-2.388-3.286-5.728A1.5 1.5 0 0 1 4.25 9.25a1.867 1.867 0 0 1 2.28.28L8 11"/></svg>`,
};
export const homeCheck: {
  name: "home_check";
  data: string;
} = {
  name: "home_check",
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-home-check" viewBox="-8 -8 40 40"><circle cx="12" cy="12" r="18" fill="#fff" stroke-width="3"/><path stroke="none" d="M0 0h24v24H0z"/><path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2"/><path d="M19 13.488V12h2l-9-9-9 9h2v7a2 2 0 0 0 2 2h4.525M15 19l2 2 4-4"/></svg>`,
};
export const paperBag: {
  name: "paper_bag";
  data: string;
} = {
  name: "paper_bag",
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-paper-bag" viewBox="-8 -8 40 40"><circle cx="12" cy="12" r="18" fill="#fff" stroke-width="3"/><path stroke="none" d="M0 0h24v24H0z"/><path d="M8 3h8a2 2 0 0 1 2 2v1.82a5 5 0 0 0 .528 2.236l.944 1.888A5 5 0 0 1 20 13.18V19a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-5.82a5 5 0 0 1 .528-2.236L6 8V5a2 2 0 0 1 2-2z"/><path d="M12 15a2 2 0 1 0 4 0 2 2 0 1 0-4 0M6 21a2 2 0 0 0 2-2v-5.82a5 5 0 0 0-.528-2.236L6 8M11 7h2"/></svg>`,
};
export const shoppingCart: {
  name: "shopping_cart";
  data: string;
} = {
  name: "shopping_cart",
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-shopping-cart" viewBox="-8 -8 40 40"><circle cx="12" cy="12" r="18" fill="#fff" stroke-width="3"/><path stroke="none" d="M0 0h24v24H0z"/><path d="M4 19a2 2 0 1 0 4 0 2 2 0 1 0-4 0M15 19a2 2 0 1 0 4 0 2 2 0 1 0-4 0"/><path d="M17 17H6V3H4"/><path d="m6 5 14 1-1 7H6"/></svg>`,
};
export type myIcons =
  | "bike"
  | "dispatching_ui_small"
  | "dispatching_ui"
  | "hand"
  | "home_check"
  | "paper_bag"
  | "shopping_cart";
export interface MyIcon {
  name: myIcons;
  data: string;
}
export type MyIconNameSubset<T extends Readonly<MyIcon[]>> = T[number]["name"];
export const completeIconSet = [
  bike,
  dispatchingUiSmall,
  dispatchingUi,
  hand,
  homeCheck,
  paperBag,
  shoppingCart,
];
