import { Box, Title, useMantineTheme } from "@mantine/core";
import { ReactNode } from "react";

export const Content = (params: { title: string; children?: ReactNode }) => {
  return (
    <>
      <Title order={1}>{params.title}</Title>
      {params.children}
    </>
  );
};

export const Section = (params: { title: string; children?: ReactNode }) => {
  const theme = useMantineTheme();

  return (
    <Box style={{ marginBottom: theme.spacing.lg }}>
      <Title order={2}>{params.title}</Title>
      {params.children}
    </Box>
  );
};

export const SubSection = (params: { title: string; children?: ReactNode }) => {
  const theme = useMantineTheme();

  return (
    <Box style={{ marginBottom: theme.spacing.xs }}>
      <Title order={5}>{params.title}</Title>
      {params.children}
    </Box>
  );
};
