import { Stack } from "@mantine/core";
import { JsonTree, keyRenderer, valueRenderer } from "../components/json";
import { OrderLink } from "../nav";
import { i, k } from "../obj-path";
import { HubState } from "../types";

type WrapperType = {
  State: HubState;
};

export const StateJson = (props: { rawState?: any }) => {
  return (
    <Stack>
      <JsonTree
        allowSearch
        data={props.rawState}
        keyRenderers={jsonKeyRenderers}
        valueRenderers={jsonValueRenderers}
      />
    </Stack>
  );
};

const jsonKeyRenderers = [
  keyRenderer(
    (orderId) => <OrderLink orderId={orderId} />,
    (state: HubState) => state.RecentlyFinalizedOrders[k],
  ),
];

const jsonValueRenderers = [
  valueRenderer(
    (orderId) => <OrderLink orderId={orderId} />,
    (state: HubState) => state.TripProposals[i].FirstDelivery.ID,
    (state: HubState) => state.TripProposals[i].IndirectDeliveries[i].ID,
    (state: HubState) => state.Trips[i].FirstDelivery.ID,
    (state: HubState) => state.Trips[i].IndirectDeliveries[i].ID,
    (state: HubState) => state.OutsourcedTrips[i].FirstDelivery.ID,
    (state: HubState) => state.OutsourcedTrips[i].IndirectDeliveries[i].ID,
    (state: HubState) => state.ClickAndCollectOrders[i].ID,
    (state: HubState) => state.ExternalDeliveryOrders[i].ID,
    (state: HubState) => state.InStorePaymentOrders[i].ID,
  ),
];
