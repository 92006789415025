export const i: number = "\\d+" as any;
export const k: string = ".*" as any;

export const derivePath = <T>(f: (value: T) => any): string[] => {
  const path: string[] = [];
  const proxy = new Proxy(
    {},
    {
      get: (target, prop) => {
        path.push(`^${prop.toString()}$`);
        return proxy;
      },
    },
  ) as any;

  f(proxy);

  return path;
};
