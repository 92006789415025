import { Indicator, Text, Tooltip } from "@mantine/core";
import {
  IconBike,
  IconCar,
  IconClock,
  IconGardenCart,
  IconHandGrab,
  IconLock,
  IconScooter,
  IconSnowflake,
  IconSun,
  IconTemperature,
} from "@tabler/icons";
import { ReactElement, ReactNode } from "react";
import { PickingTask, TemperatureCategories, Vehicle } from "../../types";

export const DISABLED_TEMP_INDICATOR_COLOR = "white";

function generateVehicleString(vehicle: Vehicle | null | undefined): string {
  if (vehicle == null) {
    return "";
  }
  const weightInKG = vehicle?.MaxWeightGrams / 1000;
  const equipmentCounts: Map<string, number> = new Map();
  let equipmentString = "";
  if (vehicle.Equipment !== undefined && vehicle.Equipment !== null) {
    for (const equipment of vehicle.Equipment) {
      if (equipment != undefined) {
        const currentCount = equipmentCounts.get(equipment.ContainerType);
        if (currentCount == undefined) {
          equipmentCounts.set(equipment.ContainerType, 1);
        } else {
          equipmentCounts.set(equipment.ContainerType, currentCount + 1);
        }
      }
    }
  }
  for (const key of equipmentCounts.keys()) {
    equipmentString = `${key}:${equipmentCounts.get(key)}X,${equipmentString}`;
  }
  return `${vehicle.VehicleType},${equipmentString}${weightInKG}kg`;
}

export const TripIndicators = (props: {
  children: ReactNode;
  locked: boolean;
  offered: boolean;
  claimed: boolean;
  vehicle: Vehicle | undefined;
}) => (
  <Indicator
    disabled={!props.offered}
    size={15}
    label={
      <IconWithTooltip
        icon={<IconHandGrab size={10} />}
        label={"Offered to rider"}
      />
    }
    color="green"
    zIndex={1}
  >
    <Indicator
      disabled={!props.locked}
      size={15}
      label={
        <IconWithTooltip
          icon={<IconLock size={10} />}
          label={
            "Locked trip proposals will not be reconsidered by the automatic stacker. An unstacking action produces a locked trip proposal, as does an unclaim action."
          }
        />
      }
      position="bottom-end"
      zIndex={1}
    >
      <Indicator
        disabled={!props.claimed}
        size={15}
        label={
          <IconWithTooltip
            icon={<IconHandGrab size={10} />}
            label={"Claimed by rider"}
          />
        }
        color="red"
        zIndex={1}
      >
        <Indicator
          disabled={props.vehicle === undefined}
          size={20}
          label={GetLabelForVehicle(props.vehicle)}
          color={
            !props.vehicle?.ID ? "green" : "#912" + props.vehicle?.ID.slice(-3)
          }
          position={"top-start"}
          zIndex={1}
        >
          {props.children}
        </Indicator>
      </Indicator>
    </Indicator>
  </Indicator>
);
export const OrderIndicators = (props: {
  children: ReactNode;
  planned: boolean;
  pickingTask?: PickingTask;
  taskIndex?: number;
  temperatureCategories: TemperatureCategories;
}) => {
  let [tIcon, tColour] = getTemperatureIcon(props.temperatureCategories);
  return (
    <Indicator
      disabled={
        props.temperatureCategories
          ? props.temperatureCategories.length == 0
          : true
      }
      offset={5}
      size={15}
      label={tIcon}
      position="top-end"
      color={tColour}
      zIndex={1}
    >
      <Indicator
        disabled={!props.pickingTask}
        offset={5}
        size={15}
        label={
          <IconWithTooltip
            icon={props.taskIndex! + 1}
            label={
              <>
                <i>{props.pickingTask?.Group}</i>
              </>
            }
          />
        }
        position="bottom-start"
        color={"gray"}
        zIndex={1}
      >
        <Indicator
          disabled={!props.planned}
          offset={5}
          size={15}
          label={
            <IconWithTooltip
              icon={<IconClock size={10} style={{ display: "flex" }} />}
              label={"Planned Order"}
            />
          }
          position="bottom-end"
          color={"yellow"}
          zIndex={1}
        >
          {props.children}
        </Indicator>
      </Indicator>
    </Indicator>
  );
};

export const IconWithTooltip = (props: {
  label: ReactNode;
  icon: ReactNode;
}) => (
  <Tooltip label={props.label} multiline>
    <div>{props.icon}</div>
  </Tooltip>
);

export const getTemperatureIcon = (
  categories: TemperatureCategories,
): [ReactElement, string] => {
  let frozenOrCold =
    categories?.includes("FrozenFood") || categories?.includes("ColdFood");
  let hot = categories?.includes("HotFood");

  if (frozenOrCold && !hot) {
    return COLD_ICON;
  }

  if (hot && !frozenOrCold) {
    return HOT_ICON;
  }

  if (hot && frozenOrCold) {
    return GENERIC_TEMP_ICON;
  }

  return NO_ICON;
};

export const GetLabelForVehicle = (
  vehicle: Vehicle | null | undefined,
): ReactElement => {
  if (vehicle === undefined) {
    return <></>;
  }
  const labelText = generateVehicleString(vehicle);
  let icon: ReactNode;
  switch (vehicle?.VehicleType) {
    case "Bicycle":
      icon = (
        <IconWithTooltip
          label={labelText}
          icon={<IconBike size={15} style={{ display: "flex" }} />}
        />
      );
      break;
    case "Car":
      icon = (
        <IconWithTooltip
          label={labelText}
          icon={<IconCar size={15} style={{ display: "flex" }} />}
        />
      );
      break;
    case "Cargo":
      icon = (
        <IconWithTooltip
          label={labelText}
          icon={<IconGardenCart size={15} style={{ display: "flex" }} />}
        />
      );
      break;
    case "Scooter":
      icon = (
        <IconWithTooltip
          label={labelText}
          icon={<IconScooter size={15} style={{ display: "flex" }} />}
        />
      );
      break;
    default:
      console.error("ERROR: Vehicle type is not known.");
      return <></>;
  }
  return (
    <div style={{ display: "flex" }}>
      <Text weight={"bold"}>{vehicle?.Equipment?.length}</Text>
      {icon}
      <Text weight={"lighter"} italic={true}>
        {vehicle?.ID?.slice(-3)}
      </Text>
    </div>
  );
};

export const COLD_ICON: [ReactElement, string] = [
  <IconWithTooltip
    label={"Cold/Frozen"}
    icon={<IconSnowflake size={10} style={{ display: "flex" }} />}
  />,
  "blue",
];

export const HOT_ICON: [ReactElement, string] = [
  <IconWithTooltip
    label={"Hot food"}
    icon={<IconSun size={10} style={{ display: "flex" }} />}
  />,
  "red",
];

export const GENERIC_TEMP_ICON: [ReactElement, string] = [
  <IconWithTooltip
    label={"Hot food and cold or frozen food"}
    icon={<IconTemperature size={10} style={{ display: "flex" }} />}
  />,
  "pink",
];

export const NO_ICON: [ReactElement, string] = [
  <></>,
  DISABLED_TEMP_INDICATOR_COLOR,
];
