import { Table, Text } from "@mantine/core";
import { ReactNode, useMemo } from "react";
import { Nullish } from "../custom-schemas";
import { Link, routes } from "../nav";

const knownCommandFields: Record<string, (value: any) => ReactNode> = {
  OrderID: (value: any) => (
    <Link
      route={routes.orders.sub.byId}
      params={{ idOrNumber: value as string }}
    >
      {value}
    </Link>
  ),
  OrderIDs: (value: any) => (
    <Text span>
      {(value as string[]).flatMap((orderId, i) => [
        i > 0 && ", ",
        <Link
          key={orderId}
          route={routes.orders.sub.byId}
          params={{ idOrNumber: orderId }}
        >
          {orderId}
        </Link>,
      ])}
    </Text>
  ),
  Timestamp: (value: any) => <></>,
};

export const Command = (props: { name: Nullish<string>; data: any }) => {
  return (
    <>
      <CommandName name={props.name} />
      <CommandData data={props.data} />
    </>
  );
};

export const CommandName = (props: { name: Nullish<string> }) => {
  return (
    <Text span weight={"normal"}>
      {props.name ? (
        props.name.replace(/^\*hubstate\./, "")
      ) : (
        <Text span italic inherit>
          No command
        </Text>
      )}
    </Text>
  );
};

export const CommandData = (props: { data: any }) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <Table>
      <tbody>
        {props.data &&
          Object.entries(props.data)
            .filter(([key]) => key !== "Timestamp")
            .map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <CommandField name={key} value={value} />
                </td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
};

const CommandField = (props: { name: string; value: any }) => {
  const value = useMemo<ReactNode>(() => {
    const field = knownCommandFields[props.name];
    return field ? (
      field(props.value)
    ) : (
      <Text italic span>
        {renderValue(props.value)}
      </Text>
    );
  }, [props.name, props.value]);

  return <>{value}</>;
};

const renderValue = (value: any): string => {
  if (Array.isArray(value)) {
    return value.map(renderValue).join(",");
  }

  switch (typeof value) {
    case "string":
    case "number":
    case "boolean":
      return value.toString();
    default:
      return JSON.stringify(value);
  }
};
