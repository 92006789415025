import { Tabs } from "@mantine/core";
import { ReactNode } from "react";
import { useHashParam } from "../hooks";

export type TabDefinition = {
  key: string;
  title: string;
  icon: ReactNode;
  content: ReactNode;
};

export const AddressableTabs = (props: {
  param?: string;
  tabs: TabDefinition[];
}) => {
  const [tab, setTab] = useHashParam(props.param || "tab");

  return (
    <Tabs
      variant="outline"
      value={tab || props.tabs[0].key}
      onTabChange={setTab}
      keepMounted={false}
    >
      <Tabs.List>
        {props.tabs.map((def) => (
          <Tabs.Tab
            key={`tab_${def.key}`}
            value={def.key}
            icon={def.icon}
            data-dd-action-name={def.title + " tab"}
          >
            {def.title}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {props.tabs.map((def) => (
        <Tabs.Panel key={`content_${def.key}`} value={def.key} pt="xs">
          {def.content}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};
