import { ActionIcon as BaseActionIcon, Tooltip } from "@mantine/core";
import { ReactNode } from "react";

export const ActionIcon = (props: {
  icon: ReactNode;
  onClick?: () => void;
  color?: string;
  disabled?: boolean;
  tooltip?: string;
  actionName?: string;
}) => (
  <Tooltip disabled={!props.tooltip} label={props.tooltip}>
    <BaseActionIcon
      variant="outline"
      disabled={props.disabled || false}
      onClick={props.onClick}
      color={props.color || "gray"}
      data-dd-action-name={props.actionName || props.tooltip + " button"}
    >
      {props.icon}
    </BaseActionIcon>
  </Tooltip>
);
