import { Stack, Textarea } from "@mantine/core";
import { ReactElement, useState } from "react";
import { useHashParam } from "../hooks";
import { HubState } from "../types";
import { parseHubState } from "../utils";
import { StaticHubState } from "./static-hub-state";

export const HubStateTool = (): ReactElement => {
  const [initialState] = useHashParam("state");
  const [hubStateText, setHubStateText] = useState(initialState || "");

  var hubState: HubState | undefined = undefined;
  if (hubStateText !== "") {
    hubState = parseHubState(hubStateText);
  }

  const valid = hubState !== null;

  return (
    <Stack>
      <Textarea
        label={"HubState JSON"}
        placeholder={"Paste HubState JSON here"}
        value={hubStateText}
        onChange={(event) => setHubStateText(event.currentTarget.value)}
        error={!hubState}
      />
      <StaticHubState hubState={hubState}></StaticHubState>
    </Stack>
  );
};
