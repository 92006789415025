import sdk from "@hubportal/sdk";
import { LoadingOverlay } from "@mantine/core";
import axios, { AxiosRequestConfig } from "axios";
import { makeUseAxios } from "axios-hooks";
import * as React from "react";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { z, ZodSchema, ZodTypeDef } from "zod";
import { ShowError } from "./components/errors";
import { CountryDeliveryArea, HubDeliveryArea } from "./types";

declare var ENV: string | undefined;

export type Schema<T> = ZodSchema<T, ZodTypeDef, any>;

export type Env = "dev" | "staging" | "prod";

const backendUrls: Record<Env, string> = {
  prod: "https://api.goflink.com/last-mile/dispatching-ui/",
  staging: "https://api.staging.goflink.com/last-mile/dispatching-ui/",
  dev: "http://localhost:8080/",
};

const audiences: Record<Env, string> = {
  prod: "https://api.goflink.com",
  staging: "https://api.staging.goflink.com",
  dev: "",
};

const env = ((): Env => {
  if (ENV) {
    console.log("Overriding env to", ENV);
    return ENV as Env;
  }

  switch (location.host) {
    case "hub.goflink.com":
      return "prod";
    case "hub.staging.goflink.com":
      return "staging";
    default:
      return "dev";
  }
})();

const context = createContext({
  env: env,
  deliveryAreas: [] as CountryDeliveryArea[],
  hubs: new Map<string, HubDeliveryArea>(),
});

type ContextType<T> = T extends React.Context<infer C> ? C : never;

export type Config = ContextType<typeof context>;

export const customAxios = axios.create({
  baseURL: backendUrls[env],
  timeout: 10000,
});

customAxios.interceptors.request.use(
  async (cfg) => {
    const token = await sdk.getAuthClient().getTokenSilently({
      audience: audiences[env],
    });

    (cfg.headers = cfg.headers || {}).Authorization = `Bearer ${token}`;
    return cfg;
  },
  (error) => Promise.reject(error),
);

const useApiAxios = makeUseAxios({
  axios: customAxios,
});

export type ApiResponse<T> = {
  loading: boolean;
  data?: T;
  error?: Error;
  raw?: string;
  refetch: (config?: AxiosRequestConfig) => void;
};

export const useApiResource = <T,>(
  path: string,
  schema: Schema<T>,
  config?: Omit<AxiosRequestConfig, "url"> & { manual?: boolean },
): ApiResponse<T> => {
  const [state, refetch] = useApiAxios<T>(
    { url: path, ...config },
    { manual: !!config?.manual },
  );

  const [data, error] = useMemo(() => {
    if (state.error) {
      return [undefined, state.error as Error];
    }

    try {
      const data = state.data ? schema.parse(state.data) : undefined;
      return [data, undefined];
    } catch (error) {
      return [undefined, error as Error];
    }
  }, [state.data, state.error]);

  return {
    loading: state.loading,
    data,
    raw: JSON.stringify(state.data),
    error,
    refetch,
  };
};

export const ConfigProvider = (props: { children: ReactNode }) => {
  const { loading, data, error } = useApiResource<CountryDeliveryArea[]>(
    "/delivery_areas",
    z.array(CountryDeliveryArea),
  );

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />

      {loading ? (
        <></>
      ) : error ? (
        <ShowError error={error} />
      ) : (
        <context.Provider value={createConfig(data!)}>
          {props.children}
        </context.Provider>
      )}
    </>
  );
};

const createConfig = (deliveryAreas: CountryDeliveryArea[]): Config => {
  const hubs = new Map<string, HubDeliveryArea>();
  deliveryAreas.forEach((country) => {
    country.cities.forEach((city) => {
      city.delivery_areas.forEach((hub) => hubs.set(hub.slug, hub));
    });
  });

  return { env, deliveryAreas, hubs };
};

export const useConfig = (): Config => useContext(context);
