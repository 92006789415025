import { useWindowEvent } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";

const updateHash = (
  target: Location | URL,
  update: (hash: string) => string,
) => {
  target.hash = update(target.hash.substring(1));
};

export const useHash = (): [
  string,
  (update: (value: string) => string) => void,
  (replace: (value: string) => string) => void,
] => {
  const [hash, setHash] = useState(location.hash);

  useWindowEvent("hashchange", (event) => {
    setHash(location.hash);
  });

  return [
    location.hash.substring(1),
    (update: (value: string) => string) => updateHash(location, update),
    (replace: (value: string) => string) => {
      const href = new URL(location.href);
      const original = href.hash;
      updateHash(href, replace);
      if (href.hash !== original) location.replace(href);
    },
  ];
};

type rawParam = string | null;

const updateParamInHash = (
  hash: string,
  name: string,
  update: (raw: rawParam) => rawParam,
): string => {
  const params = new URLSearchParams(hash);
  const original = params.get(name);
  const updated = update(original);

  if (updated === original) return hash;

  if (typeof updated === "string") params.set(name, updated);
  else params.delete(name);
  return params.toString();
};

export function useHashParam(
  name: string,
): [rawParam, (value: rawParam | undefined) => void];
export function useHashParam<Value = rawParam>(
  name: string,
  parse: (raw: rawParam) => Value,
  render: (value: Value) => rawParam,
): [Value, (value: Value) => void];
export function useHashParam<Value extends SetableValue, SetableValue>(
  name: string,
  parse?: (raw: rawParam) => Value,
  render?: (value: SetableValue) => rawParam,
): [Value, (value: SetableValue) => void] {
  const [hash, setHash, replaceHash] = useHash();
  const param = new URLSearchParams(hash).get(name);
  const value = useMemo<Value>(
    () => (parse ? parse(param) : (param as Value)),
    [parse, param],
  );
  useEffect(() => {
    if (parse && render) {
      replaceHash((hash) =>
        updateParamInHash(hash, name, (currentParam) =>
          currentParam === param ? render(parse(param)) : currentParam,
        ),
      );
    }
  }, [parse, param]);
  return [
    value,
    (value: SetableValue) => {
      setHash((hash) =>
        updateParamInHash(hash, name, (_) =>
          render ? render(value) : (value as rawParam),
        ),
      );
    },
  ];
}
