import { Delivery, DeliverySequence } from "./types";

// In a better world these things would be methods.

export const tripDeliveries = (proposal: DeliverySequence): Array<Delivery> => [
  proposal.FirstDelivery,
  ...proposal.IndirectDeliveries,
];

export const getObjectDepth = (object: object): number =>
  Object(object) === object
    ? 1 + Math.max(-1, ...Object.values(object).map(getObjectDepth))
    : 0;

export const safeJsonParse = (value: any) => {
  if (typeof value === "string") {
    try {
      return JSON.parse(value);
    } catch (err) {}
  }
  return undefined;
};
