import { SpotlightProvider } from "@mantine/spotlight";
import {
  IconBuildingStore,
  IconMap,
  IconPaperBag,
  IconPerspective,
} from "@tabler/icons";
import { createContext, ReactNode, useMemo } from "react";
import { useLocation } from "wouter";
import { Config, useConfig } from "./config";
import { useHashParam } from "./hooks";
import { routes } from "./nav";
import { CountryDeliveryArea } from "./types";
import { HashParams } from "./views/hubs/hubs";

export type SearchEngine = (query: string) => SearchResult[];

export type ActionContext = {
  setLocation: (location: string) => void;
};

export type SearchResult = {
  title: string;
  description?: string;
  icon?: ReactNode;
  action: (ctx: ActionContext) => void;
};

export type GroupedSearchResults = {
  name: string;
  results: SearchResult[];
};

export class Search {
  private engines: [string, SearchEngine][];

  constructor() {
    this.engines = [];
  }

  register(name: string, engine: SearchEngine): void {
    this.engines.push([name, engine]);
  }

  search(query: string): GroupedSearchResults[] {
    query = query.trim();

    return this.engines.map(([name, engine]) => {
      return {
        name,
        results: engine(query),
      };
    });
  }
}

export const uuidRegex =
  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i;
const orderNumberRegex = /.{2}-.{3}-.{4}-.{4}/;

type RegionSearchItem = {
  id: string;
  searchName: string;
  displayName: string;
  link: string;
};

const createAreaSearch =
  (items: RegionSearchItem[]): SearchEngine =>
  (query) => {
    query = normalize(query);
    return query
      ? items
          .filter(
            (item) => item.id === query || item.searchName.includes(query),
          )
          .map((item) => ({
            title: item.displayName,
            icon: <IconMap size={18} />,
            action: (ctx) => {
              ctx.setLocation(
                routes.map.sub.byPrefix.link({ prefix: item.link }),
              );
            },
          }))
      : [];
  };

export const createSearch = (config: Config): Search => {
  const search = new Search();

  search.register("Order ID", (query) => {
    return query.match(uuidRegex) ? [orderResult(query)] : [];
  });

  search.register("Order Number", (query) => {
    return query.match(orderNumberRegex) ? [orderResult(query)] : [];
  });

  search.register("Hub State ID", (query) => {
    return query.match(uuidRegex)
      ? [
          {
            title: query,
            icon: <IconPerspective size={18} />,
            action: (ctx) => {
              ctx.setLocation(
                routes.hubs.sub.searchHubStateId.link({ stateId: query }),
              );
            },
          },
        ]
      : [];
  });

  const countries: RegionSearchItem[] = [];
  const cities: RegionSearchItem[] = [];

  config.deliveryAreas.forEach((country) => {
    countries.push({
      id: country.id.toLowerCase(),
      searchName: normalize(country.name),
      displayName: country.name,
      link: country.id.toLowerCase(),
    });
    country.cities.forEach((city) => {
      cities.push({
        id: city.id.toLowerCase(),
        searchName: normalize(city.name),
        displayName: city.name,
        link: `${country.id}_${city.id}`.toLowerCase(),
      });
    });
  });

  search.register("Country", createAreaSearch(countries));
  search.register("City", createAreaSearch(cities));

  const hubs = Array.from(config.hubs.keys());

  search.register("Hub", (query) => {
    const [rawTimestamp] = useHashParam(HashParams.Timestamp);
    query = query.toLowerCase();
    return query
      ? hubs
          .filter((slug) => slug.includes(query))
          .map((slug) => ({
            title: slug,
            icon: <IconBuildingStore size={18} />,
            action: (ctx) => {
              const tab = new URLSearchParams(location.hash.substring(1)).get(
                "tab",
              );
              ctx.setLocation(
                routes.hubs.sub.bySlug.link(
                  { slug },
                  {
                    ...(tab && { tab }),
                    ...(rawTimestamp && {
                      [HashParams.Timestamp]: rawTimestamp,
                    }),
                  },
                ),
              );
            },
          }))
      : [];
  });

  return search;
};

const orderResult = (idOrNumber: string): SearchResult => ({
  title: idOrNumber,
  icon: <IconPaperBag size={18} />,
  action: (ctx) => {
    ctx.setLocation(routes.orders.sub.byId.link({ idOrNumber }));
  },
});

const context = createContext({
  search: new Search(),
  deliveryAreas: [] as CountryDeliveryArea[],
});

export const SearchProvider = (props: { children: ReactNode }) => {
  const config = useConfig();
  const [, setLocation] = useLocation();

  const search = useMemo(() => createSearch(config), [config.deliveryAreas]);

  return (
    <SpotlightProvider
      shortcut="/"
      searchPlaceholder="Search..."
      nothingFoundMessage="Nothing found..."
      actions={[]}
      filter={(query) => {
        return search.search(query).flatMap((group) => {
          return group.results.map((result) => {
            return {
              id: `${group}:${result.title}`,
              group: group.name,
              title: result.title,
              icon: result.icon,
              description: result.description,
              onTrigger: () => result.action({ setLocation }),
              closeOnTrigger: true,
            };
          });
        });
      }}
    >
      {props.children}
    </SpotlightProvider>
  );
};

const normalize = (str: string) =>
  str
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();
