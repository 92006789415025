import { Table, Text } from "@mantine/core";
import dateFormat from "dateformat";
import { ReactNode } from "react";
import { useApiResource } from "../../config";
import { PlannedWindow, PlannedWindowResponse, UUID } from "../../types";

const plannedWindowColumns: Record<
  string,
  (window: PlannedWindow) => ReactNode
> = {
  "Delivery Window": (window) => (
    <Text>
      {dateFormat(window.start, "HH:MM:ss")} -{" "}
      {dateFormat(window.end, "HH:MM:ss")}
    </Text>
  ),
  "Used Capacity": (window) => window.total_capacity - window.free_capacity,
  "Free Capacity": (window) => window.free_capacity,
  "Total Capacity": (window) => window.total_capacity,
};

type PlannedOrderQuery = {
  skip: boolean;
  id: UUID;
};

const usePlannedWindow = (q: PlannedOrderQuery) => {
  return useApiResource("/delivery_window_capacity", PlannedWindowResponse, {
    manual: q.skip,
    params: { id: q.id },
  });
};

export const PlannedWindowsView = (props: {
  stateId: UUID;
  revision: number;
}) => {
  const plannedWindowResource = usePlannedWindow({
    id: props.stateId,
    skip: props.revision === 0,
  });
  const windows = plannedWindowResource.data?.windows;
  if (props.revision === 0) {
    return (
      <Text>
        Information is not available before the first state transition command.
        This can happen when there is no activity at the hub over the last
        couple of hours.
      </Text>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          {Object.keys(plannedWindowColumns).map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {windows &&
          windows.map((window) => (
            <tr key={window.start.toISOString()}>
              {Object.values(plannedWindowColumns).map((column, i) => (
                <td key={i}>{column(window)}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
