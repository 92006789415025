import { useElementSize } from "@mantine/hooks";
import { useEffect } from "react";
import * as vega from "vega";
import { EventListenerHandler } from "vega";
import vegaTooltipModule from "vega-tooltip";
import { UUID } from "../types";

export type VegaSpec = vega.Spec;

export const parse = vega.parse;

export const VegaView = (props: {
  data: vega.Runtime;
  onClick?: EventListenerHandler;
}) => {
  const { ref } = useElementSize<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const view = new vega.View(props.data, {
      renderer: "svg",
      container: ref.current,
      hover: true,
    });
    vegaTooltipModule(view);
    view.runAsync();

    if (props.onClick) {
      view.addEventListener("click", props.onClick);
    }

    return () => {
      view.finalize();
    };
  }, [ref.current, props.data]);

  return (
    <>
      <div ref={ref} style={{ margin: 20, marginTop: 0 }}></div>
    </>
  );
};

export const createEvent = (time: Date, name: string): EventSpec => {
  return {
    time: time,
    name: name,
  };
};

export type VegaData = {
  name: string;
  format: { type: string };
  values: any[];
};

export type EventSpec = {
  time: Date;
  name: string;
};

export type IconSpec = {
  url: string;
  time: Date;
  line: UUID;
  tooltip: VegaToolTip;
};

export type PhaseSpec = {
  line: string;
  start: Date | string;
  end: Date | string;
  color: string;
  height: number;
  tooltip?: VegaToolTip;
};

export type VegaToolTip = {};
