import { tripDeliveries } from "../methods";
import { IndirectDelivery } from "../types";
import { formatMilliSeconds } from "../utils";
import { DeliveryTrip } from "./hubs/hub-trips-and-proposals-overview";

const est_cust_to_cust_riding_leg_coefficient = 0.91187991;
const est_cust_to_cust_riding_leg_intercept = 1.85230953421756;
const est_hub_to_cust_riding_leg_coefficient = 0.89;
const est_hub_to_cust_riding_leg_intercept = 4.09;
const est_handover_duration = 2.35;

// Estimate the duration of the trip using the time estimation model
export const totalTripDurationEstimateInMs = (trip: DeliveryTrip): number => {
  const deliveries = tripDeliveries(trip);
  let nullValue;
  const duration =
    deliveries.reduce((total, delivery, i) => {
      let routedToOrder =
        i === 0
          ? getHubToCustomerRidingLegEstimate(delivery.RoutedFromHub!)
          : getRidingLegEstimate(
              (delivery as IndirectDelivery).RoutedFromPrevious!,
            );

      if (!routedToOrder) {
        nullValue = true;
      }
      total += routedToOrder;
      if (i === deliveries.length - 1) {
        total += getRidingLegEstimate(delivery.RoutedToHub!);
      }
      return total;
    }, deliveries.length * est_handover_duration) *
    60 *
    1000;
  return nullValue ? NaN : duration;
};

export const totalRidingDurationEstimateInMs = (trip: DeliveryTrip) => {
  const deliveries = tripDeliveries(trip);
  let nullValue;
  const duration =
    deliveries.reduce((total, delivery, i) => {
      let routedToOrder =
        i === 0
          ? delivery.RoutedFromHub!
          : (delivery as IndirectDelivery).RoutedFromPrevious!;

      if (!routedToOrder) {
        nullValue = true;
      }
      total += routedToOrder;
      if (i === deliveries.length - 1) {
        total += delivery.RoutedToHub!;
      }
      return total;
    }, 0) *
    60 *
    1000;
  return nullValue ? NaN : duration;
};

export const getRidingLegEstimate = (durationInMin: number): number => {
  return (
    durationInMin * est_cust_to_cust_riding_leg_coefficient +
    est_cust_to_cust_riding_leg_intercept
  );
};

export const getHubToCustomerRidingLegEstimate = (
  durationInMin: number,
): number => {
  return (
    durationInMin * est_hub_to_cust_riding_leg_coefficient +
    est_hub_to_cust_riding_leg_intercept
  );
};

export const formatEstimateInMs = (estimate: number): string => {
  if (isNaN(estimate)) {
    return "undefined due to missing routing estimate";
  }
  return formatMilliSeconds(estimate);
};
