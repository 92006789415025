import { HoverCard, Table, useMantineTheme } from "@mantine/core";
import { Bottleneck, BottleneckRange, Bottlenecks } from "../types";
import { SubSection } from "./content";
import { Pill } from "./pill";

export const BottleneckHoverCard = (props: {
  bottlenecks: Bottlenecks;
  prevailingBottleneck?: Bottleneck | null;
  timestamp: Date;
}) => {
  const theme = useMantineTheme();
  const bottleneckColors: Record<string, string> = {
    OrderingBottleneck: theme.colors.blue[6],
    PickingBottleneck: theme.colors.red[6],
    RidingBottleneck: theme.colors.green[6],
  };

  const currentBottleneck = props.bottlenecks.Current?.Bottleneck;

  const bottleneckPercentages = getBottleneckPercentages(
    props.bottlenecks,
    props.timestamp,
  );
  return (
    <HoverCard>
      <HoverCard.Target>
        <div>
          <Pill
            sx={{
              color: "white",
              background: calculatePillGradient(
                bottleneckPercentages,
                bottleneckColors,
              ),
              borderRadius: "0px",
            }}
          >
            {currentBottleneck ? currentBottleneck : "Unknown Bottleneck"}
          </Pill>
        </div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Table>
          <SubSection title="Bottlenecks over the last 5 min">
            <tbody>
              {Object.entries(bottleneckPercentages).map(
                ([bottleneck, percentage]) => (
                  <tr key={bottleneck}>
                    <td>
                      <ColouredSquare color={bottleneckColors[bottleneck]} />
                    </td>
                    <td>{bottleneck}</td>
                    <td>{Math.round(percentage)} %</td>
                  </tr>
                ),
              )}
            </tbody>
          </SubSection>
          <SubSection title="Current Bottleneck">
            <TableRow
              color={bottleneckColors[currentBottleneck]}
              label={currentBottleneck}
            />
          </SubSection>
          <SubSection title="Prevailing Bottleneck">
            <TableRow
              color={
                props.prevailingBottleneck
                  ? bottleneckColors[props.prevailingBottleneck]
                  : theme.colors.gray[6]
              }
              label={props.prevailingBottleneck || "None"}
            />
          </SubSection>
        </Table>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const TableRow = (props: { color: string; label: string }) => (
  <tbody>
    <tr>
      <td>
        <ColouredSquare color={props.color} />
      </td>
      <td>{props.label}</td>
    </tr>
  </tbody>
);

const ColouredSquare = (props: { color: string }) => (
  <div
    style={{
      width: 10,
      height: 10,
      backgroundColor: props.color,
    }}
  ></div>
);

type BottleneckColors = Record<Bottleneck, string>;

const calculatePillGradient = (
  percentages: Record<string, number>,
  colors: BottleneckColors,
): string => {
  const gradient =
    Object.entries(percentages).reduce(
      (acc, [colorKey, percentage]) => {
        const color = colors[colorKey as keyof typeof colors];
        acc[0] += ", " + color + " " + acc[1] + "%";
        acc[1] += percentage;
        acc[0] += ", " + color + " " + acc[1] + "%";
        return acc;
      },
      ["linear-gradient(to bottom", 0],
    )[0] + ")";
  return gradient;
};

const getBottleneckPercentages = (
  bottlenecks: Bottlenecks,
  now: Date,
): Record<Bottleneck, number> => {
  const maximumDuration = 5 * 60 * 1000;
  const ranges: BottleneckRange[] = [
    ...bottlenecks.Historic,
    bottlenecks.Current,
  ];

  const result = {
    OrderingBottleneck: 0,
    PickingBottleneck: 0,
    RidingBottleneck: 0,
  } as Record<Bottleneck, number>;

  if (ranges.length === 1) {
    result[ranges[0].Bottleneck] = 100;
    return result;
  }

  const prunedRanges: BottleneckRange[] = ranges.map<BottleneckRange>(
    (range) =>
      ({ Start: range.Start, Bottleneck: range.Bottleneck } as BottleneckRange),
  );

  let totalDuration = now.getTime() - ranges[0].Start.getTime();
  if (totalDuration === 0) {
    return result;
  }

  if (totalDuration > maximumDuration) {
    prunedRanges[0].Start = new Date(now.getTime() - maximumDuration);
    totalDuration = maximumDuration;
  }

  return prunedRanges.reduce<Record<Bottleneck, number>>((acc, curr, i) => {
    let value: number;
    if (i < prunedRanges.length - 1) {
      value =
        ((prunedRanges[i + 1].Start.getTime() - curr.Start.getTime()) * 100) /
        totalDuration;
    } else {
      value = ((now.getTime() - curr.Start.getTime()) * 100) / totalDuration;
    }
    acc[curr.Bottleneck] += value;
    return acc;
  }, result);
};
