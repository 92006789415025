import { Badge, Text } from "@mantine/core";
import { ContainerID } from "../types";

const containerColors: Partial<Record<string, string>> = {
  "1": "green",
  "2": "orange",
  "3": "red",
};

export const ShortContainerIDText = ({
  containerID,
}: {
  containerID: ContainerID;
}) => (
  <>
    <Text span fz="xs" mx={4}>
      {containerID.substring(3, 5)}
    </Text>
    <Text span fz="xs">
      {containerID.substring(5)}
    </Text>
  </>
);

export const ContainerIDText = ({
  containerID,
}: {
  containerID: ContainerID;
}) => (
  <>
    <Text span fz="xs" opacity={0.4}>
      {containerID.substring(0, 3)}
    </Text>
    <ShortContainerIDText containerID={containerID} />
  </>
);

export const Container = ({ containerID }: { containerID: ContainerID }) => (
  <Badge
    mr={2}
    variant={"filled"}
    sx={{ textTransform: "none" }}
    color={containerColors[containerID[3]] || "gray"}
  >
    <ContainerIDText containerID={containerID} />
  </Badge>
);
