import { Group } from "@mantine/core";
import dateFormat from "dateformat";
import { CopyButton } from "./copy";

export const DateTime = (props: { date: Date }) => (
  <Time date={props.date} showDate={true} />
);

export const Time = (props: {
  date: Date;
  showDate?: boolean;
  noCopyButton?: boolean;
}) => {
  const date = new Date(props.date);

  return (
    <Group spacing={0}>
      {props.showDate && <>{dateFormat(date, "yyyy-mm-dd")}&nbsp;</>}
      {dateFormat(date, "HH:MM:ss")}
      {!props.noCopyButton && <CopyButton value={date.toISOString()} />}
    </Group>
  );
};
