import { GeoCoordinates } from "./geojson";
import { toDegrees, toRadians } from "./utils";

export type Point = {
  x: number;
  y: number;
};

export const convertToCircleAngle = (bearing: number) => {
  const bear = 90 - bearing;
  if (bear < 0) {
    return bear + 360;
  } else {
    return bear;
  }
};

export const pointOnCircle = (
  radius: number,
  angle: number,
  center: Point,
): Point => {
  const angleInRad = toRadians(angle);
  const x = radius * Math.cos(angleInRad) + center.x;
  const y = radius * -Math.sin(angleInRad) + center.y;
  return { x: x, y: y };
};

export const farthestDistanceToEdgeOfPolygon = (
  point: GeoCoordinates,
  polygon: GeoCoordinates[][],
) => {
  return polygon.flat(2).reduce((acc, curr) => {
    const distance = greatCircleDistance(curr, point);
    if (distance > acc) {
      acc = distance;
    }
    return acc;
  }, 0);
};

export const greatCircleDistance = (
  point1: GeoCoordinates,
  point2: GeoCoordinates,
) => {
  const R = 6371e3;
  const φ1 = toRadians(point1.latitude);
  const φ2 = toRadians(point2.latitude);
  const Δφ = toRadians(point2.latitude - point1.latitude);
  const Δλ = toRadians(point2.longitude - point1.longitude);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const oppositeBearing = (bearing: number) => {
  if (bearing + 180 > 360) {
    return bearing - 180;
  } else {
    return bearing + 180;
  }
};

export const calculateMidpoint = (
  p1: GeoCoordinates,
  p2: GeoCoordinates,
): GeoCoordinates => {
  const φ1 = toRadians(p1.latitude);
  const φ2 = toRadians(p2.latitude);
  const λ1 = toRadians(p1.longitude);
  const λ2 = toRadians(p2.longitude);

  const Bx = Math.cos(φ2) * Math.cos(λ2 - λ1);
  const By = Math.cos(φ2) * Math.sin(λ2 - λ1);
  const φ3 = Math.atan2(
    Math.sin(φ1) + Math.sin(φ2),
    Math.sqrt((Math.cos(φ1) + Bx) * (Math.cos(φ1) + Bx) + By * By),
  );
  const λ3 = λ1 + Math.atan2(By, Math.cos(φ1) + Bx);
  return {
    latitude: toDegrees(φ3),
    longitude: toDegrees(λ3),
  };
};

export const calculateBearing = (p1: GeoCoordinates, p2: GeoCoordinates) => {
  const φ1 = toRadians(p1.latitude);
  const φ2 = toRadians(p2.latitude);
  const λ1 = toRadians(p1.longitude);
  const λ2 = toRadians(p2.longitude);
  const x = Math.cos(φ2) * Math.sin(λ2 - λ1);
  const y =
    Math.cos(φ1) * Math.sin(φ2) -
    Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
  return (toDegrees(Math.atan2(x, y)) + 360) % 360;
};

export const getBearingOfArrowHeadLine = (
  bearing: number,
  rightLine: boolean,
): number => {
  if (rightLine) {
    return (bearing + 135) % 360;
  } else {
    if (bearing - 135 < 0) {
      return 360 + bearing - 135;
    } else {
      return bearing - 135;
    }
  }
};

export const getArrowPoint = (
  midpoint: GeoCoordinates,
  bearing: number,
): GeoCoordinates => {
  const lat1 = toRadians(midpoint.latitude);
  const lon1 = toRadians(midpoint.longitude);
  // Angular distance of arrow head lines: Arc length divided by Earth radius.
  const angDist = 32 / 6372795;
  const bear = toRadians(bearing);

  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(angDist) +
      Math.cos(lat1) * Math.sin(angDist) * Math.cos(bear),
  );
  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(bear) * Math.sin(angDist) * Math.cos(lat1),
      Math.cos(angDist) - Math.sin(lat1) * Math.sin(lat2),
    );
  return {
    longitude: toDegrees(lon2),
    latitude: toDegrees(lat2),
  };
};
