import { Group } from "@mantine/core";
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
} from "@tabler/icons";
import { ReactNode } from "react";
import { useNavigationContext } from "../../app";
import { ActionIcon } from "../../components/action-icon";
import { Loading } from "../../components/loading";
import { ApiResponse } from "../../config";
import { Nullish } from "../../custom-schemas";
import { HubStateHistorySummary, UUID } from "../../types";

export const StateHistoryNavigation = (props: {
  children: ReactNode;
  response: ApiResponse<HubStateHistorySummary[]>;
  selectedIndex: number;
  isLive: boolean;
  exitLive: () => void;
  currentTime: Date;
  setTimestamp: (timestamp: Nullish<string>) => void;
  setStateId: (id: Nullish<UUID>) => void;
}): JSX.Element => {
  const navigationDistance = useNavigationContext().distance;
  const history = props.response.data;

  if (props.response.loading) {
    return (
      <Group spacing={8}>
        <ActionIcon icon={<Loading />} />
        <ActionIcon icon={<Loading />} />
        {props.children}
        <ActionIcon icon={<Loading />} />
        <ActionIcon icon={<Loading />} />
      </Group>
    );
  }
  return (
    <Group spacing={8}>
      <ActionIcon
        onClick={() => {
          props.exitLive();
          props.setStateId(undefined);
          props.setTimestamp(
            new Date(
              props.currentTime.getTime() - navigationDistance * 60 * 1000,
            ).toISOString(),
          );
        }}
        icon={<IconChevronsLeft />}
        tooltip={"Go Back " + navigationDistance + " Minutes "}
        actionName={"Go back n minutes button"}
      />
      <ActionIcon
        disabled={
          !history ||
          history.length === 0 ||
          props.selectedIndex === history.length - 1
        }
        onClick={() => {
          props.exitLive();
          props.setTimestamp(undefined);
          props.setStateId(history![props.selectedIndex + 1].ID);
        }}
        icon={<IconChevronLeft />}
        tooltip={"Previous state button"}
      />
      {props.children}
      <ActionIcon
        disabled={
          !history ||
          history.length === 0 ||
          props.selectedIndex === 0 ||
          props.isLive
        }
        onClick={() => {
          props.setTimestamp(undefined);
          props.setStateId(history![props.selectedIndex - 1].ID);
        }}
        icon={<IconChevronRight />}
        tooltip={"Next state button"}
      />
      <ActionIcon
        disabled={
          !history ||
          history.length === 0 ||
          props.selectedIndex === 0 ||
          props.isLive
        }
        onClick={() => {
          props.setStateId(undefined);
          props.setTimestamp(
            new Date(
              props.currentTime.getTime() + navigationDistance * 60 * 1000,
            ).toISOString(),
          );
        }}
        icon={<IconChevronsRight />}
        tooltip={"Go Forward " + navigationDistance + " Minutes "}
        actionName={"Go forward n minutes button"}
      />
    </Group>
  );
};
