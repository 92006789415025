import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ReactNode } from "react";
import { z } from "zod";
import { ShowError } from "./components/errors";
import { Loading } from "./components/loading";
import { ApiResponse, customAxios, Schema, useApiResource } from "./config";
import {
  CombinedOrder,
  ExtendedOrderInfo,
  HubSlug,
  OrderID,
  orderPhaseEstimates,
  PickingTask,
  scheduledTripProposal,
  statePlus,
  UUID,
} from "./types";

const HubStateEstimates = z.record(UUID, orderPhaseEstimates);
export type HubStateEstimates = z.infer<typeof HubStateEstimates>;

export type HubData = {
  proposalSchedule?: ApiResponse<scheduledTripProposal[]>;
  representativeEstimate?: ApiResponse<number>;
  hubstateEstimates?: ApiResponse<HubStateEstimates>;
};

export const useHubData = (stateResponse: ApiResponse<statePlus>): HubData => {
  const manual = { manual: !stateResponse?.data };
  const state = stateResponse.data?.State;
  return {
    proposalSchedule: useProposalSchedule(
      {
        hub: state?.Hub,
        id: state?.ID,
      },
      manual,
    ),
    representativeEstimate: useRepresentativeDeliveryEstimate(
      {
        hub: state?.Hub,
        timestamp: state?.Timestamp,
      },
      manual,
    ),
    hubstateEstimates: useApiResource(
      "/hubstate_estimates",
      HubStateEstimates,
      { params: { hub: state?.Hub, id: state?.ID }, ...manual },
    ),
  };
};

export type HubStateQuery =
  | { hub: HubSlug; timestamp?: Date }
  | { hub?: HubSlug; id: UUID };

export const useHubState = (q: HubStateQuery): ApiResponse<statePlus> => {
  return useApiResource("/state_plus", statePlus, { params: q });
};

export type ScheduleQuery = {
  hub?: HubSlug;
  id?: UUID;
};

export const useProposalSchedule = (
  q: ScheduleQuery,
  manual?: ManualRequest,
) => {
  return useApiResource(
    "/schedule_trip_proposals",
    z.array(scheduledTripProposal),
    {
      params: q,
      ...manual,
    },
  );
};

export type OrderNumber = string;
export type OrderQuery = { idOrNumber: OrderID | OrderNumber };

export const useOrder = (q: OrderQuery) => {
  return useApiResource("/order", CombinedOrder, { params: q });
};

export const useExtendedOrderInfo = (id: OrderID) => {
  return useApiResource("/extended-order-info", ExtendedOrderInfo, {
    params: { id },
  });
};

export type RepresentativeEstimateQuery = { hub?: HubSlug; timestamp?: Date };
export type ManualRequest = { manual: boolean };

export const useRepresentativeDeliveryEstimate = (
  q: RepresentativeEstimateQuery,
  manual: ManualRequest,
) => {
  return useApiResource("/representative_delivery_estimate", z.number(), {
    params: q,
    ...manual,
  });
};

type PickerQueueQuery = {
  id?: UUID;
  manual?: boolean;
};

export const usePickerQueue = (q: PickerQueueQuery) => {
  return useApiResource<PickingTask[]>("/picker_queue", z.array(PickingTask), {
    params: { id: q.id },
    manual: q.manual || false,
  });
};

export const downloadConfigurationCSV: () => Promise<
  AxiosResponse<any>
> = () => {
  return customAxios.get("/feature_flags_csv", { responseType: "arraybuffer" });
};

export type ResourceAccessor<T, P> =
  | {
      path: string;
      schema: Schema<T>;
      params?: P;
      config?: Omit<AxiosRequestConfig, "url">;
    }
  | (() => ApiResponse<T>);

export const ApiResource = <T, P>(params: {
  accessor: ResourceAccessor<T, P>;
  children: (data: T) => ReactNode;
}) => {
  const useResource = () => {
    if ("path" in params.accessor) {
      const { path, schema, params: p, config } = params.accessor;
      return useApiResource(path, schema, { ...config, params: p });
    } else {
      return params.accessor();
    }
  };

  const { data, error, loading } = useResource();

  if (!data && loading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return <>{params.children(data!)}</>;
};
