import {
  MantineTheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMemo } from "react";
import { HubStateEstimates } from "../../api";
import { parse, VegaView } from "../../components/vega";
import { bike, hand, homeCheck, paperBag, shoppingCart } from "../../images";
import { scheduledTripProposal } from "../../types";
import { DeliveryTrip } from "./hub-trips-and-proposals-overview";
import { TimelineColors, tripTimelineSpec } from "./trip-timeline-spec";

export const TripTimeline = (props: {
  trip: DeliveryTrip;
  currentTime: Date;
  scheduledTrip?: scheduledTripProposal;
  estimates?: HubStateEstimates;
  width?: number;
}) => {
  const theme = useMantineTheme();
  const spec = useMemo(() => {
    return parse(
      tripTimelineSpec({
        trip: props.trip,
        time: props.currentTime,
        colors: timelineColors(theme),
        width: props.width || 500,
        eventIcons: eventIcons,
        scheduledTrip: props.scheduledTrip,
        estimates: props.estimates,
      }),
    );
  }, [props.trip, props.currentTime]);

  return <VegaView data={spec} />;
};

export type getTimelineColors = (estimate?: boolean) => TimelineColors;

const timelineColors = (theme: MantineTheme): getTimelineColors => {
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (estimate?: boolean) => {
    const intensity = estimate ? 1 : 6;
    return {
      created: theme.colors.yellow[intensity],
      pickerAccepted: theme.colors.orange[intensity],
      pickingComplete: theme.colors.red[intensity],
      riderClaimed: theme.colors.violet[intensity],
      enRoute: theme.colors.violet[intensity],
      arrived: theme.colors.blue[intensity],
      delivered: theme.colors.green[1],
      label: theme.colors.gray[6],
      pdtEarly: theme.colors.green[6],
      pdtLate: theme.colors.red[6],
      pdt: dark ? theme.colors.dark[0] : theme.colors.dark[6],
      delivery_window: dark ? theme.colors.gray[7] : theme.colors.gray[2],
    };
  };
};

export type EventIcons = Record<string, string>;

const eventIcons = {
  pickerAccepted: shoppingCart.data,
  pickingComplete: paperBag.data,
  riderClaimed: hand.data,
  enRoute: bike.data,
  delivered: homeCheck.data,
};
