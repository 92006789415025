import {
  calculateBearing,
  calculateMidpoint,
  getArrowPoint,
  getBearingOfArrowHeadLine,
} from "./geometric-funcs";
import { Coordinates } from "./types";

export type GeoCoordinates = Coordinates;

type Longitude = number;
type Latitude = number;

export type GeoJSON = {
  type: "FeatureCollection";
  features: GeoJSONFeature[];
};

export type GeoJSONFeature = {
  type: "Feature";
  properties: Record<string, any>;
  geometry:
    | { type: "Point"; coordinates: [Longitude, Latitude] }
    | { type: "Polygon"; coordinates: [Longitude, Latitude][][] }
    | { type: "LineString"; coordinates: [Longitude, Latitude][] };
};

export const polygons = (
  turfs: GeoCoordinates[][],
  properties: Record<string, any> = {},
): GeoJSONFeature[] => {
  return turfs.map((turf) => {
    return {
      type: "Feature",
      properties,
      geometry: {
        type: "Polygon",
        coordinates: [
          turf.map<[number, number]>(({ latitude, longitude }) => [
            longitude,
            latitude,
          ]),
        ],
      },
    };
  });
};

export const point = (
  gc: GeoCoordinates,
  properties: Record<string, any> = {},
): GeoJSONFeature => ({
  type: "Feature",
  properties,
  geometry: {
    type: "Point",
    coordinates: [gc.longitude, gc.latitude],
  },
});

export const line = (
  coordinates: GeoCoordinates[],
  properties: Record<string, any> = {},
): GeoJSONFeature => ({
  type: "Feature",
  properties,
  geometry: {
    type: "LineString",
    coordinates: coordinates.map<[number, number]>((gc) => [
      gc.longitude,
      gc.latitude,
    ]),
  },
});

export const lineWithArrow = (
  coordinates: GeoCoordinates[],
  properties: Record<string, any> = {},
): GeoJSONFeature[] => {
  return [line(coordinates, properties), ...arrowHead(coordinates, properties)];
};

export const collection = (
  ...features: (GeoJSONFeature | undefined)[]
): GeoJSON => {
  return {
    type: "FeatureCollection",
    features: features.filter((f) => f !== undefined) as GeoJSONFeature[],
  };
};

const arrowHead = (
  coordinates: GeoCoordinates[],
  properties: Record<string, any> = {},
) => {
  if (coordinates.length < 2) {
    return [];
  }

  coordinates = Array.from(coordinates);
  const features: GeoJSONFeature[] = [];
  coordinates.reduce((previous, c) => {
    const midPoint = calculateMidpoint(previous, c);
    const bearing = calculateBearing(previous, c);
    features.push(
      line(
        [
          midPoint,
          getArrowPoint(midPoint, getBearingOfArrowHeadLine(bearing, true)),
        ],
        properties,
      ),
      line(
        [
          midPoint,
          getArrowPoint(midPoint, getBearingOfArrowHeadLine(bearing, false)),
        ],
        properties,
      ),
    );

    return c;
  }, coordinates.shift()!);
  return features;
};
