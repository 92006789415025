import {
  Flex,
  Group,
  Stack,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import dateFormat from "dateformat";
import { ReactNode } from "react";
import { CopyButton } from "../../components/copy";
import { Time } from "../../components/date";
import { getRiderPseudonym } from "../../rider-pseudonyms";
import { Rider, Window } from "../../types";
import { isEmptyDate } from "../../utils";

type ShiftColumnData = {
  content:
    | ((rider: Rider, time: Date) => ReactNode)
    | ((
        rider: Rider,
        time: Date,
        dayBoundaries: Window,
        width: number,
      ) => ReactNode);
  size: number;
};

export const RiderShiftView = (props: { riders: Rider[]; time: Date }) => {
  const { width } = useViewportSize();
  const tableWidth = 0.8 * width;

  const sortedRiders = sortRiderShifts(props.riders, props.time);
  const dayBoundaries = getBusinessDayBoundaries(props.riders);

  return (
    <Stack>
      <Table
        width={tableWidth}
        sx={{ position: "relative", tableLayout: "fixed" }}
        verticalSpacing={4}
      >
        <thead>
          <tr>
            {Object.entries(riderShiftColumns).map(([label, data], i) => (
              <th
                key={label}
                style={{
                  width: (data.size / totalColumns) * tableWidth,
                }}
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {sortedRiders.map((rider) => (
            <tr key={rider.ID}>
              {Object.values(riderShiftColumns).map((column, i) => (
                <td
                  key={i}
                  style={{
                    width: (column.size / totalColumns) * tableWidth,
                    ...(i === 1 && { padding: "0px" }),
                  }}
                >
                  {column.content(
                    rider,
                    props.time,
                    dayBoundaries,
                    (column.size / totalColumns) * tableWidth,
                  )}
                </td>
              ))}
            </tr>
          ))}
          {hasValidDates(dayBoundaries) && (
            <GuideLines
              dayBoundaries={dayBoundaries}
              time={props.time}
              width={
                (tableWidth * riderShiftColumns["Rider Shift Timeline"].size) /
                totalColumns
              }
            />
          )}
        </tbody>
      </Table>
    </Stack>
  );
};

// Sorts the riders according to the start of their first shift and then according to whether they are no shows
export const sortRiderShifts = (riders: Rider[], time: Date) => {
  const sortedByShiftStart = riders.sort((a, b) => {
    if (a.PlannedShifts.length > 0 && b.PlannedShifts.length > 0) {
      return (
        a.PlannedShifts[0].start.getTime() - b.PlannedShifts[0].start.getTime()
      );
    }
    return 0;
  });
  return [
    ...sortedByShiftStart.filter((rider) => !isNoShow(rider, time)),
    ...sortedByShiftStart.filter((rider) => isNoShow(rider, time)),
  ];
};

const isNoShow = (rider: Rider, time: Date) => {
  if (isEmptyDate(rider.StatusChange) && hasCurrentShift(rider, time)) {
    return true;
  }
  return false;
};

export const hasCurrentShift = (rider: Rider, time: Date): boolean => {
  const shifts = rider.PlannedShifts;
  for (let i = 0; i < shifts.length; i++) {
    if (
      time.getTime() > shifts[i].start.getTime() &&
      shifts[i].end.getTime() > time.getTime()
    ) {
      return true;
    }
  }
  return false;
};

// Returns the start of the earliest and the end of the latest shift of the given day
const getBusinessDayBoundaries = (riders: Rider[]): Window => {
  const dayStart = Math.min(
    ...riders.flatMap((rider) =>
      rider.PlannedShifts.map((shift) => {
        if (isEmptyDate(shift.start)) {
          return Number.POSITIVE_INFINITY;
        }
        return shift.start.getTime();
      }),
    ),
  );
  const dayEnd = Math.max(
    ...riders.flatMap((rider) =>
      rider.PlannedShifts.map((shift) => {
        if (isEmptyDate(shift.end)) {
          return 0;
        }
        return shift.end.getTime();
      }),
    ),
  );
  return {
    start: new Date(dayStart),
    end: new Date(dayEnd),
  };
};

const riderShiftColumns: Record<string, ShiftColumnData> = {
  Rider: {
    content: (rider: Rider) => (
      <>
        <Tooltip label={rider.ID}>
          <Flex direction={"row"} align={"flex-start"}>
            <Group
              spacing={0}
              style={{ justifyContent: "flex-end", width: "100%" }}
              noWrap
            >
              <Text
                c="dimmed"
                fz={10}
                fw={200}
                sx={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                mr={6}
              >
                {rider.ID}
              </Text>
              {getRiderPseudonym(rider.ID)}
              <CopyButton value={rider.ID} />
            </Group>
          </Flex>
        </Tooltip>
      </>
    ),
    size: 10,
  },
  "Rider Shift Timeline": {
    content: (
      rider: Rider,
      time: Date,
      dayBoundaries: Window,
      width: number,
    ) => (
      <RiderShiftTimeline
        rider={rider}
        dayBoundaries={dayBoundaries}
        width={width}
        time={time}
      />
    ),
    size: 50,
  },
  "Rider Status": {
    content: (rider: Rider) => rider.Status,
    size: 10,
  },
  "Last Status Change": {
    content: (rider: Rider, time: Date) => {
      if (isEmptyDate(rider.StatusChange)) {
        return <></>;
      }
      return (
        <Text weight={hasRecentStatusChange(rider, time) ? "bold" : "normal"}>
          <Time date={rider.StatusChange} noCopyButton={true} />
        </Text>
      );
    },
    size: 10,
  },
};

// Determines if a rider had a recent status change with a threshhold of 10 minutes
const hasRecentStatusChange = (rider: Rider, time: Date): boolean => {
  return time.getTime() - rider.StatusChange.getTime() < 10 * 60 * 1000;
};

const totalColumns = Object.values(riderShiftColumns).reduce((acc, curr) => {
  acc += curr.size;
  return acc;
}, 0);

const hasValidDates = (boundaries: Window) => {
  return !isNaN(boundaries.start.getTime()) && !isNaN(boundaries.end.getTime());
};

const GuideLines = (props: {
  dayBoundaries: Window;
  time: Date;
  width: number;
}) => {
  const dayStartTime = props.dayBoundaries.start.getTime();
  const dayEndTime = props.dayBoundaries.end.getTime();

  const getLabelMargin = (date: Date) => {
    return (
      ((date.getTime() - dayStartTime) / (dayEndTime - dayStartTime)) *
      props.width
    );
  };

  return (
    <tr>
      <td style={{ border: "none" }}></td>
      <td style={{ border: "none" }}>
        {Object.entries(guideLinesData).map(([label, getHelperlineDate]) => {
          const helperLineTime = getHelperlineDate(props.time);

          if (isWithinWindow(helperLineTime, props.dayBoundaries)) {
            return (
              <Text
                key={label}
                size={"xs"}
                sx={{
                  position: "absolute",
                  marginLeft:
                    getLabelMargin(getHelperlineDate(props.time)) - 14,
                }}
              >
                {label}
              </Text>
            );
          }
          return <div key={label}></div>;
        })}
        {isWithinWindow(props.time, props.dayBoundaries) && (
          <Text
            size={"xs"}
            sx={{
              position: "absolute",
              marginLeft: getLabelMargin(props.time) - 12,
              marginTop: isCloseToLabel(props.time) ? "10px" : "0px",
            }}
          >
            Now
          </Text>
        )}
      </td>
    </tr>
  );
};

const guideLinesData: Record<string, (time: Date) => Date> = {
  "6:00": (time: Date) => replaceTimeOfDate(time, "06:00:00.000"),
  "12:00": (time: Date) => replaceTimeOfDate(time, "12:00:00.000"),
  "18:00": (time: Date) => replaceTimeOfDate(time, "18:00:00.000"),
  "24:00": (time: Date) => replaceTimeOfDate(time, "24:00:00.000"),
};

const replaceTimeOfDate = (date: Date, newTime: string) => {
  return new Date(dateFormat(date, "yyyy-mm-dd") + " " + newTime);
};

export const isWithinWindow = (time: Date, window: Window) => {
  if (
    time.getTime() >= window.start.getTime() &&
    time.getTime() <= window.end.getTime()
  ) {
    return true;
  }
  return false;
};

const isCloseToLabel = (time: Date) => {
  const labelDates = Object.values(guideLinesData).map((getDate) =>
    getDate(time),
  );

  for (let i = 0; i < labelDates.length; i++) {
    if (Math.abs(labelDates[i].getTime() - time.getTime()) < 45 * 60 * 1000) {
      return true;
    }
  }
  return false;
};

export const getShiftStatus = (
  rider: Rider,
  shift: Window,
  time: Date,
): ShiftStatus => {
  if (rider.Status !== "Offline") {
    return "activeShift";
  }

  if (time.getTime() < shift.start.getTime()) {
    return "upcomingShift";
  }

  if (time.getTime() > shift.end.getTime()) {
    return "finishedShift";
  }

  if (isEmptyDate(rider.StatusChange)) {
    return "noShow";
  }
  return "finishedEarly";
};

type ShiftStatusColors = {
  activeShift: string;
  noShow: string;
  upcomingShift: string;
  finishedShift: string;
  finishedEarly: string;
};

const getShiftColor = (): ShiftStatusColors => {
  const theme = useMantineTheme();
  const dark = theme.colorScheme === "dark";

  return {
    activeShift: theme.colors.gray[dark ? 3 : 6],
    noShow: theme.colors.red[dark ? 4 : 2],
    upcomingShift: theme.colors.gray[dark ? 7 : 2],
    finishedShift: theme.colors.gray[dark ? 7 : 2],
    finishedEarly: theme.colors.gray[dark ? 7 : 2],
  };
};

type ShiftStatus = keyof ShiftStatusColors;

const RiderShiftTimeline = (props: {
  rider: Rider;
  dayBoundaries: Window;
  width: number;
  time: Date;
}) => {
  if (!hasValidDates(props.dayBoundaries)) {
    return <></>;
  }
  const rowHeight = 36;
  const shifts = props.rider.PlannedShifts;
  const dayStart = props.dayBoundaries.start.getTime();
  const dayEnd = props.dayBoundaries.end.getTime();
  const totalTime = dayEnd - dayStart;

  const convertToX = (start: Date) => {
    return ((start.getTime() - dayStart) / totalTime) * props.width;
  };

  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <svg width={"99%"} height={rowHeight}>
        {shifts.map((shift, i) => {
          return (
            <rect
              key={i}
              x={convertToX(shift.start)}
              width={convertToX(shift.end) - convertToX(shift.start)}
              y={rowHeight / 2 - 5}
              height={10}
              fill={
                getShiftColor()[getShiftStatus(props.rider, shift, props.time)]
              }
            >
              <title>
                Shift Status:
                {"\t" + getShiftStatus(props.rider, shift, props.time) + "\n"}
                Shift Start:
                {"\t" + dateFormat(shift.start, "HH:MM:ss") + "\n"}
                Shift End:
                {"\t\t" + dateFormat(shift.end, "HH:MM:ss")}
              </title>
            </rect>
          );
        })}
        <rect
          x={convertToX(props.time)}
          width={1}
          height={rowHeight}
          fill={"red"}
        />
        {Object.entries(guideLinesData).map(([label, getDate]) => (
          <rect
            key={label}
            x={convertToX(getDate(props.time))}
            width={1}
            height={rowHeight}
            fill={"gray"}
          />
        ))}
      </svg>
    </div>
  );
};
