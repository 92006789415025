import { z } from "zod";

export type Timestamp = { Time: Date | undefined; Inferred: boolean };

export type Nullish<T> = T | undefined | null;

const parseTimestamp = (str: string) => {
  if (str.length === 0) {
    return { Time: undefined, Inferred: false };
  }

  const parts = str.split(" ");
  return {
    Time: new Date(parts[0]),
    Inferred: parts.length > 1,
  };
};

// By default Zod strips out unrecognized object keys during parsing.
// This function recursively copies and modifies an existing schema to retain all the fields
// of the original parsed object while still maintaining the original schema's constraints.
const retainUndeclaredFields = <T extends z.ZodTypeAny>(schema: T): T => {
  let target: z.ZodTypeAny;
  if (schema instanceof z.ZodObject) {
    const shape = Object.fromEntries(
      Object.entries(schema.shape).map(([key, value]) => {
        return [key, retainUndeclaredFields(value as z.ZodTypeAny)];
      }),
    );

    target = z.object(shape).passthrough();
  } else if (schema instanceof z.ZodArray) {
    target = z.array(retainUndeclaredFields(schema.element));
  } else if (schema instanceof z.ZodRecord) {
    target = z.record(retainUndeclaredFields(schema.element));
  } else {
    return schema;
  }

  if (schema.isNullable()) {
    target = target.nullable();
  }
  if (schema.isOptional()) {
    target = target.optional();
  }

  return target as T;
};

export const custom = {
  UUID: z.string().uuid(),
  Time: z.string().transform((str) => new Date(str)),
  Duration: z.number(),
  TimestampEvents: z
    .tuple([
      z.string(),
      z.string(),
      z.string(),
      z.string(),
      z.string(),
      z.string(),
      z.string(),
    ])
    .transform<{
      created: Timestamp;
      pickerAccepted: Timestamp;
      pickingComplete: Timestamp;
      riderClaimed: Timestamp;
      enRoute: Timestamp;
      arrived: Timestamp;
      delivered: Timestamp;
    }>((tuple) => {
      const parse = (index: number) => {
        const str = tuple[index];
        return parseTimestamp(str);
      };
      return {
        created: parse(0),
        pickerAccepted: parse(1),
        pickingComplete: parse(2),
        riderClaimed: parse(3),
        enRoute: parse(4),
        arrived: parse(5),
        delivered: parse(6),
      };
    }),
  Timestamp: z.string().transform(parseTimestamp),
  OrderIDSet: z.array(z.string()).nullish(),
  JSON: z.any(),
  RawCTOrder: retainUndeclaredFields(
    z.object({
      // This models the parts of the raw commercetools order that we directly use. Feel free to add more as needed.
      orderNumber: z.string(),
      createdAt: z.string(),
      lineItems: z.array(
        z.object({
          id: z.string(),
          variant: z.object({
            sku: z.string(),
            attributes: z.array(
              z.object({
                name: z.string(),
                value: z.any(),
              }),
            ),
            images: z.array(
              z.object({
                url: z.string(),
              }),
            ),
          }),
        }),
      ),
      custom: z.object({
        fields: z.record(z.any()),
      }),
    }),
  ),
  RiderStatus: z.string(),
  DeliveryType: z.string(),
  DeliveryMethod: z.string(),
  Bottleneck: z.string(),
  TemperatureCategory: z.string(),
  TemperatureCategories: z.array(z.string()).nullish(),
  Event: z.object({
    kind: z.string(),
    value: z.any(),
  }),
  Group: z.string(),
  PhaseDurations: z
    .record(z.string(), z.number())
    .nullish()
    .transform((m) => m ?? {}),
  Vehicle: z.object({
    ID: z.string(),
    RoutingType: z.string(),
    VehicleType: z.string(),
    MaxWeightGrams: z.number(),
    Equipment: z
      .array(
        z
          .object({
            ContainerType: z.string(),
          })
          .nullish(),
      )
      .nullish(),
  }),
};
