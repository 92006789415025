import { useMantineTheme } from "@mantine/core";
import dateFormat from "dateformat";
import { lineWithArrow, point } from "./geojson";
import {
  Delivery,
  DeliveryWindow,
  HistoricHubState,
  HubState,
  statePlus,
  Time,
  TimestampEvents,
} from "./types";

export const orderedTimestampEvents: Record<keyof TimestampEvents, string> = {
  created: "Created",
  pickerAccepted: "Picker Accepted",
  pickingComplete: "Picking Complete",
  riderClaimed: "Rider Claimed",
  enRoute: "En Route",
  arrived: "Arrived",
  delivered: "Delivered",
};

const events = Object.keys(orderedTimestampEvents) as (keyof TimestampEvents)[];

export const getLatestEventIndex = (timestamps: TimestampEvents) => {
  const firstMissing = events.findIndex((event) => !timestamps[event].Time);
  return firstMissing === -1 ? events.length : firstMissing;
};

export const getLatestEvent = (timestamps: TimestampEvents) => {
  const event = events[getLatestEventIndex(timestamps) - 1];
  return orderedTimestampEvents[event];
};

export const getStateColor = (
  timestamps: TimestampEvents,
  withheldFromPicking: boolean = false,
): string => {
  const theme = useMantineTheme();
  return timestamps.delivered.Time
    ? theme.colors.green[6]
    : timestamps.arrived.Time
    ? theme.colors.blue[6]
    : timestamps.riderClaimed.Time
    ? theme.colors.violet[6]
    : timestamps.pickingComplete.Time
    ? theme.colors.red[6]
    : timestamps.pickerAccepted.Time
    ? theme.colors.orange[6]
    : !withheldFromPicking
    ? theme.colors.yellow[6]
    : theme.colors.gray[6];
};

export const formatDeliveryWindow = (
  w: DeliveryWindow | undefined | null,
): string =>
  w == null
    ? "N/A"
    : dateFormat(w.start, "HH:MM:ss") + " – " + dateFormat(w.end, "HH:MM:ss");

export const isEmptyDate = (date: Time): boolean => date.getFullYear() <= 1;

export function zip<T, U>(a: Array<T>, b: Array<U>): Array<[T, U]> {
  return a.map((k, i) => [k, b[i]]);
}

export const toRadians = (degrees: number): number => {
  return (Math.PI / 180) * degrees;
};

export const toDegrees = (radians: number): number => {
  return (180 / Math.PI) * radians;
};

// formats a number with an optional unit, using a thin space as a thousands separator and to separate the number from the unit.
export const formatNumber = (n: number | undefined, unit?: string): string => {
  return formatNumberString(
    n === undefined ? "(undefined)" : n.toString(),
    unit,
  );
};

// formats a number string with potential decimal but not yet with thousands separators, using a thin space as a thousands separator and to separate the number from the optional unit.
export const formatNumberString = (n: string, unit?: string): string => {
  const thinSpace = "\u2009";
  const base = n.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, thinSpace);
  return unit ? base + thinSpace + unit : base;
};

export const formatMilliSeconds = (time: number): string => {
  if (isNaN(time)) return "undefined";
  const seconds = time / 1000;
  return `${Math.floor(seconds / 60)} min ${(seconds % 60).toFixed(0)} s`;
};

export const formatMinutes = (time: number): string => {
  const milliseconds = time * 60 * 1000;
  return formatMilliSeconds(milliseconds);
};

export const formatDurationInMinutes = (date1: Date, date2: Date): string => {
  return formatMilliSeconds(durationInMs(date1, date2));
};

export const shiftDate = (date: Date, durationInMs: number): Date => {
  return new Date(durationInMs + date.getTime());
};

export const durationInMs = (date1: Date, date2: Date): number => {
  const time1 = date1.getTime();
  const time2 = date2.getTime();
  return Math.abs(time1 - time2);
};

export const deliverableFeatures = (
  deliverables: DeliverySequence[],
  color: string,
) => {
  return deliverables.flatMap((t) => {
    const deliveries = [t.FirstDelivery, ...t.IndirectDeliveries];
    const coordinates = deliveries.map((d) => d.DeliveryCoords);
    return [
      ...deliveries.map((d) =>
        point(d.DeliveryCoords, {
          description: d.ID,
          color,
        }),
      ),
      ...lineWithArrow(coordinates, { color }),
    ];
  });
};

type DeliverySequence = {
  FirstDelivery: Delivery;
  IndirectDeliveries: Delivery[];
};

/**
 * Tries to parse the given hubstate.
 * It will also attempt to parse HistoricHubState or a "statePlus", so that we can also
 * paste hubstates obtained from the DB or that we copied over the wire.
 * */
export const parseHubState = (hubStateText: string): HubState | undefined => {
  const parsers = [
    HubState,
    HistoricHubState.transform((hs) => hs.State),
    statePlus.transform((hs) => hs.State),
  ];

  try {
    const json = JSON.parse(hubStateText);

    var errors = [];

    for (const parser of parsers) {
      const parsed = parser.safeParse(json);
      if (parsed.success) {
        return parsed.data;
      }
      errors.push(parsed.error);
    }
    console.error("Zod parsing for hubstate failed", errors);
  } catch (e) {
    console.error("JSON parsing for hubstate failed", e);
  }
};

// Ensures a value conforms to the given type, while preserving the exact const literal type of the value.
export const constConformingTo =
  <T>() =>
  <C extends T>(c: C): C =>
    c;

// Retrieves a value from an object with a const type, for a key isn't statically known to be in the object.
export const valueOrUndefined = <
  K extends string | number | symbol,
  R extends Partial<Record<K, any>>,
>(
  record: R,
  key: K,
): R[keyof R] | undefined => record[key];
