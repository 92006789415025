import dateFormat from "dateformat";
import { HubStateEstimates } from "../../api";
import {
  EventSpec,
  IconSpec,
  PhaseSpec,
  VegaData,
  VegaSpec,
  VegaToolTip,
} from "../../components/vega";
import { tripDeliveries } from "../../methods";
import {
  orderPhaseEstimates,
  OutsourcedTrip,
  scheduledTripProposal,
  Timestamp,
  TimestampEvents,
  Trip,
  UUID,
} from "../../types";
import {
  formatDurationInMinutes,
  formatMilliSeconds,
  getLatestEventIndex,
  isEmptyDate,
  orderedTimestampEvents,
  shiftDate,
} from "../../utils";
import { DeliveryTrip } from "./hub-trips-and-proposals-overview";
import { getTimelineColors } from "./trip-timeline";

type TripTimelineProps = {
  trip: DeliveryTrip;
  time: Date;
  width: number;
  colors: getTimelineColors;
  eventIcons: EventIcons;
  scheduledTrip?: scheduledTripProposal;
  estimates?: HubStateEstimates;
};

export const tripTimelineSpec = (props: TripTimelineProps): VegaSpec => {
  const { trip, time, width, colors, eventIcons, scheduledTrip, estimates } =
    props;

  const height = tripDeliveries(trip).length * 33;
  const [phases, icons] = extractStateChanges(
    trip,
    time,
    colors,
    eventIcons,
    estimates,
  );
  const [pdts, events] = extractPdtsAndEvents(trip, time, colors);
  if (scheduledTrip) {
    events.push({
      name: "ExpectedClaimAt",
      time: scheduledTrip.expected_claim,
    });
  }

  const range = [
    ...pdts.map((pdt) => pdt.time),
    ...phases.flatMap((state) => [state.start, state.end]),
    ...events.map((event) => event.time),
  ].map((time) => ({ time }));

  // Make the different data conform to vega data type
  const vegaData: VegaData[] = Object.entries({
    range: range,
    pdts: pdts,
    phases: phases,
    events: events,
    icons: icons,
  } as Record<string, any[]>).map(
    ([label, data]): VegaData => ({
      name: label,
      format: { type: "json" },
      values: data,
    }),
  );

  return {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: "Timeline of orders in a trip",
    width: width,
    height: height,
    padding: 0,
    data: vegaData,
    scales: [
      {
        name: "yscale",
        type: "band",
        range: [
          0,
          {
            signal: "height",
          },
        ],
        domain: {
          data: "phases",
          field: "line",
        },
      },
      {
        name: "xscale",
        type: "time",
        range: "width",
        round: true,
        domain: {
          data: "range",
          field: "time",
        },
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "xscale",
        format: "%H:%M:%S",
        labelAngle: -80,
        labelAlign: "right",
        labelColor: colors().label,
      },
    ],
    marks: [
      {
        type: "rect",
        from: {
          data: "phases",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "start",
            },
            x2: {
              scale: "xscale",
              field: "end",
            },
            yc: {
              scale: "yscale",
              field: "line",
              band: 0.5,
            },
            height: {
              field: "height",
            },
            tooltip: {
              field: "tooltip",
            },
            fill: {
              field: "color",
            },
          },
        },
      },
      {
        type: "rule",
        from: {
          data: "events",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "time",
            },
            y: {
              value: -6,
            },
            y2: {
              field: {
                group: "height",
              },
            },
            stroke: {
              value: "gray",
            },
          },
        },
      },
      {
        type: "rect",
        from: {
          data: "pdts",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "time",
              offset: -2,
            },
            y: {
              field: "y",
            },
            y2: {
              scale: "yscale",
              field: "line",
            },
            stroke: {
              value: "black",
            },
            fill: {
              field: "color",
            },
            tooltip: {
              field: "tooltip",
            },
            width: { value: 4 },
          },
        },
      },
      {
        type: "text",
        from: {
          data: "events",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "time",
              offset: 2,
            },
            y: {
              value: -10,
            },
            angle: {
              value: -70,
            },
            fill: {
              value: colors().label,
            },
            text: {
              field: "name",
            },
            fontSize: {
              value: 10,
            },
          },
        },
      },
      {
        type: "image",
        from: {
          data: "icons",
        },
        encode: {
          enter: {
            url: { field: "url" },
            tooltip: {
              field: "tooltip",
            },
            x: {
              scale: "xscale",
              field: "time",
              offset: -10,
            },
            yc: {
              scale: "yscale",
              field: "line",
              band: 0.5,
            },
            width: { value: 20 },
            height: { value: 20 },
          },
        },
      },
    ],
  };
};

const extractPdtsAndEvents = (
  data: DeliveryTrip,
  currentTime: Date,
  colors: getTimelineColors,
): [PdtSpec[], EventSpec[]] => {
  const pdts: PdtSpec[] = [];
  const events: EventSpec[] = [
    {
      time: currentTime,
      name: "Now",
    },
  ];

  if (data.hasOwnProperty("CompletedAt")) {
    const trip = data as Trip | OutsourcedTrip;
    if (!isEmptyDate(trip.CompletedAt)) {
      events.pop();
      events.push({
        time: trip.CompletedAt,
        name: "Rider Returned",
      });
    }
  }

  tripDeliveries(data).flatMap((order, i) => {
    const pdtTime = new Date(
      order.Timestamps.Events.created.Time!.getTime() + order.PDT * 60000,
    );
    const deliveredTime = order.Timestamps.Events.delivered.Time?.getTime();
    const pdtData = getPdtData(deliveredTime, pdtTime, currentTime, colors());

    if (order.delivery_window) {
      events.push(
        {
          time: order.delivery_window.start,
          name: "Start",
        },
        {
          time: order.delivery_window.end,
          name: "End",
        },
      );
    } else {
      pdts.push({
        line: order.ID,
        time: pdtTime,
        y: (i + 1) * 33,
        tooltip: {
          PDT: dateFormat(pdtTime, "HH:MM:ss"),
          ...pdtData.duration,
        },
        color: pdtData.color,
      });

      events.push({
        time: pdtTime,
        name: "PDT",
      });
    }
  });
  return [pdts, events];
};

const getPdtData = (
  deliveredTime: number | undefined,
  pdtTime: Date,
  currentTime: Date,
  colors: TimelineColors,
): PdtData => {
  if (deliveredTime) {
    if (deliveredTime > pdtTime.getTime()) {
      return {
        color: colors.pdtLate,
        duration: {
          MissedBy: formatMilliSeconds(deliveredTime - pdtTime.getTime()),
        },
      };
    } else {
      return {
        color: colors.pdtEarly,
        duration: {
          TimeToPdt: formatMilliSeconds(pdtTime.getTime() - deliveredTime),
        },
      };
    }
  } else {
    if (currentTime > pdtTime) {
      return {
        color: colors.pdtLate,
        duration: {
          CurrentlyMissedBy: formatMilliSeconds(
            currentTime.getTime() - pdtTime.getTime(),
          ),
        },
      };
    } else {
      return {
        color: colors.pdt,
        duration: {
          DueIn: formatMilliSeconds(pdtTime.getTime() - currentTime.getTime()),
        },
      };
    }
  }
};

export const getValidEvents = (
  timestamps: TimestampEvents,
): [string, Timestamp][] => {
  return Object.keys(orderedTimestampEvents)
    .slice(0, getLatestEventIndex(timestamps))
    .map((key) => {
      const timestamp = timestamps[key as keyof TimestampEvents];
      return [key, timestamp] as [string, Timestamp];
    });
};

type PhasesByEvent = Record<string, keyof orderPhaseEstimates>;

const phasesByEvent: PhasesByEvent = {
  created: "QueueingForPickerPhase",
  pickerAccepted: "PickingPhase",
  pickingComplete: "QueueingForRiderPhase",
  riderClaimed: "RiderPreparationPhase",
  enRoute: "RidingPhase",
  arrived: "HandingOffPhase",
};

const extractStateChanges = (
  data: DeliveryTrip,
  current: Date,
  colors: getTimelineColors,
  eventIcons: EventIcons,
  estimates?: HubStateEstimates,
): [PhaseSpec[], IconSpec[]] => {
  const states: PhaseSpec[] = [];
  const icons: IconSpec[] = [];

  tripDeliveries(data).map((order) => {
    if (order.delivery_window) {
      states.push({
        line: order.ID,
        start: order.delivery_window.start,
        end: order.delivery_window.end,
        color: colors()["delivery_window" as keyof TimelineColors],
        height: 30,
        tooltip: {
          Phase: "deliveryWindow",
          Start: dateFormat(order.delivery_window.start, "HH:MM:ss"),
          End: dateFormat(order.delivery_window.end, "HH:MM:ss"),
        },
      });
    }

    const phaseEstimates = estimates ? estimates[order.ID] : undefined;
    const timestamps = order.Timestamps.Events;
    const timestampArray = Object.values(timestamps);
    const lastEventIndex = getLatestEventIndex(order.Timestamps.Events);
    Object.keys(orderedTimestampEvents)
      .map((key) => key as keyof TimestampEvents)
      .map((key, i) => {
        const phase = phasesByEvent[key as keyof PhasesByEvent];
        const eventTime = timestamps[key].Time;

        if (eventIcons.hasOwnProperty(key)) {
          let url = eventIcons[key as keyof typeof eventIcons];
          const isEstimate = !eventTime;

          if (!isEstimate || (isEstimate && phaseEstimates)) {
            let time = eventTime;
            if (isEstimate) {
              time = estimatedPhaseStart(
                phaseEstimates!,
                phase,
                timestamps.created.Time!,
              );
              url = url.replace(/currentColor/, "#CCCCCC");
            }
            icons.push({
              url: `data:image/svg+xml;base64,${window.btoa(url)}`,
              time: time!,
              line: order.ID,
              tooltip: {
                Event: key,
                [isEstimate ? "EstimatedTime" : "Time"]: dateFormat(
                  time,
                  "HH:MM:ss",
                ),
              },
            });
          }
        }

        if (key === "delivered") {
          return;
        }
        const inProgress = i === lastEventIndex - 1;
        const phaseEnd = inProgress ? current : timestampArray[i + 1].Time!;

        if (i < lastEventIndex) {
          const estimatedPhaseEnd =
            inProgress && phaseEstimates
              ? shiftDate(eventTime!, phaseEstimates[phase] * 60 * 1000)
              : phaseEnd;
          const tooltip = phase
            ? getTooltip(inProgress, eventTime!, estimatedPhaseEnd, key)
            : {};

          states.push({
            line: order.ID,
            start: eventTime!,
            end: phaseEnd,
            color: colors()[key as keyof TimelineColors],
            height: 6,
            tooltip: tooltip,
          });

          if (inProgress && phaseEstimates) {
            states.push({
              line: order.ID,
              start: current,
              end: estimatedPhaseEnd,
              color: colors(true)[key as keyof TimelineColors],
              height: 6,
              tooltip: tooltip,
            });
          }
        }
        // Phase estimates
        else if (phaseEstimates && phase) {
          const estPhaseStart = estimatedPhaseStart(
            phaseEstimates,
            phase,
            timestamps.created.Time!,
          );
          const estPhaseEnd = shiftDate(
            estPhaseStart,
            phaseEstimates[phase] * 60 * 1000,
          );
          const tooltip = getTooltip(true, estPhaseStart, estPhaseEnd, key);

          states.push({
            line: order.ID,
            start: estPhaseStart,
            end: estPhaseEnd,
            color: colors(true)[key as keyof TimelineColors],
            height: 6,
            tooltip: tooltip,
          });
        }
      });
  });
  return [states, icons];
};

const estimatedPhaseStart = (
  phaseEstimates: orderPhaseEstimates,
  phase: string,
  time: Date,
): Date => {
  const phases = Object.entries(phaseEstimates);
  let duration = 0;
  for (let i = 0; i < phases.length; i++) {
    if (phases[i][0] === phase) {
      break;
    }
    duration += phases[i][1];
  }
  return shiftDate(time, duration * 60 * 1000);
};

const getTooltip = (
  isEstimated: boolean,
  start: Date,
  end: Date,
  event: string,
): VegaToolTip => {
  if (event === "delivered") {
    return {
      Phase: "delivered",
      DeliveredAt: dateFormat(start, "HH:MM:ss"),
    };
  }
  const endLabel = isEstimated ? "EstimatedEnd" : "End";
  const durationLabel = isEstimated ? "EstimatedDuration" : "Duration";
  return {
    Phase: phasesByEvent[event as keyof PhasesByEvent],
    Start: dateFormat(start, "HH:MM:ss"),
    [endLabel]: dateFormat(end, "HH:MM:ss"),
    [durationLabel]: formatDurationInMinutes(end, start),
  };
};

type PdtData = {
  color: string;
  duration:
    | {
        MissedBy: string;
      }
    | {
        TimeToPdt: string;
      }
    | {
        DueIn: string;
      }
    | {
        CurrentlyMissedBy: string;
      };
};

type PdtSpec = {
  line: UUID;
  time: Date;
  y: number;
  color: string;
  tooltip?: {
    PDT: Date | string;
  };
};

export interface PhaseToolTip extends VegaToolTip {
  Phase: string;
  Start: Date | string;
  End?: Date | string;
  Duration?: string;
}

export interface PdtToolTip extends VegaToolTip {
  Phase: string;
  DeliveredAt: Date | string;
  TimeToPDT?: string;
}

export type TimelineColors = {
  created: string;
  pickerAccepted: string;
  pickingComplete: string;
  riderClaimed: string;
  enRoute: string;
  arrived: string;
  delivered: string;
  label: string;
  pdtEarly: string;
  pdtLate: string;
  pdt: string;
  delivery_window: string;
};

type EventIcons = {
  pickerAccepted: string;
  pickingComplete: string;
  riderClaimed: string;
  enRoute: string;
  delivered: string;
};
