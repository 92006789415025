import { Box, Card, Flex, Select, Text } from "@mantine/core";
import { useMemo, useState } from "react";
import { Collapsible } from "../../components/collapsible";
import { ShowError } from "../../components/errors";
import { JsonTree } from "../../components/json";
import { Loading } from "../../components/loading";
import { useApiResource } from "../../config";
import { tripDeliveries } from "../../methods";
import {
  Event,
  HubState,
  merge,
  OrderIDSet,
  scored,
  stackingRetraceResponse,
} from "../../types";
import { HubAreaView } from "./hub-area-map";
import {
  TripProposalSummary,
  TripSummary,
  useOrderLabelsSelection,
} from "./hub-trips-and-proposals-overview";
import { getDeliveryTripId, getDeliveryTrips } from "./hubs";
import { getStartTimestamp, TripCard } from "./trip-card";

export const StackingRetraceView = (props: {
  state: HubState;
  withheldFromPicking: OrderIDSet;
}) => {
  const [openedTripId, setOpenedTripId] = useState<string>();
  const [stackingAlgorithm, setStackingAlgorithm] = useState<string | null>(
    props.state.SelectedStackingDetails.NonFrozenZoneAlgo,
  );
  const [stackingFrozenAlgorithm, setFrozenStackingAlgorithm] = useState<
    string | null
  >(props.state.SelectedStackingDetails.FrozenZoneAlgo);

  let { data, error, loading, refetch } = useApiResource(
    "/stacking/retrace",
    stackingRetraceResponse,
    {
      params: {
        id: props.state.ID,
        selected_algorithm: stackingAlgorithm,
        selected_algorithm_frozen_zone: stackingFrozenAlgorithm,
      },
    },
  );

  const [orderLabels] = useOrderLabelsSelection();

  const openedTrip = useMemo(() => {
    if (props.state) {
      return getDeliveryTrips(props.state).find(
        (trip) => getDeliveryTripId(trip) === openedTripId,
      );
    }
  }, [openedTripId, props.state]);

  if (!data && loading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  type mappedEvent =
    | { kind: "inputScored"; value: scored }
    | { kind: "merged"; value: merge }
    | { kind: "other"; event: any };
  const events: mappedEvent[] =
    data?.trace.map((event) => {
      switch (event.kind) {
        case "inputScored":
          return { ...event, value: scored.parse(event.value) } as mappedEvent;
        case "merged":
          return { ...event, value: merge.parse(event.value) } as mappedEvent;
        default:
          return { kind: "other", event } as mappedEvent;
      }
    }) || [];
  const currentTrips: Record<string, string> = {};
  const mergees: Record<string, string[]> = {};
  const trips: Record<string, any> = {};
  const deliveryIds = (event: Event): string[] =>
    event.value.Trip.Deliveries.map((d: any) => d.ID);
  const tripId = (event: Event): string => deliveryIds(event).join(", ");
  for (const event of events) {
    switch (event.kind) {
      case "merged":
        mergees[tripId(event)] = [
          ...new Set(deliveryIds(event).map((id) => currentTrips[id])),
        ];
      case "inputScored":
        for (const delivery of event.value.Trip.Deliveries) {
          currentTrips[delivery.ID] = tripId(event);
        }
        trips[tripId(event)] = event;
    }
  }

  function ResultingTripCard(props: {
    state: HubState;
    tripId: string;
    oldTripId: string;
  }) {
    const event = trips[props.tripId];
    const tripMergees = mergees[props.tripId];
    return (
      <Card>
        <Flex key={props.tripId}>
          {event ? (
            <>
              <Box mr={10}>
                <TripSummary
                  trip={{
                    Locked: false,
                    FirstDelivery: event.value.Trip.Deliveries[0],
                    IndirectDeliveries: event.value.Trip.Deliveries.slice(1),
                  }}
                  orderLabels={orderLabels}
                  hubSlug={props.state.Hub}
                  time={props.state.Timestamp}
                  setOpenedTripId={setOpenedTripId}
                  startTimestamp={getStartTimestamp()}
                />
              </Box>
              <JsonTree data={event} />
            </>
          ) : (
            `No event found for ${props.tripId}`
          )}
        </Flex>
        {tripMergees?.length &&
          props.tripId != null &&
          props.tripId != props.oldTripId && (
            <Collapsible label="mergees">
              {tripMergees.map((tripId) => (
                <ResultingTripCard
                  key={tripId}
                  state={props.state}
                  tripId={tripId}
                  oldTripId={props.tripId}
                />
              ))}
            </Collapsible>
          )}
      </Card>
    );
  }

  return (
    <Flex
      mih={80}
      gap="xs"
      direction={"column"}
      align="flex-start"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[0],
      })}
    >
      <Text my={20}>
        This allows us to investigate detailed decisions that are made when
        re-stacking the selected hub state using the currently deployed code and
        feature flags.x
      </Text>

      {data && (
        <Flex direction={"row"} gap="xl">
          <Select
            label="Stacking Algorithm Used (Non-Frozen):"
            placeholder={data.selected_algorithm}
            data={data.available_stacking_algorithms}
            value={stackingAlgorithm}
            onChange={setStackingAlgorithm}
            searchable
          />
          <Select
            label="Frozen Zone Stacking Algo:"
            placeholder={data.frozen_selected_algorithm}
            data={data.available_stacking_algorithms}
            value={stackingFrozenAlgorithm}
            onChange={setFrozenStackingAlgorithm}
            searchable
          />
        </Flex>
      )}

      {data && (
        <HubAreaView
          state={{ ...data.original_state, TripProposals: data.restacked }}
          withheldFromPicking={props.withheldFromPicking}
        />
      )}
      {data?.restacked.map((tripProposal) => {
        const deliveryIds = tripDeliveries(tripProposal).map((d) => d.ID);
        const tripId = deliveryIds.join(", ");
        return (
          <ResultingTripCard
            state={props.state}
            tripId={tripId}
            oldTripId={""}
          />
        );
      })}
      <TripCard
        trip={openedTrip}
        state={props.state}
        withheldFromPicking={props.withheldFromPicking}
        setOpenedTripId={setOpenedTripId}
      />
    </Flex>
  );
};
