import { Box, Group, Popover, Stack, Text } from "@mantine/core";
import { IconHelp } from "@tabler/icons";
import dateFormat from "dateformat";
import { useMemo, useState } from "react";
import * as vega from "vega";
import { VegaData, VegaView } from "../../components/vega";
import { tripDeliveries } from "../../methods";
import { HubState, Order, TimestampEvents, Window } from "../../types";
import { shiftDate } from "../../utils";
import { isWithinWindow } from "./rider-shifts";

export const HubMetrics = (props: { state: HubState }) => {
  const [openedTooltip, setOpenedTooltip] = useState("");
  const orders = extractAllOrders(props.state);
  const intervals = extractIntervals(props.state.Timestamp, 15, 120);

  const window: Window = {
    start: shiftDate(props.state.Timestamp, -2 * 60 * 60 * 1000),
    end: props.state.Timestamp,
  };
  const transitionSpecs = makeTransitionSpecs(
    orders,
    window,
    props.state.Timestamp,
  );
  const transitionRange = [{ time: window.start }, { time: window.end }];
  const eventSpecs = makeEventRateSpecs(orders, intervals);
  const eventRateRange = [
    ...Object.values(eventSpecs)
      .flat()
      .map((spec) => spec.value),
    10,
  ].map((value) => {
    return { value: value };
  });

  const makeTransitionChartSpec = (label: string, dataNames: string[]) => {
    return transitionChartSpec(
      label,
      dataNames,
      transitionSpecs,
      transitionRange,
    );
  };

  return (
    <Stack spacing={0} justify={"end"}>
      {Object.values(chartData).map((data, i) => {
        const isRateChart = data.type === "phaseRate";
        const chartSpec = isRateChart
          ? makePhaseRateChartSpec(
              data.label,
              eventSpecs[data.dataNames[0]],
              eventRateRange,
            )
          : makeTransitionChartSpec(data.label, data.dataNames);
        const height = isRateChart ? 75 : 100;
        return (
          <Group key={i} spacing={0}>
            <Popover
              position="left"
              width={"md"}
              opened={data.label === openedTooltip}
              shadow={"xl"}
            >
              <Popover.Target>
                <IconHelp
                  size={15}
                  onClick={() => {
                    if (data.label === openedTooltip) {
                      setOpenedTooltip("");
                    } else {
                      setOpenedTooltip(data.label);
                    }
                  }}
                />
              </Popover.Target>
              <Popover.Dropdown sx={{ borderWidth: 2, borderColor: "black" }}>
                <Text>{data.description}</Text>
              </Popover.Dropdown>
            </Popover>
            <VegaChart chartSpec={chartSpec} height={height} />
          </Group>
        );
      })}
    </Stack>
  );
};

const extractAllOrders = (state: HubState) => {
  return [
    ...state.Trips.flatMap((t) => tripDeliveries(t)),
    ...state.TripProposals.flatMap((t) => tripDeliveries(t)),
    ...state.ExternalDeliveryOrders,
    ...state.InStorePaymentOrders,
    ...state.ClickAndCollectOrders,
    ...state.OutsourcedTrips.flatMap((t) => tripDeliveries(t)),
  ];
};

const extractIntervals = (
  time: Date,
  intervalDurationMin: number,
  totalDurationMin: number,
): Window[] => {
  const intervalAmount = totalDurationMin / intervalDurationMin;
  const intervalDurationMs = intervalDurationMin * 60 * 1000;
  const totalDurationMs = totalDurationMin * 60 * 1000;

  return [...Array(intervalAmount).keys()].map((i) => {
    return {
      start: shiftDate(time, -totalDurationMs + i * intervalDurationMs),
      end: shiftDate(time, -totalDurationMs + (i + 1) * intervalDurationMs),
    };
  });
};

type ChartData = {
  label: string;
  type: "phaseRate" | "transition";
  dataNames: string[];
  description: string;
};

const chartData: Record<string, ChartData> = {
  orderRate: {
    label: "Order Rate",
    type: "phaseRate",
    dataNames: ["orderRate"],
    description: "The number of orders that were created in 15 minute interval",
  },
  pickingBacklog: {
    label: "Picking Backlog",
    type: "transition",
    dataNames: ["pickingBacklog"],
    description: "The number of orders waiting to be picked",
  },
  inPicking: {
    label: "Order in Picking",
    type: "transition",
    dataNames: ["inPicking"],
    description: "The number of orders currently being picked",
  },
  packingRate: {
    label: "Packing Rate",
    type: "phaseRate",
    dataNames: ["packingRate"],
    description:
      "The number of orders that finished picking in 15 minute interval",
  },
  ridingBacklog: {
    label: "Riding Backlog",
    type: "transition",
    dataNames: ["ridingBacklog", "completeRidingBacklog"],
    description:
      "The immediate backlog with all orders waiting to be claimed by a rider is shown in blue and the complete backlog with all orders not yet claimed is shown in gray",
  },
  claimRate: {
    label: "Claim Rate",
    type: "phaseRate",
    dataNames: ["claimRate"],
    description:
      "The number of orders that were claimed in 15 minute interval.",
  },
  enRoute: {
    label: "Orders en Route",
    type: "transition",
    dataNames: ["enRoute"],
    description: "The number of orders that are currently being delivered",
  },
  deliveryRate: {
    label: "Delivery Rate",
    type: "phaseRate",
    dataNames: ["deliveryRate"],
    description: "The number of orders that were delivered",
  },
};

const VegaChart = (props: { chartSpec: vega.Spec; height?: number }) => {
  const spec = useMemo(() => {
    return vega.parse(props.chartSpec);
  }, [props.chartSpec]);

  return (
    <Box w={"xl"} h={props.height}>
      <VegaView data={spec} />
    </Box>
  );
};

const makePhaseRateChartSpec = (
  title: string,
  data: BarSpec[],
  range?: RateRange,
): vega.Spec => {
  return {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: title + " per 15 min over the last 2 hours",
    width: 170,
    height: 45,
    data: [
      {
        name: "data",
        values: data,
      },
      {
        name: "range",
        values: range,
      },
    ],
    scales: [
      {
        name: "xscale",
        type: "band",
        domain: { data: "data", field: "interval" },
        range: "width",
        padding: 0.05,
        round: true,
      },
      {
        name: "yscale",
        domain: { data: range ? "range" : "data", field: "value" },
        nice: true,
        range: "height",
      },
    ],

    axes: [
      {
        orient: "bottom",
        scale: "xscale",
        labelOverlap: "parity",
        tickBand: "extent",
        labelOffset: 10,
        labels: title === "Delivery Rate" ? true : false,
        ticks: title === "Delivery Rate" ? true : false,
      },
      {
        orient: "left",
        scale: "yscale",
        labelOverlap: "parity",
        title: title,
      },
    ],

    marks: [
      {
        type: "rect",
        from: { data: "data" },
        encode: {
          enter: {
            x: { scale: "xscale", field: "interval" },
            width: { scale: "xscale", band: 1 },
            y: { scale: "yscale", field: "value" },
            y2: { scale: "yscale", value: 0 },
            tooltip: {
              field: "tooltip",
            },
          },
          update: {
            fill: { value: "steelblue" },
          },
          hover: {
            fill: { value: "lightblue" },
          },
        },
      },
    ],
  };
};

type TimeRange = {
  time: Date;
}[];

type RateRange = {
  value: number;
}[];

type BarSpec = {
  interval: string;
  value: number;
  tooltip: {
    Value: number;
    Interval: string;
  };
};

const eventRates: Record<string, string> = {
  created: "orderRate",
  pickingComplete: "packingRate",
  riderClaimed: "claimRate",
  delivered: "deliveryRate",
};

const makeEventRateSpecs = (
  orders: Order[],
  intervals: Window[],
): Record<string, BarSpec[]> => {
  const vegaData: Record<string, BarSpec[]> = {
    orderRate: [],
    packingRate: [],
    claimRate: [],
    deliveryRate: [],
    pickingBacklog: [],
  };
  intervals.forEach((interval) => {
    const intervalString =
      dateFormat(interval.start, "HH:MM:ss") +
      " - " +
      dateFormat(interval.end, "HH:MM:ss");

    Object.entries(eventRates).forEach(([event, rateLabel]) => {
      const rate = calculateRate(
        orders,
        interval,
        event as keyof TimestampEvents,
      );
      vegaData[rateLabel].push({
        interval: dateFormat(interval.end, "HH:MM"),
        value: rate,
        tooltip: {
          Value: rate,
          Interval: intervalString,
        },
      });
    });
  });
  return vegaData;
};

const calculateRate = (
  orders: Order[],
  interval: Window,
  event: keyof TimestampEvents,
): number => {
  return orders.filter(
    (o) =>
      o.Timestamps.Events[event].Time &&
      isWithinWindow(o.Timestamps.Events[event].Time!, interval),
  ).length;
};

type TransitionEvent = {
  value: number;
  time: Date;
};

type PhaseBoundaryEvents = {
  start: keyof TimestampEvents;
  end: keyof TimestampEvents;
};

const phases: Record<string, PhaseBoundaryEvents> = {
  pickingBacklog: {
    start: "created",
    end: "pickerAccepted",
  },
  inPicking: {
    start: "pickerAccepted",
    end: "pickingComplete",
  },
  ridingBacklog: {
    start: "pickingComplete",
    end: "riderClaimed",
  },
  enRoute: {
    start: "riderClaimed",
    end: "arrived",
  },
  completeRidingBacklog: {
    start: "created",
    end: "riderClaimed",
  },
};

const makeTransitionSpecs = (
  orders: Order[],
  window: Window,
  time: Date,
): VegaData[] => {
  const vegaData: VegaData[] = [];

  Object.keys(phases).map((key) => {
    const relevantEvents: TransitionEvent[] = [];
    let inPhaseCount = countOrderInPhase(orders, phases[key]);
    orders.forEach((o) => {
      Object.entries(o.Timestamps.Events).forEach(([label, timestamp]) => {
        if (timestamp.Time && isWithinWindow(timestamp.Time, window)) {
          if (label === phases[key].start) {
            relevantEvents.push({
              value: -1,
              time: timestamp.Time,
            });
          }
          if (label === phases[key].end) {
            relevantEvents.push({
              value: 1,
              time: timestamp.Time,
            });
          }
        }
      });
    });

    const sortedEvents = [...relevantEvents].sort(
      (a, b) => b.time.getTime() - a.time.getTime(),
    );

    vegaData.push({
      name: key,
      values: [
        { value: inPhaseCount, time: time, label: key },
        ...sortedEvents.map((event) => {
          inPhaseCount += event.value;
          return {
            value: inPhaseCount,
            time: event.time,
            label: key,
            formattedTime: dateFormat(event.time, "HH:MM:ss"),
          };
        }),
      ],
      format: {
        type: "json",
      },
    });
  });
  return vegaData;
};

const countOrderInPhase = (
  orders: Order[],
  boundaryEvents: PhaseBoundaryEvents,
): number => {
  return orders.reduce((acc, curr) => {
    const timestamps = curr.Timestamps.Events;
    if (
      timestamps[boundaryEvents.start].Time &&
      !timestamps[boundaryEvents.end].Time
    ) {
      acc += 1;
    }
    return acc;
  }, 0);
};

const transitionChartSpec = (
  title: string,
  dataNames: string[],
  data: VegaData[],
  range?: TimeRange,
): vega.Spec => {
  const marks: vega.Mark[] = [];
  const ydomain: { data: string; field: string }[] = [];
  dataNames.forEach((dataName, i) => {
    marks.push({
      type: "line",
      from: { data: dataName },
      encode: {
        enter: {
          x: { scale: "xscale", field: "time" },
          y: { scale: "yscale", field: "value" },
          stroke: { value: i === 0 ? "steelblue" : "lightgray" },
          strokeWidth: { value: 2 },
        },
        update: {
          interpolate: { value: "step" },
          strokeOpacity: { value: 1 },
          tooltip: {
            signal:
              "{label: datum.label, value: datum.value, time: datum.formattedTime}",
          },
        },
      },
    });
    ydomain.push({
      data: dataName,
      field: "value",
    });
  });

  return {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: title,
    width: 170,
    height: 80,
    data: [...Object.values(data)],
    scales: [
      {
        name: "xscale",
        type: "time",
        domain: { data: dataNames[0], field: "time" },
        range: "width",
        padding: 0.05,
        round: true,
      },
      {
        name: "yscale",
        type: "linear",
        domain: {
          fields: [{ data: dataNames[0], field: "value" }, ...ydomain],
        },
        nice: true,
        range: "height",
      },
    ],
    axes: [
      {
        orient: "left",
        scale: "yscale",
        labelOverlap: "parity",
        title: title,
        tickMinStep: 1,
      },
      {
        orient: "bottom",
        scale: "xscale",
        labels: false,
        ticks: false,
      },
    ],
    marks: marks,
  };
};
