import { Center, Group, Space, Stack, Table, Text } from "@mantine/core";
import { useExtendedOrderInfo } from "../api";
import { useIdOrNumberContext } from "../app";
import { Link, routes } from "../nav";
import {
  Order,
  PickingTask,
  scheduledTripProposal,
  TimestampEvents,
} from "../types";
import {
  durationInMs,
  getLatestEvent,
  getLatestEventIndex,
  getStateColor,
  orderedTimestampEvents,
} from "../utils";
import { OrderIndicators } from "../views/hubs/indicators";
import { CopyButton } from "./copy";
import { Time } from "./date";
import { Pill } from "./pill";

export const OrderCard = (props: {
  order: Order;
  isWithheld: boolean;
  isFilled: boolean;
  time: Date;
  pickingTask?: PickingTask;
  pickerQueuePosition?: number;
  scheduledTrip?: scheduledTripProposal;
  isStuckInPicking?: boolean;
}) => {
  const timestamps = props.order.Timestamps.Events;
  const latestEvent = getLatestEvent(timestamps);
  const stateColor = getStateColor(timestamps, props.isWithheld);
  return (
    <>
      <HoverCardHeader order={props.order} />
      <Center p="xs">
        <OrderIndicators
          planned={props.order.DeliveryOptionType == "PLANNED"}
          taskIndex={props.pickerQueuePosition}
          pickingTask={props.pickingTask}
          temperatureCategories={props.order.Items.TemperatureCategories}
        >
          <Pill
            sx={{
              ...(props.isFilled
                ? { backgroundColor: stateColor, color: "white" }
                : {
                    color: stateColor,
                    borderWidth: "1px",
                    borderColor: stateColor,
                  }),
            }}
          >
            {props.isWithheld ? (
              <Stack spacing={0}>
                <Text>Withheld from Picking</Text>
                <Text>{props.pickingTask?.Group}</Text>
              </Stack>
            ) : props.isStuckInPicking ? (
              "Stuck in Picking"
            ) : (
              latestEvent
            )}
            {props.isFilled &&
              latestEvent === "Delivered" &&
              " and rider en route"}
          </Pill>
        </OrderIndicators>
      </Center>
      <TimestampTable
        timestamps={timestamps}
        order={props.order}
        scheduledTrip={props.scheduledTrip}
        time={props.time}
      />
    </>
  );
};

function OrderIdentifierLink(identifier: string, identifierText: string) {
  return (
    <Center>
      {identifierText}
      <Space w={8}></Space>
      <Link
        data-dd-action-name="Order link"
        route={routes.orders.sub.byId}
        params={{ idOrNumber: identifier }}
      >
        {identifier.length > 16
          ? identifier.substring(0, 16) + "..."
          : identifier}
      </Link>
      <CopyButton value={identifier} />
    </Center>
  );
}

const HoverCardHeader = (props: { order: Order }) => {
  const orderIdentifierDisplayContext = useIdOrNumberContext();
  let orderNumber = useExtendedOrderInfo(
    props.order.ID,
  ).data?.OrderNumber.toUpperCase();
  if (orderNumber == undefined) {
    // This should not happen but if it does the orderId link will still work and display (yay undefined | string)
    orderNumber = "";
  }
  return (
    <Center style={{ display: "block" }}>
      {OrderIdentifierLink(props.order.ID, "Order ID: ")}
      {OrderIdentifierLink(orderNumber, "Order Nr: ")}
    </Center>
  );
};

export const TimestampTable = (props: {
  order: Order;
  timestamps: TimestampEvents;
  scheduledTrip?: scheduledTripProposal;
  time: Date;
}) => {
  const PDT = new Date(
    props.timestamps.created.Time!.getTime() + props.order.PDT * 60000,
  );
  const ETAAtCheckout = new Date(
    props.timestamps.created.Time!.getTime() +
      props.order.ETAAtCheckout * 60000,
  );
  return (
    <>
      <Table verticalSpacing={4}>
        <tbody>
          {Object.entries(orderedTimestampEvents)
            .slice(0, getLatestEventIndex(props.timestamps))
            .map((entry) => entry as [keyof TimestampEvents, string])
            .map(([key, label]) => {
              const timestamp = props.timestamps[key];

              let duration = Math.round(
                durationInMs(timestamp.Time!, props.time) / 60 / 1000,
              );
              return (
                <tr key={key}>
                  <td>{label}</td>
                  <td>
                    <Group>
                      {timestamp.Time && <Time date={timestamp.Time} />}
                      {timestamp.Inferred && " (inferred)"}
                    </Group>
                  </td>
                  <td>{duration && `- ${duration} min`}</td>
                </tr>
              );
            })}
          {props.scheduledTrip && (
            <tr>
              <td>Expected Claim At</td>
              <td>
                <Group>
                  <Time date={props.scheduledTrip.expected_claim} />
                </Group>
              </td>
              <td>
                {`+ ${Math.round(
                  durationInMs(props.time, props.scheduledTrip.expected_claim) /
                    60 /
                    1000,
                )} min`}
              </td>
            </tr>
          )}
        </tbody>
        <Space h={20}></Space>
        <tbody>
          {props.order.delivery_window ? (
            <>
              <tr key={"start"}>
                <td>{"Delivery Window Start"}</td>
                <td>
                  <Time date={props.order.delivery_window.start} />
                </td>
                <td></td>
              </tr>
              <tr key={"end"}>
                <td>{"Delivery Window End"}</td>
                <td>
                  <Time date={props.order.delivery_window.end} />
                </td>
                <td></td>
              </tr>
            </>
          ) : (
            <>
              <tr key={"eta"}>
                <td>{"ETA at Checkout"}</td>
                <td>
                  <Time date={ETAAtCheckout} />
                </td>
                <td></td>
              </tr>
              <tr key={"pdt"}>
                <td>{"PDT"}</td>
                <td>
                  <Time date={PDT} />
                </td>
                <td></td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </>
  );
};
