import {
  Card,
  Code,
  Container,
  Divider,
  Grid,
  List,
  Loader,
  Space,
  Table,
  Text,
} from "@mantine/core";

import { useElementSize } from "@mantine/hooks";
import { useEffect, useRef } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Collapsible } from "../components/collapsible";
import { Content } from "../components/content";
import { DateTime } from "../components/date";
import { GeoJsonMap } from "../components/map";
import { parse, VegaView } from "../components/vega";
import { collection, point } from "../geojson";
import {
  LogEntry,
  StepFrontendData,
  TripClaimedEvent,
} from "../simulator-types";
import { FlinkPink } from "../themes";
import { OrderID, TimestampEvents } from "../types";

export const Simulator = () => {
  const didUnmount = useRef(false);

  const { sendMessage, readyState, lastJsonMessage } = useWebSocket(
    "ws://localhost:12345/simulator",
    {
      shouldReconnect: () => !didUnmount.current,
      reconnectInterval: 1000,
      reconnectAttempts: 1000000,
    },
  );

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage("data");
    }
  }, [readyState]);

  return (
    <Content title="Simulator Frontend">
      <Space h="lg" />
      {readyState !== ReadyState.OPEN || !lastJsonMessage ? (
        <Container>
          <Card>
            <Text size="lg">
              {readyState !== ReadyState.OPEN
                ? "Connecting to simulator server"
                : "Waiting for simulation data"}{" "}
              <Loader variant="dots" size="sm" />
            </Text>
          </Card>
          <Space h="lg" />
          <Card>
            <Text>
              This is the frontend for the dispatching simulator. It connects to
              a simulator server running on your machine and displays details
              about the simulation result. To start the server, run:
              <Code>
                simulator step-viewer -i {"<"}out.avro{">"}
              </Code>
            </Text>
          </Card>
        </Container>
      ) : (
        <SimulationDataViewer
          data={lastJsonMessage as any as StepFrontendData}
        />
      )}
    </Content>
  );
};

type NumberField<
  T extends Record<string, any>,
  K extends string,
> = T[K] extends number ? K : never;

type EventType =
  | "PickerShiftStartedEvent"
  | "PickerShiftEndedEvent"
  | "RiderShiftStartedEvent"
  | "RiderShiftEndedEvent"
  | "OrderCreatedEvent"
  | "PickingStartedEvent"
  | "OrderPackedEvent"
  | "TripClaimedEvent"
  | "EnRouteToCustomerEvent"
  | "ArrivedAtCustomerEvent"
  | "OrderDeliveredEvent"
  | "RiderGoingBackToHubEvent"
  | "RiderBackAtHubEvent";

const eventTypeCounterField: Record<
  EventType,
  (event: any) => [NumberField<Counters, keyof Counters>, number][]
> = {
  PickerShiftStartedEvent: () => [["pickers", 1]],
  PickerShiftEndedEvent: () => [["pickers", -1]],
  RiderShiftStartedEvent: () => [["riders", 1]],
  RiderShiftEndedEvent: () => [["riders", -1]],
  OrderCreatedEvent: () => [["queueingForPicker", 1]],
  PickingStartedEvent: () => [
    ["queueingForPicker", -1],
    ["picking", 1],
  ],
  OrderPackedEvent: () => [
    ["picking", -1],
    ["queuingForRider", 1],
  ],
  TripClaimedEvent: (event: TripClaimedEvent) => {
    return [
      ["queuingForRider", -event.order_ids.length],
      ["ridingOrders", event.order_ids.length],
      ["ridingTrips", 1],
    ];
  },
  EnRouteToCustomerEvent: () => [],
  ArrivedAtCustomerEvent: () => [],
  OrderDeliveredEvent: () => [
    ["ridingOrders", -1],
    ["deliveredOrders", 1],
  ],
  RiderGoingBackToHubEvent: () => [
    ["ridingTrips", -1],
    ["deliveredTrips", 1],
  ],
  RiderBackAtHubEvent: () => [],
};

const displayedCounters: (keyof Counters)[] = [
  "queueingForPicker",
  "picking",
  "queuingForRider",
  "ridingOrders",
  "ridingTrips",
];

const smoothTime = 10 * 60 * 1000; // milliseconds

const labelCounters = (
  counters: [Date, Counters][],
): { date: Date; value: number; metric: string }[] => {
  let currentIndex = 0;
  let [start, counter] = counters[currentIndex];
  const smoothedCounters: [Date, Counters][] = [];

  // skip initial zeros and create a single one before the first non-zero
  while (allZero(counter, displayedCounters)) {
    [start, counter] = counters[++currentIndex];
  }
  start = new Date(start.getTime() - smoothTime);
  if (currentIndex > 0) {
    smoothedCounters.push([start, counters[currentIndex - 1][1]]);
  }

  while (true) {
    if (currentIndex === counters.length) {
      break;
    } else if (counters[currentIndex][0].getTime() <= start.getTime()) {
      counter = counters[currentIndex][1];
      currentIndex++;
    } else {
      start = new Date(start.getTime() + smoothTime);
      smoothedCounters.push([start, counter]);
    }
  }
  smoothedCounters.push([new Date(start.getTime() + smoothTime), counter]);

  return smoothedCounters.flatMap(([date, counters]) => {
    return displayedCounters.map((metric) => ({
      date,
      value: counters[metric],
      metric,
    }));
  });
};

const SimulationDataViewer = (props: { data: StepFrontendData }) => {
  const { ref, width } = useElementSize();

  const eventsCount = countEvents(props.data.simulation.log.entries);
  const finalEventsCount = eventsCount[eventsCount.length - 1][1];

  const stackSizes = props.data.simulation.log.entries
    .flatMap((entry) => {
      return entry.type === "TripClaimedEvent"
        ? [entry.event.order_ids.length]
        : [];
    })
    .sort();

  const basicInfo = {
    File: props.data.input,
    "Simulation Clock": (
      <DateTime date={props.data.simulation.internal_state.now} />
    ),
    "Orders (Delivered/Undelivered)": `${
      props.data.simulation.orders.length
    } (${finalEventsCount.deliveredOrders}/${
      finalEventsCount.queueingForPicker +
      finalEventsCount.picking +
      finalEventsCount.queuingForRider +
      finalEventsCount.ridingOrders
    })`,
    "Stack Sizes": `mean: ${
      stackSizes.reduce((a, b) => a + b, 0) / stackSizes.length
    }, median: ${stackSizes[Math.floor(stackSizes.length / 2)]}, max: ${
      stackSizes[stackSizes.length - 1]
    }`,
  };
  const stacks = extractStacks(props.data.simulation.log.entries);
  const undeliveredOrders = stacks.flatMap((stack) =>
    stack.orders.filter((order) => !order.events.delivered.Time),
  );

  const standardCellHeight = 400;
  const standardCellWidth = Math.max(width * 0.9, width - 50) / 2;

  return (
    <>
      <Grid ref={ref}>
        <Grid.Col span={12}>
          <Table highlightOnHover fontSize="md">
            <tbody>
              {Object.entries(basicInfo).map(([label, value]) => (
                <tr key={label}>
                  <td>{label}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Divider my="md" variant="dotted" />
        </Grid.Col>
        <Grid.Col span={6}>
          <VegaView
            data={countsChart(
              eventsCount,
              standardCellWidth,
              standardCellHeight,
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <GeoJsonMap
            data={collection(
              point(props.data.simulation.hub_coordinates, {
                description: "hub",
                color: FlinkPink,
              }),
              ...props.data.simulation.orders.map((order) =>
                point(order.delivery_coordinates, { description: order.id }),
              ),
            )}
            height={standardCellHeight}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <VegaView
            data={stacksChart(
              stacks,
              props.data.simulation.internal_state.now,
              standardCellWidth * 2,
              standardCellHeight,
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Collapsible
            label={`Undelivered Orders (${undeliveredOrders.length})`}
          >
            <List>
              {undeliveredOrders.map((order) => (
                <List.Item key={order.id}>{order.id}</List.Item>
              ))}
            </List>
          </Collapsible>
        </Grid.Col>
      </Grid>
    </>
  );
};

const countsChart = (
  eventsCount: [Date, Counters][],
  width: number,
  height: number,
) =>
  parse({
    $schema: "https://vega.github.io/schema/vega/v5.json",
    width,
    height,
    autosize: "fit",
    padding: 5,

    signals: [
      {
        name: "clear",
        value: true,
        on: [
          {
            events: "mouseup[!event.item]",
            update: "true",
            force: true,
          },
        ],
      },
      {
        name: "shift",
        value: false,
        on: [
          {
            events: "@legendSymbol:click, @legendLabel:click",
            update: "event.shiftKey",
            force: true,
          },
        ],
      },
      {
        name: "clicked",
        value: null,
        on: [
          {
            events: "@legendSymbol:click, @legendLabel:click",
            update: "{value: datum.value}",
            force: true,
          },
        ],
      },
    ],

    data: [
      {
        name: "table",
        values: labelCounters(eventsCount),
      },
      {
        name: "selected",
        on: [
          { trigger: "clear", remove: true },
          { trigger: "!shift", remove: true },
          { trigger: "!shift && clicked", insert: "clicked" },
          { trigger: "shift && clicked", toggle: "clicked" },
        ],
      },
    ],

    scales: [
      {
        name: "x",
        type: "time",
        range: "width",
        domain: { data: "table", field: "date" },
        nice: "minute",
      },
      {
        name: "y",
        type: "linear",
        range: "height",
        nice: true,
        zero: true,
        domain: { data: "table", field: "value" },
      },
      {
        name: "color",
        type: "ordinal",
        range: "category",
        domain: { data: "table", field: "metric" },
      },
    ],

    axes: [
      { orient: "bottom", scale: "x" },
      { orient: "left", scale: "y" },
    ],

    marks: [
      {
        type: "group",
        from: {
          facet: {
            name: "series",
            data: "table",
            groupby: "metric",
          },
        },
        marks: [
          {
            type: "line",
            from: { data: "series" },
            encode: {
              update: {
                x: { scale: "x", field: "date" },
                y: { scale: "y", field: "value" },
                interpolate: { value: "step-after" },
                strokeWidth: { value: 2 },
                opacity: [
                  {
                    test: "!length(data('selected')) || indata('selected', 'value', datum.metric)",
                    value: 0.7,
                  },
                  { value: 0.15 },
                ],
                stroke: { scale: "color", field: "metric" },
                fill: { value: "transparent" },
                tooltip: { signal: "datum" },
              },
            },
          },
        ],
      },
    ],

    legends: [
      {
        stroke: "color",
        title: "Origin",
        encode: {
          symbols: {
            name: "legendSymbol",
            interactive: true,
            update: {
              fill: { value: "transparent" },
              strokeWidth: { value: 2 },
              opacity: [
                {
                  test: "!length(data('selected')) || indata('selected', 'value', datum.value)",
                  value: 0.7,
                },
                { value: 0.15 },
              ],
              size: { value: 64 },
            },
          },
          labels: {
            name: "legendLabel",
            interactive: true,
            update: {
              opacity: [
                {
                  test: "!length(data('selected')) || indata('selected', 'value', datum.value)",
                  value: 1,
                },
                { value: 0.25 },
              ],
            },
          },
        },
      },
    ],
  });

const zeroCounter = {
  pickers: 0,
  riders: 0,
  queueingForPicker: 0,
  picking: 0,
  queuingForRider: 0,
  ridingOrders: 0,
  ridingTrips: 0,
  deliveredOrders: 0,
  deliveredTrips: 0,
};

type Counters = typeof zeroCounter;

const allZero = (counters: Counters, fields: (keyof Counters)[]): boolean => {
  return fields.every((field) => counters[field] === 0);
};

const countEvents = (entries: LogEntry[]): [Date, Counters][] => {
  if (entries.length === 0) {
    return [];
  }

  let previous: Counters = zeroCounter;

  return [
    [new Date(new Date(entries[0].at).getTime() - 1 * 60 * 1000), previous],
    ...entries.flatMap<[Date, Counters]>((entry) => {
      const date = new Date(entry.at);
      previous = Object.assign({}, previous);
      if (entry.type in eventTypeCounterField) {
        const fields = eventTypeCounterField[entry.type as EventType];
        for (const [key, inc] of fields(entry.event)) {
          previous[key] += inc;
        }
        return [[date, previous]];
      }
      return [];
    }),
  ];
};

type SimulatedOrder = {
  id: OrderID;
  events: TimestampEvents;
};

type SimulatedStack = {
  orders: SimulatedOrder[];
};

const timestampFieldsByUpdate: Partial<
  Record<EventType, keyof TimestampEvents>
> = {
  OrderCreatedEvent: "created",
  PickingStartedEvent: "pickerAccepted",
  OrderPackedEvent: "pickingComplete",
  TripClaimedEvent: "riderClaimed",
  EnRouteToCustomerEvent: "enRoute",
  ArrivedAtCustomerEvent: "arrived",
  OrderDeliveredEvent: "delivered",
};

const extractStacks = (entries: LogEntry[]): SimulatedStack[] => {
  const orders = new Map<OrderID, SimulatedOrder>();
  entries.forEach((entry) => {
    const field = timestampFieldsByUpdate[entry.type as EventType];
    if (!field) {
      return;
    }

    const orderIDs: OrderID[] =
      typeof entry.event.order_id === "string"
        ? [entry.event.order_id]
        : Array.isArray(entry.event.order_ids)
        ? entry.event.order_ids
        : [];

    orderIDs.forEach((id) => {
      let order = orders.get(id);
      if (!order) {
        order = {
          id,
          events: {
            created: { Time: undefined, Inferred: false },
            pickerAccepted: { Time: undefined, Inferred: false },
            pickingComplete: { Time: undefined, Inferred: false },
            riderClaimed: { Time: undefined, Inferred: false },
            enRoute: { Time: undefined, Inferred: false },
            arrived: { Time: undefined, Inferred: false },
            delivered: { Time: undefined, Inferred: false },
          },
        };
        orders.set(id, order);
      }

      order!.events[field].Time = entry.at;
    });
  });

  return entries
    .filter((entry) => entry.type === "TripClaimedEvent")
    .map<SimulatedStack>((entry) => {
      return {
        orders: (entry.event as TripClaimedEvent).order_ids.map(
          (id) => orders.get(id)!,
        ),
      };
    });
};

const phaseEvents: Record<
  string,
  {
    name: string;
    start: keyof TimestampEvents;
    end: keyof TimestampEvents;
    color: string;
  }
> = {
  queuingForPicker: {
    name: "Queuing for picker",
    start: "created",
    end: "pickerAccepted",
    color: "red",
  },
  picking: {
    name: "Picking",
    start: "pickerAccepted",
    end: "pickingComplete",
    color: "green",
  },
  queuingForRider: {
    name: "Queuing for rider",
    start: "pickingComplete",
    end: "riderClaimed",
    color: "yellow",
  },
  preparing: {
    name: "Preparing",
    start: "riderClaimed",
    end: "enRoute",
    color: "blue",
  },
  riding: {
    name: "Riding",
    start: "enRoute",
    end: "arrived",
    color: "orange",
  },
  delivering: {
    name: "Delivering",
    start: "arrived",
    end: "delivered",
    color: "purple",
  },
};

const stacksChart = (
  stacks: SimulatedStack[],
  simulationEnd: Date,
  width: number,
  height: number,
) => {
  let orderIndex = 0;
  const phases = stacks.flatMap((stack) => {
    return stack.orders.flatMap((order) => {
      const i = orderIndex++;
      return Object.entries(phaseEvents).flatMap(
        ([phase, { start: startEvent, end: endEvent, color }]) => {
          const start = order.events[startEvent].Time;
          if (!start) {
            return [];
          }

          const end = order.events[endEvent].Time || simulationEnd;

          return { orderId: order.id, i, phase, start, end, color };
        },
      );
    });
  });

  return parse({
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: "A basic line chart example.",
    width: width,
    height: height,
    autosize: "fit",
    padding: 5,
    data: [
      {
        name: "table",
        values: phases,
        transform: [
          { type: "formula", expr: "toDate(datum.start)", as: "start" },
          { type: "formula", expr: "toDate(datum.end)", as: "end" },
        ],
      },
    ],
    scales: [
      {
        name: "x",
        type: "time",
        range: "width",
        domain: {
          data: "table",
          fields: ["start", "end"],
        },
        nice: "minute",
      },
      {
        name: "y",
        type: "band",
        range: [0, { signal: "height" }],
        domain: { data: "table", field: "i" },
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "x",
      },
    ],
    marks: [
      {
        type: "group",
        from: {
          facet: {
            name: "series",
            data: "table",
            groupby: "orderId",
          },
        },
        marks: [
          {
            type: "rect",
            from: {
              data: "series",
            },
            encode: {
              update: {
                x: {
                  scale: "x",
                  field: "start",
                },
                x2: {
                  scale: "x",
                  field: "end",
                },
                y: { scale: "y", field: "i", offset: -1 },
                fill: {
                  field: "color",
                },
                tooltip: {
                  signal: "datum",
                },
                height: { value: 2 },
              },
            },
          },
        ],
      },
    ],
  });
};
