import {
  Button,
  Grid,
  Modal,
  SegmentedControl,
  Slider,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { IconDownload, IconMoonStars, IconSun } from "@tabler/icons";
import fileDownload from "js-file-download";
import strftime from "strftime";
import { downloadConfigurationCSV } from "./api";
import { useSettingsContext } from "./app";

export const Settings = (props: {
  opened: boolean;
  onModalClosed: () => void;
}) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const settings = useSettingsContext();

  return (
    <Modal opened={props.opened} onClose={props.onModalClosed} title="Settings">
      <Grid columns={3} align={"center"}>
        <Grid.Col span={1}>
          <SegmentedControl
            value={colorScheme}
            onChange={() => toggleColorScheme()}
            data={[
              {
                label: <IconMoonStars size={16} style={{ margin: "auto" }} />,
                value: "dark",
              },
              {
                label: <IconSun size={16} style={{ margin: "auto" }} />,
                value: "light",
              },
            ]}
            fullWidth
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Text>Toggle Color Scheme</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <SegmentedControl
            value={settings.idOrNumber.selected}
            onChange={settings.idOrNumber.toggle}
            data={[
              { label: " ID ", value: "order_id" },
              { label: "Number", value: "order_number" },
            ]}
            fullWidth
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Text>Toggle Global Order Identifier</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Slider
            mt={25}
            min={1}
            max={15}
            step={1}
            value={settings.navigation.distance}
            onChange={settings.navigation.setDistance}
            labelAlwaysOn
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Text>Set Time Increment of Hub State Navigation</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Button
            variant={"outline"}
            onClick={downloadConfig}
            sx={{ width: "100%" }}
          >
            <IconDownload />
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text>Download configurations for every Hub</Text>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

const downloadConfig = (evt: any): void => {
  downloadConfigurationCSV()
    .then((r) => {
      fileDownload(
        r.data,
        strftime("%Y-%m-%d-%H%M%S", new Date()) + "-hub-config.csv",
      );
    })
    .catch((r) => console.error(r));
};
