import { Text, TextInput, useMantineTheme } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconFilter } from "@tabler/icons";
import {
  applyValue,
  DataItemProps,
  DataType,
  JsonViewer,
  JsonViewerKeyRenderer,
} from "@textea/json-viewer";
import { ReactElement, ReactNode, useMemo } from "react";
import { derivePath } from "../obj-path";

export type Renderer<T> = {
  is: (value: T, path: string[]) => boolean;
  Component: (value: T, path: string[]) => ReactElement;
};

export const JsonTree = (props: {
  data: any;
  initialDepth?: number;
  keyRenderers?: Renderer<any>[];
  valueRenderers?: Renderer<any>[];
  allowSearch?: boolean;
}) => {
  const theme = useMantineTheme();
  const [query, setQuery] = useDebouncedState<string | undefined>(
    undefined,
    300,
  );

  const [result, searchMessage] = useMemo<[SearchResult, ReactNode]>(() => {
    const defaultDepth = props.initialDepth ?? 2;

    if (!props.allowSearch || !query) {
      return [
        { data: props.data, depth: defaultDepth, matches: 0 },
        <SearchMessage>Type to search.</SearchMessage>,
      ];
    }

    const result = search(props.data, query);

    if (result.matches > 20 && result.depth > 4) {
      result.depth = defaultDepth;
      return [
        result,
        <SearchMessage error>
          Too many matches ({result.matches}). Please refine your search to
          expand the tree with the results.
        </SearchMessage>,
      ];
    }

    return [
      result,
      <SearchMessage>Found {result.matches} match(es).</SearchMessage>,
    ];
  }, [props.data, query]);

  const keyRenderer = useMemo(() => {
    if (query) {
      return treeKeyRenderer([
        highlightKeyRenderer(query, (value) => matchQuery(value, query)),
        ...(props.keyRenderers ?? []),
      ]);
    } else if (props.keyRenderers?.length) {
      return treeKeyRenderer(props.keyRenderers);
    } else {
      return undefined;
    }
  }, [props.keyRenderers, query]);

  const valueRenderers = useMemo(
    () => props.valueRenderers?.map((r) => treeValueType(r)),
    [props.valueRenderers],
  );

  return (
    <>
      {props.allowSearch && (
        <TextInput
          icon={<IconFilter />}
          placeholder="Search"
          onChange={(e) =>
            setQuery(
              e.currentTarget.value.trim().toLowerCase().replace(/"/g, ""),
            )
          }
          description={searchMessage}
          inputWrapperOrder={["input", "description"]}
        />
      )}
      <JsonViewer
        key={`${props.allowSearch}--${query}`} // force rerender on search
        style={{ fontSize: theme.fontSizes.sm }}
        value={result.data}
        enableClipboard
        theme={theme.colorScheme}
        defaultInspectDepth={result.depth}
        collapseStringsAfterLength={40}
        maxDisplayLength={128}
        quotesOnKeys={false}
        indentWidth={4}
        keyRenderer={keyRenderer}
        valueTypes={valueRenderers}
        rootName={false}
      />
    </>
  );
};

export const matchQuery = (value: any, query: string): boolean => {
  if (value === undefined || value === null) {
    return query === "undefined" || query === "null";
  }

  if (value instanceof Date) {
    return value.toISOString().toLowerCase().includes(query);
  }

  return value.toString().toLowerCase().includes(query);
};

export type SearchResult = {
  data: any;
  depth: number;
  matches: number;
};

export const SearchMessage = (props: {
  children: ReactNode;
  error?: boolean;
}) => {
  return (
    <Text fz="xs" tt="uppercase" c={props.error ? "red" : undefined}>
      {props.children}
    </Text>
  );
};

type PartialResult = {
  depth: number;
  matches: number;
};

export const search = (data: any, query: string): SearchResult => {
  if (!query) {
    return data;
  } else if (data === null || data === undefined) {
    return { data: data, depth: 1, matches: 0 };
  } else if (typeof data !== "object") {
    return matchQuery(data, query)
      ? { data: data, depth: 1, matches: 1 }
      : { data: undefined, depth: 1, matches: 0 };
  }

  const recursiveSearchAndFilter = (
    value: Record<string, any>,
  ): [Record<string, any> | undefined, PartialResult] => {
    if (value === undefined || value === null) {
      return matchQuery(value, query)
        ? [value, { depth: 1, matches: 1 }]
        : [undefined, { depth: 1, matches: 0 }];
    }

    let depth = 0;
    let matches = 0;
    const anyDirectValueMatches = Object.values(value).some((v) => {
      if (isNotObjectArray(v) && v.some((c) => matchQuery(c, query))) {
        depth = Math.max(depth, 2);
        return true;
      } else if (typeof v !== "object" && matchQuery(v, query)) {
        depth = Math.max(depth, 1);
        return true;
      } else {
        return false;
      }
    });

    // we keep going to find nested matches and the depth of the match
    const remainingChildren = Object.entries(value).flatMap<[string, any][]>(
      ([key, v]) => {
        if (v === undefined || v === null) {
          if (query === "undefined" || query === "null") {
            depth = Math.max(depth, 1);
            return [[key, v]];
          }
        } else if (Array.isArray(v)) {
          let anyMatch = false;
          const remaining = v.map((v) => {
            const [result, { depth: d, matches: m }] =
              recursiveSearchAndFilter(v);
            if (m > 0) {
              depth = Math.max(depth, d + 2);
              matches += m;
              anyMatch = true;
              return result;
            } else {
              // we return undefined to keep the array length, but indicate that there was no match
              return undefined;
            }
          });
          return anyMatch ? [[key, remaining]] : [];
        } else if (typeof v === "object") {
          const [result, { depth: d, matches: m }] =
            recursiveSearchAndFilter(v);
          if (m > 0) {
            depth = Math.max(depth, d + 1);
            matches += m;
            return [[key, result]];
          }
        }
        return [];
      },
    );

    if (anyDirectValueMatches) {
      return [value, { depth, matches: matches + 1 }];
    }

    return [Object.fromEntries(remainingChildren), { depth, matches }];
  };

  const [filtered, partial] = recursiveSearchAndFilter(data);
  return partial.matches > 0
    ? { data: filtered, ...partial }
    : { data: undefined, depth: 1, matches: 0 };
};

const isObject = (v: any): v is Record<string, any> =>
  typeof v === "object" && !Array.isArray(v) && !(v instanceof Date);

const isNonEmptyArray = (v: any): v is any[] => Array.isArray(v) && !!v.length;

const isNotObjectArray = (v: any): v is any[] =>
  isNonEmptyArray(v) && !isObject(v);

export const keyRenderer = <T,>(
  render: (key: string) => ReactElement,
  selector: (root: T) => any,
  ...others: ((root: T) => any)[]
): Renderer<any> => {
  const expectedPaths = [selector, ...others].map(derivePath);

  return {
    is: (value, path) => matchPath(path, expectedPaths),
    Component: (entry) => render(entry.path[entry.path.length - 1]),
  };
};

export const valueRenderer = <T, V>(
  render: (value: V) => ReactElement,
  selector: (root: T) => V,
  ...others: ((root: T) => V)[]
): Renderer<any> => {
  const expectedPaths = [selector, ...others].map(derivePath);

  return {
    is: (value, path) => matchPath(path, expectedPaths),
    Component: (entry) => render(entry.value as V),
  };
};

const matchPath = (path: string[], expectedPaths: string[][]): boolean =>
  expectedPaths.some(
    (expectedPath) =>
      path.length === expectedPath.length &&
      path.every((p, i) => p.toString().match(expectedPath[i])),
  );

const treeKeyRenderer: (
  renderers: Renderer<any>[],
  highlight?: Renderer<any>[],
) => JsonViewerKeyRenderer = (renderers) => {
  const find = (value: any, path: string[]): Renderer<any> | undefined =>
    renderers.find((r) => r.is(value, path));

  const ActualKeyRenderer: JsonViewerKeyRenderer = ({ value, path }) => {
    const normalizedPath = path.map((p) => p.toString());
    const Component = useMemo(() => find(value, normalizedPath), [])!.Component;
    return <Component value={value} path={path} />;
  };

  ActualKeyRenderer.when = (props): boolean => {
    return renderers.some((r) =>
      r.is(
        props.value,
        props.path.map((p) => p.toString()),
      ),
    );
  };

  return ActualKeyRenderer;
};

const treeValueType = (renderer: Renderer<any>): DataType<unknown> => ({
  is: (value, path) =>
    renderer.is(
      value,
      path.map((p) => p.toString()),
    ),
  Component: (props: DataItemProps<any>) => (
    <renderer.Component
      value={props.value}
      path={props.path.map((p) => p.toString())}
    />
  ),
});

const highlightKeyRenderer = <T,>(query: string, on: (value: T) => boolean) => {
  return {
    is: (value: T, path: string[]) => {
      return on(value);
    },
    Component: (entry: DataItemProps<T>) => {
      return <mark>{entry.path[entry.path.length - 1]}</mark>;
    },
  };
};
