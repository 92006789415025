import { useViewportSize } from "@mantine/hooks";
import {
  MantineTheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/styles";
import chroma from "chroma-js";
import dateFormat from "dateformat";
import { useMemo, useState } from "react";
import { Item, ScenegraphEvent } from "vega";
import { HubStateEstimates, useHubState } from "../../api";
import { ShowError } from "../../components/errors";
import { Loading } from "../../components/loading";
import {
  createEvent,
  EventSpec,
  parse,
  PhaseSpec,
  VegaData,
  VegaSpec,
  VegaToolTip,
  VegaView,
} from "../../components/vega";
import { useHashParam } from "../../hooks";
import { tripDeliveries } from "../../methods";
import { getRiderPseudonym } from "../../rider-pseudonyms";
import { SelectedOrderYellow } from "../../themes";
import {
  HubState,
  IndirectDelivery,
  OrderIDSet,
  Rider,
  RiderID,
  TimestampEvents,
  Trip,
  TripID,
} from "../../types";
import {
  durationInMs,
  formatDurationInMinutes,
  formatMilliSeconds,
  formatMinutes,
  isEmptyDate,
  shiftDate,
} from "../../utils";
import {
  formatEstimateInMs,
  getRidingLegEstimate,
  totalRidingDurationEstimateInMs,
  totalTripDurationEstimateInMs,
} from "../time-estimation";
import { groupBy } from "./hub-area-map";
import { getDeliveryTripId, HashParams } from "./hubs";
import { isWithinWindow, sortRiderShifts } from "./rider-shifts";
import { TripCard } from "./trip-card";

export const RiderTimeline = (props: {
  state: HubState;
  withheldFromPicking: OrderIDSet;
  width?: number;
  estimates?: HubStateEstimates;
}) => {
  const [openedTripId, setOpenedTripId] = useState<string>();
  const theme = useMantineTheme();
  const timelineColors = activityTimelineColors(theme);
  const width = useViewportSize().width;
  const timestamp = shiftDate(props.state.Timestamp, -1.7 * 60 * 60 * 1000);
  const earlierHubState = useHubState({
    hub: props.state.Hub,
    timestamp,
  });

  const onPhaseClick = (event: ScenegraphEvent, item?: Item | null) => {
    setOpenedTripId(item?.datum.tripId);
  };

  const tripsOfPrevState: Trip[] = earlierHubState.data
    ? earlierHubState.data.State.Trips
    : [];
  const ridersOfPrevState = earlierHubState.data
    ? earlierHubState.data.State.Riders
    : {};

  const allTrips: Record<RiderID, Trip[]> = groupBy(
    combineTrips(props.state.Trips, tripsOfPrevState),
    (t) => t.RiderID,
  );
  const combinedRiders = { ...ridersOfPrevState, ...props.state.Riders };
  const sortedRiders = sortRiderShifts(
    Object.values(combinedRiders),
    props.state.Timestamp,
  );

  const spec = parse(
    riderTimelineSpec(
      sortedRiders,
      allTrips,
      props.state.Timestamp,
      timelineColors,
      width - 220 || 1000,
    ),
  );

  const openedTrip = useMemo(() => {
    return Object.values(allTrips)
      .flat()
      .find((trip) => trip.ID === openedTripId);
  }, [openedTripId, props.state]);

  if (!earlierHubState.data && earlierHubState.loading) {
    return <Loading />;
  }

  if (earlierHubState.error) {
    return <ShowError error={earlierHubState.error} />;
  }
  return (
    <>
      <VegaView data={spec} onClick={onPhaseClick} />
      <Pattern colors={timelineColors} />
      <TripCard
        trip={openedTrip}
        state={props.state}
        withheldFromPicking={props.withheldFromPicking}
        setOpenedTripId={setOpenedTripId}
        estimates={props.estimates}
      />
    </>
  );
};

// Combine two collections of trips while removing unfinished past trips and removing duplicates
const combineTrips = (trips: Trip[], pastTrips: Trip[]): Trip[] => {
  const finishednewTrips = pastTrips.filter(
    (trip) => !isEmptyDate(trip.CompletedAt),
  );
  const uniqueTripIds: TripID[] = [];
  const combinedTrips = [...trips, ...finishednewTrips];

  return combinedTrips.reduce((acc, trip) => {
    if (!uniqueTripIds.includes(trip.ID)) {
      uniqueTripIds.push(trip.ID);
      acc.push(trip);
    }
    return acc;
  }, [] as Trip[]);
};

const riderTimelineSpec = (
  riders: Rider[],
  trips: Record<RiderID, Trip[]>,
  currentTime: Date,
  colors: ActivityTimelineColors,
  width: number,
): VegaSpec => {
  const timelineStart = shiftDate(currentTime, -3 * 60 * 60 * 1000);
  const [phases, riderSpecs] = extractVegaPhases(
    riders,
    trips,
    timelineStart,
    currentTime,
    colors,
  );

  const events: EventSpec[] = [
    createEvent(currentTime, "Now"),
    createEvent(timelineStart, "3h ago"),
  ];

  const range = [
    ...phases.flatMap((phase) => [phase.start, phase.end]),
    ...events.map((event) => event.time),
  ].map((date) => ({ date }));

  const vegaData = Object.entries({
    phases: phases,
    events: events,
    riders: riderSpecs,
    range: range,
  } as Record<string, any[]>).map(
    ([label, data]): VegaData => ({
      name: label,
      format: { type: "json" },
      values: data,
    }),
  );

  return {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: "Timeline of the riders of a hub",
    autosize: "fit",
    width: width,
    height: riderSpecs.length * 40 + 60,
    padding: 0,
    data: vegaData,
    scales: [
      {
        name: "yscale",
        type: "band",
        range: [
          0,
          {
            signal: "height",
          },
        ],
        domain: {
          data: "phases",
          field: "line",
        },
      },
      {
        name: "xscale",
        type: "time",
        range: "width",
        round: true,
        domain: {
          data: "range",
          field: "date",
        },
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "xscale",
        format: "%H:%M:%S",
        labelAngle: -45,
        labelAlign: "right",
        labelColor: colors.label,
        grid: true,
        tickCount: 35,
        labelOverlap: "parity",
        gridColor: colors.grid,
      },
    ],
    marks: [
      {
        type: "text",
        from: {
          data: "riders",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              offset: -20,
            },
            y: {
              scale: "yscale",
              field: "line",
              offset: 12,
            },
            text: {
              field: "pseudonym",
            },
            align: {
              value: "right",
            },
            tooltip: {
              field: "tooltip",
            },
            fill: {
              value: colors.label,
            },
            fontSize: {
              value: 14,
            },
          },
        },
      },
      {
        type: "text",
        from: {
          data: "riders",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              offset: -20,
            },
            y: {
              scale: "yscale",
              field: "line",
              offset: 24,
            },
            text: {
              field: "line",
            },
            align: {
              value: "right",
            },
            tooltip: {
              field: "tooltip",
            },
            fill: {
              value: colors.idLabel,
            },
            fontSize: {
              value: 8,
            },
          },
        },
      },
      {
        type: "rect",
        from: {
          data: "phases",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "start",
            },
            x2: {
              scale: "xscale",
              field: "end",
            },
            yc: {
              scale: "yscale",
              field: "line",
              offset: 15,
            },
            height: {
              field: "height",
            },
            tooltip: {
              field: "tooltip",
            },
            fill: {
              field: "color",
            },
            fillOpacity: [
              {
                test: "datum.color === 'opaque'",
                value: 0,
              },
              {
                value: 1,
              },
            ],
            stroke: {
              field: "stroke",
            },
            strokeWidth: {
              field: "strokeWidth",
            },
            cornerRadiusTopLeft: {
              field: "cornerRadiusLeft",
            },
            cornerRadiusBottomLeft: {
              field: "cornerRadiusLeft",
            },
            cornerRadiusTopRight: {
              field: "cornerRadiusRight",
            },
            cornerRadiusBottomRight: {
              field: "cornerRadiusRight",
            },
          },
        },
      },
      {
        type: "rule",
        from: {
          data: "events",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "time",
            },
            y: {
              value: -6,
            },
            y2: {
              field: {
                group: "height",
              },
            },
            stroke: {
              value: "gray",
            },
          },
        },
      },
      {
        type: "text",
        from: {
          data: "events",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "time",
              offset: 2,
            },
            y: {
              value: -10,
            },
            angle: {
              value: -70,
            },
            fill: {
              value: colors.label,
            },
            text: {
              field: "name",
            },
            fontSize: {
              value: 10,
            },
          },
        },
      },
    ],
  };
};

type PhaseCorner = "right" | "left" | "none" | "both";

type PhaseSpecProps = {
  riderID: RiderID;
  phaseLabel: string;
  phaseStart: Date;
  phaseEnd: Date;
  color: string;
  isPhaseComplete: boolean;
  windowStart: Date;
  tooltip: {};
  phaseCorners: PhaseCorner;
  stroke?: string;
  strokeWidth?: number;
  tripId: string;
  phaseStartTooltip?: Date;
};

type RiderSpec = {
  line: string;
  pseudonym: string;
  tooltip: {
    ID: string;
    Status: string;
    LastStatusChange: string;
  };
};

interface TripPhaseSpec extends PhaseSpec {
  pseudonym: string;
  cornerRadiusLeft: number;
  cornerRadiusRight: number;
  stroke?: string;
  strokeWidth?: number;
  tripId: string;
}

type ActivityTimelineColors = {
  idLabel: string;
  selected: string;
  label: string;
  shift: string;
  grid: string;
  setup: string;
  estimate: string;
  tripInProgress: string;
  handover: string;
  trip: (ratio: number) => string;
};

const activityTimelineColors = (
  theme: MantineTheme,
): ActivityTimelineColors => {
  const { colorScheme } = useMantineColorScheme();
  const [coloring] = useHashParam(HashParams.FancyColors);
  const isFancy = coloring === "fancy";
  const dark = colorScheme === "dark";
  const scale = chroma
    .scale([theme.colors.red[4], "#ffffff", theme.colors.green[4]])
    .mode("lrgb")
    .domain([0.4, 1]);

  return {
    idLabel: theme.colors.gray[dark ? 6 : 4],
    label: theme.colors.gray[dark ? 2 : 8],
    shift: theme.colors.gray[dark ? 7 : 3],
    selected: SelectedOrderYellow,
    setup: theme.colors.orange[4],
    grid: theme.colors.gray[dark ? 8 : 2],
    estimate: "url(#diagonal-stripes)",
    tripInProgress: theme.colors.grape[4],
    trip: (ratio: number) => {
      if (!isFancy) return theme.colors.green[4];
      return scale(ratio).hex();
    },
    handover: theme.colors.blue[4],
  };
};

const getPhaseSpec = (props: PhaseSpecProps): TripPhaseSpec => {
  return {
    line: props.riderID,
    pseudonym: getRiderPseudonym(props.riderID),
    start: getLaterDate(props.phaseStart, props.windowStart),
    end: getLaterDate(props.phaseEnd, props.windowStart),
    color: props.color,
    height: props.stroke ? 13 : 14,
    tooltip: {
      Phase: props.phaseLabel,
      State: props.isPhaseComplete ? "Complete" : "In Progress",
      Start: dateFormat(
        props.phaseStartTooltip ? props.phaseStartTooltip : props.phaseStart,
        "HH:MM:ss",
      ),
      ...(props.isPhaseComplete && {
        End: dateFormat(props.phaseEnd, "HH:MM:ss"),
      }),
      ...props.tooltip,
    },
    cornerRadiusLeft:
      props.phaseCorners === "left" || props.phaseCorners === "both" ? 4 : 0,
    cornerRadiusRight:
      props.phaseCorners === "right" || props.phaseCorners === "both" ? 4 : 0,
    stroke: props.stroke,
    strokeWidth: props.strokeWidth,
    tripId: props.tripId,
  };
};

const extractVegaPhases = (
  riders: Rider[],
  allTrips: Record<RiderID, Trip[]>,
  timelineStart: Date,
  currentTime: Date,
  colors: ActivityTimelineColors,
): [PhaseSpec[], RiderSpec[]] => {
  const [selectedOrderID] = useHashParam(HashParams.SelectedOrder);
  const riderSpecs = getRiderSpecs(
    Object.values(riders),
    Object.keys(allTrips),
  );
  const phases: PhaseSpec[] = [];
  let specOfSelectedOrder: TripPhaseSpec | undefined;
  let endOfLoadingPhase: Date | undefined;

  riders.forEach((rider) => {
    const trips: Trip[] = allTrips[rider.ID];
    if (!trips) return;
    const sortedTrips = trips.sort(
      (a, b) => a.StartedAt.getTime() - b.StartedAt.getTime(),
    );
    phases.push(
      ...getRiderShiftsSpecs(rider!, trips, currentTime, timelineStart, colors),
    );

    sortedTrips.map((trip, tripIndex) => {
      const orders = tripDeliveries(trip);
      const tripTooltip = getTripTooltip(trip, currentTime);
      const isTripComplete = !isEmptyDate(trip.CompletedAt);
      orders.map((order, i) => {
        const timestamps = order.Timestamps.Events;
        const phaseStart =
          i === 0
            ? timestamps.riderClaimed.Time
            : orders[i - 1].Timestamps.Events.delivered.Time;

        if (!!timestamps.enRoute.Time && phaseStart) {
          const spec = getOrderDeliveryPhaseSpec(
            currentTime,
            phaseStart,
            trip,
            timestamps,
            i,
            tripTooltip,
            timelineStart,
            colors,
          );

          phases.push(spec);

          if (selectedOrderID === order.ID) {
            specOfSelectedOrder = { ...spec, ...specOfSelectedOrder };
          }
        }

        if (timestamps.arrived.Time) {
          let phaseStart = timestamps.arrived.Time;
          const phaseEnd = timestamps.delivered.Time
            ? timestamps.delivered.Time
            : currentTime;
          const duration =
            phaseEnd.getTime() - timestamps.arrived.Time.getTime();
          const isPhaseComplete = !!timestamps.delivered.Time;

          if (duration < 30 * 1000) {
            phaseStart = shiftDate(phaseStart, -30 * 1000);
          }
          const phaseTooltip = getPhaseTooltip(
            isPhaseComplete,
            phaseStart,
            phaseEnd,
            currentTime,
          );

          const phaseSpecProps: PhaseSpecProps = {
            riderID: rider.ID,
            phaseLabel: "Handing over Order " + (i + 1),
            phaseStart: phaseStart,
            phaseEnd: phaseEnd,
            color: colors.handover,
            isPhaseComplete: isPhaseComplete,
            windowStart: timelineStart,
            tooltip: {
              ...phaseTooltip,
              ...tripTooltip,
            },
            phaseCorners: "none",
            ...(isTripComplete && {
              stroke: colors.trip(getTripEstimateAccuracy(trip)),
              strokeWidth: 1,
            }),
            tripId: getDeliveryTripId(trip),
            phaseStartTooltip: timestamps.arrived.Time,
          };

          phases.push(getPhaseSpec(phaseSpecProps));
          if (!timestamps.delivered.Time) {
            phases.push(getEstimateSpec(phaseSpecProps, colors, trip));
          }
        }
        if (timestamps.delivered.Time) {
          if (i === orders.length - 1) {
            const phaseEnd = isEmptyDate(trip.CompletedAt)
              ? getLaterDate(
                  shiftDate(
                    trip.StartedAt,
                    totalTripDurationEstimateInMs(trip),
                  ),
                  currentTime,
                )
              : ensureGapToNextTrip(sortedTrips, tripIndex, trip.CompletedAt);

            const legTooltip = getLegTooltip(
              isTripComplete,
              timestamps.delivered.Time,
              phaseEnd,
              order.RoutedToHub!,
              currentTime,
            );

            phases.push(
              getPhaseSpec({
                riderID: rider.ID,
                phaseLabel: "Returning to Hub",
                phaseStart: timestamps.delivered.Time,
                phaseEnd: phaseEnd,
                color: isTripComplete
                  ? colors.trip(getTripEstimateAccuracy(trip))
                  : colors.estimate,
                isPhaseComplete: !isEmptyDate(trip.CompletedAt),
                windowStart: timelineStart,
                tooltip: { ...legTooltip, ...tripTooltip },
                phaseCorners: "right",
                tripId: getDeliveryTripId(trip),
              }),
            );
          }
        }
      });
      const timestamps = orders[0].Timestamps.Events;
      const isPhaseComplete = !!timestamps.enRoute.Time;
      const phaseStart = timestamps.riderClaimed.Time;
      const phaseEnd = timestamps.enRoute.Time
        ? timestamps.enRoute.Time
        : currentTime;

      if (
        durationInMs(phaseStart!, phaseEnd) > 30 * 1000 ||
        !timestamps.enRoute.Time
      ) {
        const phaseToolTip = getPhaseTooltip(
          isPhaseComplete,
          phaseStart!,
          phaseEnd,
          currentTime,
        );

        const phaseSpecProps: PhaseSpecProps = {
          riderID: rider.ID,
          phaseLabel: "Loading Bike",
          phaseStart: timestamps.riderClaimed.Time!,
          phaseEnd: phaseEnd,
          color: colors.setup,
          isPhaseComplete: isPhaseComplete,
          windowStart: timelineStart,
          tooltip: { ...phaseToolTip, ...tripTooltip },
          phaseCorners: "left",
          tripId: getDeliveryTripId(trip),
        };
        if (orders[0].ID === selectedOrderID) {
          endOfLoadingPhase = phaseEnd;
        }

        phases.push(getPhaseSpec(phaseSpecProps));

        if (!isPhaseComplete) {
          phases.push(getEstimateSpec(phaseSpecProps, colors, trip));
        }
      }
    });
  });
  if (specOfSelectedOrder) {
    phases.push(
      selectedOrderHighlightSpec(specOfSelectedOrder, endOfLoadingPhase),
    );
  }
  return [phases, riderSpecs];
};

const getEstimateSpec = (
  props: PhaseSpecProps,
  colors: ActivityTimelineColors,
  trip: Trip,
): TripPhaseSpec => {
  return getPhaseSpec({
    ...props,
    phaseStart: props.phaseEnd,
    phaseEnd: shiftDate(trip.StartedAt, totalTripDurationEstimateInMs(trip)),
    color: colors.estimate,
    phaseCorners: "right",
  });
};

const getRiderSpecs = (
  riders: Rider[],
  ridersWithTrip: RiderID[],
): RiderSpec[] => {
  const riderSpecs: RiderSpec[] = [];
  riders.forEach((rider) => {
    if (ridersWithTrip.includes(rider.ID)) {
      riderSpecs.push({
        line: rider.ID,
        pseudonym: getRiderPseudonym(rider.ID),
        tooltip: {
          ID: rider.ID,
          Status: rider.Status,
          LastStatusChange: dateFormat(rider.StatusChange, "HH:MM:ss"),
        },
      });
    }
  });
  return riderSpecs;
};

const getRiderShiftsSpecs = (
  rider: Rider,
  trips: Trip[],
  currentTime: Date,
  timelineStart: Date,
  colors: ActivityTimelineColors,
): PhaseSpec[] => {
  const phases: PhaseSpec[] = [];
  rider.PlannedShifts.forEach((shift) => {
    if (
      isWithinWindow(timelineStart, shift) ||
      isWithinWindow(currentTime, shift)
    ) {
      const phaseStart =
        shift.start.getTime() < timelineStart.getTime()
          ? timelineStart
          : shift.start;
      const phaseEnd =
        shift.end.getTime() > currentTime.getTime()
          ? getPhaseEnd(trips, currentTime)
          : shift.end;

      phases.push({
        line: rider.ID,
        start: phaseStart,
        end: phaseEnd,
        color: colors.shift,
        height: 10,
        tooltip: {
          Phase: "Rider shift",
          Pseudonym: getRiderPseudonym(rider.ID),
          Start: dateFormat(shift.start, "HH:MM:ss"),
          End: dateFormat(shift.end, "HH:MM:ss"),
        },
      });
    }
  });
  return phases;
};

const getPhaseEnd = (trips: Trip[], currentTime: Date) => {
  const currentTrip = trips.find((trip) => isEmptyDate(trip.CompletedAt));
  if (!currentTrip) {
    return currentTime;
  }
  const tripEndEstimate = new Date(
    currentTrip.StartedAt.getTime() +
      totalTripDurationEstimateInMs(currentTrip),
  );
  return getLaterDate(tripEndEstimate, currentTime);
};

const getLegTooltip = (
  complete: boolean,
  start: Date,
  end: Date,
  legTimeMin: number,
  currentTime: Date,
) => {
  return {
    ...(!complete && {
      ElapsedLegTime: formatDurationInMinutes(start, currentTime),
    }),
    EstLegTime: formatMinutes(legTimeMin),
    ...(complete && { ActualLegTime: formatDurationInMinutes(end, start) }),
  };
};

const getPhaseTooltip = (
  complete: boolean,
  start: Date,
  end: Date,
  currentTime: Date,
) => {
  return {
    ...(complete
      ? { Duration: formatDurationInMinutes(end, start) }
      : { ElapsedPhaseTime: formatDurationInMinutes(start, currentTime) }),
  };
};

const getTripTooltip = (trip: Trip, currentTime: Date): VegaToolTip => {
  const isComplete = !isEmptyDate(trip.CompletedAt);

  return {
    EstTripRidingTime: formatEstimateInMs(
      totalRidingDurationEstimateInMs(trip),
    ),
    ...(isComplete && {
      ActualTripRidingTime: formatMilliSeconds(getTotalLegTimesInMs(trip)),
    }),
    ...(!isComplete && {
      ElapsedTripTime: formatDurationInMinutes(trip.StartedAt, currentTime),
    }),
    EstTripTime: formatEstimateInMs(totalTripDurationEstimateInMs(trip)),
    ...(isComplete && {
      ActualTripTime: formatDurationInMinutes(trip.CompletedAt, trip.StartedAt),
      "Ratio Est/Act": getTripEstimateAccuracy(trip).toFixed(2),
    }),
  };
};

const getTripEstimateAccuracy = (trip: Trip) => {
  const tripDuration = trip.CompletedAt.getTime() - trip.StartedAt.getTime();
  return totalTripDurationEstimateInMs(trip) / tripDuration;
};

const getLaterDate = (date1: Date, date2: Date): Date => {
  return date1.getTime() > date2.getTime() ? date1 : date2;
};

const getTotalLegTimesInMs = (trip: Trip): number => {
  const orders = tripDeliveries(trip);
  return orders.reduce((legTimes, order, i) => {
    const timestamps = order.Timestamps.Events;
    const legStart =
      i === 0
        ? timestamps.riderClaimed.Time!
        : orders[i - 1].Timestamps.Events.delivered.Time!;
    const legEnd = timestamps.arrived.Time!;
    legTimes += legEnd.getTime() - legStart.getTime();

    if (i === tripDeliveries(trip).length - 1) {
      legTimes +=
        trip.CompletedAt.getTime() - timestamps.delivered.Time!.getTime();
    }
    return legTimes;
  }, 0);
};

// Ensure that the gap between trips is at least 45 seconds, so they can be distinguished visually
const ensureGapToNextTrip = (
  trips: Trip[],
  currentTripIndex: number,
  endTime: Date,
): Date => {
  if (currentTripIndex === trips.length - 1) {
    return endTime;
  }

  const nextTrip = trips[currentTripIndex + 1];
  if (nextTrip.StartedAt.getTime() - endTime.getTime() < 45 * 1000) {
    return shiftDate(nextTrip.StartedAt, -45 * 1000);
  }
  return endTime;
};

const getOrderDeliveryPhaseSpec = (
  currentTime: Date,
  phaseStart: Date,
  trip: Trip,
  timestamps: TimestampEvents,
  orderIndex: number,
  tripTooltip: VegaToolTip,
  timelineStart: Date,
  colors: ActivityTimelineColors,
): TripPhaseSpec => {
  const order = tripDeliveries(trip)[orderIndex];

  const phaseEnd = timestamps.arrived.Time
    ? timestamps.arrived.Time
    : new Date(trip.StartedAt.getTime() + totalTripDurationEstimateInMs(trip));

  const isPhaseComplete = !!timestamps.arrived.Time;
  const color = isPhaseComplete
    ? colors.trip(getTripEstimateAccuracy(trip))
    : colors.estimate;

  const isIndirectDelivery = order.hasOwnProperty("RoutedFromPrevious");

  const legTooltip = getLegTooltip(
    isPhaseComplete,
    phaseStart,
    phaseEnd,
    getRidingLegEstimate(
      isIndirectDelivery
        ? (order as IndirectDelivery).RoutedFromPrevious!
        : order.RoutedFromHub!,
    ),
    currentTime,
  );

  return getPhaseSpec({
    riderID: trip.RiderID,
    phaseLabel: "Delivering Order " + (orderIndex + 1),
    phaseStart: phaseStart,
    phaseEnd: phaseEnd,
    color: color,
    isPhaseComplete: isPhaseComplete,
    windowStart: timelineStart,
    tooltip: { ...legTooltip, ...tripTooltip },
    tripId: getDeliveryTripId(trip),
    phaseCorners: determineCorners(orderIndex, isPhaseComplete),
  });
};

const determineCorners = (orderIndex: number, isPhaseComplete: boolean) => {
  if (orderIndex === 0) {
    if (!isPhaseComplete) {
      return "both";
    }
    return "left";
  }
  if (isPhaseComplete) {
    return "none";
  }
  return "right";
};

const selectedOrderHighlightSpec = (
  specOfSelectedOrder: TripPhaseSpec,
  endOfLoadingPhase: Date | undefined,
): TripPhaseSpec => {
  specOfSelectedOrder.stroke = SelectedOrderYellow;
  specOfSelectedOrder.strokeWidth = 4;
  specOfSelectedOrder.color = "opaque";
  specOfSelectedOrder.start = endOfLoadingPhase || specOfSelectedOrder.start;
  specOfSelectedOrder.cornerRadiusLeft = 0;
  return specOfSelectedOrder;
};

const Pattern = (props: { colors: ActivityTimelineColors }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    style={{ zIndex: -9, position: "fixed" }}
  >
    <defs>
      <pattern
        id="diagonal-stripes"
        patternUnits="userSpaceOnUse"
        width="2.5"
        height="2.5"
        patternTransform="rotate(45)"
        fill="#000000"
      >
        <line
          x1="0"
          y="0"
          x2="0"
          y2="2.5"
          stroke={props.colors.label}
          stroke-width="1"
        />
      </pattern>
    </defs>
    <rect
      width="100%"
      height="100%"
      fill="url(#diagonal-stripes)"
      opacity="1"
    />
  </svg>
);
