import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { Paper, Text, useMantineTheme } from "@mantine/core";
import { Feature, Map as OLMap, MapBrowserEvent, Overlay, View } from "ol";
import { ScaleLine } from "ol/control";
import { easeOut } from "ol/easing";
import { buffer, getArea, getCenter } from "ol/extent";
import { GeoJSON as GeoJSONFormat } from "ol/format";
import Geometry from "ol/geom/Geometry";
import { defaults } from "ol/interaction/defaults";
import { Layer, Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import "ol/ol.css";
import { fromLonLat, getPointResolution } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import Google from "ol/source/Google.js";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { FitOptions } from "ol/View";
import { createPortal } from "react-dom";
import { useConfig } from "../config";
import { GeoJSON } from "../geojson";
import { CityLink, CountryLink, HubLink } from "../nav";

export const GeoJsonMap = (params: {
  data: GeoJSON;
  height?: number | string;
  overlayContent?:
    | ((properties: Record<string, any>) => ReactNode | undefined)
    | null;
  navigation?: boolean;
}) => {
  const apiKey = useConfig().mapKey;
  const elementRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<OLMap | undefined>();
  const [layer, setLayer] = useState<Layer<any> | undefined>();
  const [selectedFeature, setSelectedFeature] = useState<
    Feature<Geometry> | undefined
  >();
  const [overlayContent, setOverlayContent] = useState<ReactNode | undefined>();
  const theme = useMantineTheme();

  const createOverlayContent = useMemo(() => {
    return params.overlayContent === undefined
      ? showDetails
      : params.overlayContent;
  }, [params.overlayContent]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    const map = new OLMap({
      target: element,
      layers: [
        new TileLayer({
          source: new Google({
            key: apiKey,
            mapType: "roadmap",
            styles: [
              {
                featureType: "poi",
                elementType: "all",
                stylers: [{ visibility: "off" }],
              },
            ],
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([13.7, 52.3]),
      }),
      interactions: defaults({
        doubleClickZoom: false,
        pinchZoom: params.navigation,
        mouseWheelZoom: params.navigation,
        dragPan: params.navigation,
      }),
    });
    map.addControl(new ScaleLine({ units: "metric" }));

    const overlayElement = document.createElement("div");
    const overlay = new Overlay({
      element: overlayElement,
      autoPan: true,
    });
    map.addOverlay(overlay);

    map.on("singleclick", (event) => {
      const feature = selectFeature(map, selectedFeature, event);
      setOverlayContent(undefined);

      if (feature && createOverlayContent) {
        const properties = { ...feature.getProperties() };
        delete properties.geometry;

        const content = createOverlayContent(properties);
        if (content) {
          setOverlayContent(
            createPortal(
              <Paper style={{ padding: 6 }}>{content}</Paper>,
              overlayElement,
            ),
          );

          const extent = feature.getGeometry()!.getExtent();
          overlay.setPosition(getCenter(extent));
        }
      }

      setSelectedFeature(feature);
    });

    map.on("dblclick", (event) => {
      const feature = selectFeature(map, selectedFeature, event);
      if (feature) {
        const onDoubleclick = feature.getProperties().onDoubleclick;
        if (onDoubleclick) {
          onDoubleclick();
        }
      }
    });

    setMap(map);

    return () => {
      map.dispose();
    };
  }, [elementRef.current, theme.colorScheme]);

  useEffect(() => {
    if (!map) {
      return;
    }
    // Remove all layers except the tile layer
    // Layers somehow accumulated and removing the current layer in this effect did not suffice to keep the layer count to 2
    map
      .getLayers()
      .getArray()
      .slice(1)
      .forEach((layer) => map.removeLayer(layer));

    setOverlayContent(undefined);

    const options: FitOptions = {};
    if (layer) {
      fadeOut(map, layer, 300);

      options.duration = 300;
      options.easing = easeOut;
    }

    setLayer(addLayer(map, params.data, options));
  }, [map, params.data]);

  return (
    <>
      <div
        ref={elementRef}
        className="map"
        style={{ width: "100%", height: params.height || 400 }}
      ></div>
      {overlayContent}
    </>
  );
};

const addLayer = (
  map: OLMap,
  data: GeoJSON,
  fitOptions?: FitOptions,
): VectorLayer<VectorSource<Feature<Geometry>>> | undefined => {
  if (!data) {
    return undefined;
  }

  const vectorLayer = new VectorLayer({
    source: new VectorSource({
      features: new GeoJSONFormat().readFeatures(data, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
    }),
    style: (feature) => {
      return styles(feature.getProperties())[feature.getGeometry()!.getType()];
    },
    updateWhileAnimating: true,
  });

  const layerSource = vectorLayer.getSource();
  if (!layerSource || layerSource.isEmpty()) {
    return undefined;
  }
  map.addLayer(vectorLayer);

  let extent = layerSource.getExtent();
  if (getArea(extent) === 0) {
    const pointResolutionInMeters = getPointResolution(
      map.getView().getProjection(),
      1,
      getCenter(extent),
      "m",
    );
    // buffer with 20m in each direction
    extent = buffer(extent, 20 / pointResolutionInMeters);
  }
  map.getView().fit(extent, {
    padding: [30, 30, 30, 30],
    ...fitOptions,
  });

  return vectorLayer;
};

const selectFeature = (
  map: OLMap,
  currentSelection: Feature<Geometry> | undefined,
  event: MapBrowserEvent<any>,
): Feature<Geometry> | undefined => {
  const clickedPixel = event.pixel;
  const features = clickedPixel
    ? (map.getFeaturesAtPixel(clickedPixel) as Feature<Geometry>[])
    : [];
  features.forEach((f) => f.setStyle(undefined));
  currentSelection?.setStyle(undefined);

  const featureIndex =
    (features.indexOf(currentSelection!) + 1) % features.length;
  return features[featureIndex];
};

const styles = (properties: any): Record<string, Style> => ({
  LineString: new Style({
    stroke: new Stroke({
      color: properties?.color || "rgba(216,0,95,0.5)",
      width: 2,
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: properties?.color || "rgba(216,0,95,0.3)",
      width: 2,
    }),
    fill: new Fill({
      color: properties?.fill || "rgba(229,80,150,0.15)",
    }),
  }),
  Point: new Style({
    image: new CircleStyle(
      properties?.selected
        ? {
            fill: new Fill({
              color: "#fff100",
            }),
            stroke: new Stroke({
              color: "rgb(0, 0, 0)",
              width: 0.3,
            }),
            radius: 8,
          }
        : {
            fill: new Fill({
              color: properties?.color || "rgba(28, 77, 185)",
            }),
            radius: 5,
          },
    ),
  }),
});

const fadeOut = (map: OLMap, layer: Layer<any>, duration: number) => {
  let start = Date.now();
  const step = () => {
    const opacity = 1 - (Date.now() - start) / duration;
    if (opacity <= 0) {
      map.removeLayer(layer);
    } else {
      layer.setOpacity(opacity);
      setTimeout(step, 4);
    }
  };
  step();
};

const showDetails = (
  properties: Record<string, any>,
): ReactNode | undefined => {
  const parts: ReactNode[] = [];

  if (properties.description) {
    const desc = properties.description as string;
    parts.push(<Text key={desc}>{desc}</Text>);
  }

  if (properties.orderCard && properties.orderId) {
    const orderId = properties.orderId as string;
    parts.push(<div key={orderId}>{properties.orderCard}</div>);
  }

  if (properties.country) {
    const country = properties.country as string;
    parts.push(
      <Text key={country}>
        Country: <CountryLink country={country} />
      </Text>,
    );
  }

  if (properties.city) {
    const city = properties.city as string;
    parts.push(
      <Text key={city}>
        City: <CityLink city={city} />
      </Text>,
    );
  }

  if (properties.hub) {
    const slug = properties.hub as string;
    parts.push(
      <Text key={slug}>
        Hub: <HubLink slug={slug} />
      </Text>,
    );
  }

  return parts.length ? <>{parts}</> : undefined;
};
