import sdk from "@hubportal/sdk";
import React from "react";
import ReactDOMClient from "react-dom/client";
import { AppProps } from "single-spa";
import singleSpaReact from "single-spa-react";
import { App } from "./app";

const Root = (props: AppProps) => {
  return <App />;
};

const module = "@hubportal/dispatching";

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  renderType: "createRoot",
  errorBoundary: (err, errInfo, props) => {
    console.error(err);
    if (!localStorage.getItem(`import-map-override:${module}`)) {
      // Disable error reporting if we override the import map (e.g. for local dev).
      // This prevents us from reporting too many errors to Sentry and causing alerts.
      sdk.reportError(err, module, errInfo);
    }

    return <div>error: {err.message}</div>;
  },
});
