import { Text, Tooltip, useMantineTheme } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useEffect } from "react";
import { ShowError } from "../../components/errors";
import { JsonTree, Renderer } from "../../components/json";
import { Loading } from "../../components/loading";
import { Env, useApiResource, useConfig } from "../../config";
import {
  FieldMetadata,
  FlagsWithMetadata,
  HubFlags,
  HubSlug,
  PathMetadata,
} from "../../types";

const launchdarklyEnv: Record<Env, string> = {
  dev: "test",
  staging: "test",
  prod: "production",
};

const urls: Record<string, (env: Env, meta: FieldMetadata) => string> = {
  launchdarkly: (env, meta) =>
    `https://app.launchdarkly.com/default/${launchdarklyEnv[env]}/features/${meta.Key}/targeting`,
};

const keyRenderer: (metadata: MetaTree) => Renderer<any> = (metadata) => ({
  is: (value, path) => !!metadata.get(path),
  Component: ({ path }) => {
    const env = useConfig().env;
    const theme = useMantineTheme();
    const meta = metadata.get(path)!;
    const key = path.slice(-1);

    const keyColor =
      theme.colorScheme === "dark"
        ? theme.colors.gray[7]
        : theme.colors.gray[5];

    return (
      <Tooltip
        label={meta.Description && `${path.join(".")}: ${meta.Description}`}
        multiline
        mx={150} // avoids having the navbar cover the tooltip
      >
        {meta.Source ? (
          <a
            style={{
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.blue[5]
                  : theme.colors.blue[9],
            }}
            href={urls[meta.Source](env, meta)}
            target="_blank"
          >
            {key}
          </a>
        ) : (
          <span>
            {key}{" "}
            <Text color={keyColor} size="xs" italic span>
              {meta.Key}
            </Text>
          </span>
        )}
      </Tooltip>
    );
  },
});

export const FeatureFlags = (props: { hub: HubSlug }) => {
  const { data, error, loading, refetch } = useApiResource(
    "/feature_flags",
    FlagsWithMetadata.transform<[HubFlags, MetaTree]>((data) => {
      return [data.Flags, MetaTree.fromPathMetadata(data.Meta)];
    }),
    { params: { hub: props.hub } },
  );
  const refreshInterval = useInterval(refetch, 5000);

  useEffect(() => {
    if (data) {
      refreshInterval.start();
    } else {
      refreshInterval.stop();
    }
    return refreshInterval.stop;
  }, [data]);

  if (!data && loading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <JsonTree
      data={data![0]}
      initialDepth={10}
      keyRenderers={[keyRenderer(data![1])]}
    />
  );
};

class MetaTree {
  private metadata?: FieldMetadata;
  private children: Map<string, MetaTree>;

  constructor(metadata?: FieldMetadata) {
    this.metadata = metadata;
    this.children = new Map();
  }

  get(path: (string | number)[]): FieldMetadata | undefined {
    if (path.length === 0) {
      return this.metadata;
    }

    const [key, ...rest] = path;
    if (!this.children.has(key.toString())) {
      return undefined;
    }

    return this.children.get(key.toString())!.get(rest);
  }

  public static fromPathMetadata(metadata: PathMetadata[]): MetaTree {
    const root: MetaTree = new MetaTree();

    metadata.forEach((meta) => {
      const leaf = meta.Path.reduce((parent, key) => {
        const child = parent.children.has(key)
          ? parent.children
          : parent.children.set(key, new MetaTree());
        return child.get(key)!;
      }, root);
      leaf.metadata = meta.Metadata;
    });

    return root;
  }
}
