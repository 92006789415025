import { Center, Sx } from "@mantine/core";
import { ReactNode } from "react";

const pillStyle: Sx = {
  padding: "0px 15px",
  textAlign: "center",
  textDecoration: "none",
  borderRadius: "10px",
  fontWeight: "bold",
  minHeight: "35px",
  width: "fit-content",
  margin: "3px",
  alignItems: "center",
};

export const Pill = (props: { children?: ReactNode; sx: Sx }) => {
  return (
    <Center
      sx={{
        ...pillStyle,
        ...props.sx,
      }}
      fz="xs"
    >
      <>{props.children}</>
    </Center>
  );
};
