import {
  Center,
  Group,
  HoverCard,
  Indicator,
  Slider,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  getHotkeyHandler,
  useDebouncedState,
  useHotkeys,
} from "@mantine/hooks";
import {
  IconAlertCircle,
  IconCheck,
  IconEdit,
  IconGitCommit,
  IconX,
} from "@tabler/icons";
import dateFormat from "dateformat";
import { ReactNode, useEffect, useState } from "react";
import { useHashParam } from "../hooks";
import { uuidRegex } from "../search";
import { HubState, UUID } from "../types";
import { HashParams } from "../views/hubs/hubs";
import { ActionIcon } from "./action-icon";
import { CopyButton } from "./copy";
import { DateTime } from "./date";

export const isOlderThanADay = (date: Date) => {
  return new Date().getTime() - date.getTime() > 24 * 60 * 60 * 1000;
};

export const StateHoverCard = (props: {
  state: HubState;
  width: number;
  exitLiveMode: () => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useMantineTheme();
  return (
    <HoverCard
      shadow="md"
      closeDelay={isEditing ? 2000 : 100}
      onClose={() => setIsEditing(false)}
      data-dd-action-name={"State hovercard"}
    >
      <HoverCard.Target>
        <Center w={props.width}>
          {props.state.Revision}
          <IconGitCommit size={18} />
          <Indicator
            label={
              <Tooltip
                label={"You're looking at a state older than 24 hours"}
                position={"right"}
              >
                <div>
                  <IconAlertCircle size={10} color={"white"} />
                </div>
              </Tooltip>
            }
            size={15}
            zIndex={1000}
            disabled={!isOlderThanADay(props.state.Timestamp)}
            color={theme.colors.yellow[5]}
          >
            <DateTime date={props.state.Timestamp} />
          </Indicator>
        </Center>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <StateCardDropdown
          state={props.state}
          setIsEditing={setIsEditing}
          exitLiveMode={props.exitLiveMode}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const StateCardDropdown = (props: {
  state: HubState;
  setIsEditing: (value: boolean) => void;
  exitLiveMode: () => void;
}) => {
  const [editTime, setEditTime] = useState(false);
  const [editStateId, setEditStateId] = useState(false);

  const handleEditTime = () => {
    setEditTime(true);
    setEditStateId(false);
    props.setIsEditing(true);
  };

  const handleEditId = () => {
    setEditStateId(true);
    setEditTime(false);
    props.setIsEditing(true);
  };

  return (
    <div>
      <Title order={4}>Hub State Details</Title>
      <Text size="sm">
        <Table>
          <tbody>
            {(
              [
                ["Hub", props.state.Hub],
                [
                  "ID",
                  editStateId ? (
                    <IdSelector
                      stateId={props.state.ID}
                      handleStopEditing={() => {
                        setEditStateId(false);
                        props.setIsEditing(false);
                      }}
                      exitLiveMode={props.exitLiveMode}
                    />
                  ) : (
                    <Group
                      sx={{ width: "100%", justifyContent: "space-between" }}
                      data-dd-action-name={"Edit state ID"}
                    >
                      <Group spacing={0}>
                        <Text onClick={() => handleEditId()}>
                          {props.state.ID}
                        </Text>
                        <CopyButton value={props.state.ID} />
                      </Group>
                      <ActionIcon
                        icon={<IconEdit />}
                        onClick={() => handleEditId()}
                      />
                    </Group>
                  ),
                ],
                ["Revision", props.state.Revision],
                [
                  "Time",
                  editTime ? (
                    <TimeSelector
                      time={props.state.Timestamp}
                      handleStopEditing={() => {
                        setEditTime(false);
                        props.setIsEditing(false);
                      }}
                      exitLiveMode={props.exitLiveMode}
                    />
                  ) : (
                    <Group
                      sx={{ width: "100%", justifyContent: "space-between" }}
                      data-dd-action-name={"Edit state time"}
                    >
                      <Group spacing={0}>
                        <Text onClick={() => handleEditTime()}>
                          {dateFormat(props.state.Timestamp, "yyyy-mm-dd")}
                          &nbsp;
                          {dateFormat(props.state.Timestamp, "HH:MM:ss")}
                        </Text>
                        <CopyButton
                          value={props.state.Timestamp.toISOString()}
                        />
                      </Group>
                      <ActionIcon
                        icon={<IconEdit />}
                        onClick={() => handleEditTime()}
                      />
                    </Group>
                  ),
                ],
              ] as [string, ReactNode][]
            ).map(([label, content]) => (
              <tr key={label}>
                <td>{label}</td>
                <td>{content}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Text>
    </div>
  );
};

const TimeSelector = (props: {
  time: Date;
  handleStopEditing: () => void;
  exitLiveMode: () => void;
}) => {
  const [, setTimestamp] = useHashParam(HashParams.Timestamp);
  const [, setStateId] = useHashParam(HashParams.StateId);
  const [selectedTime, setSelectedTime] = useDebouncedState<Date>(
    props.time,
    3000,
  );

  useEffect(() => {
    if (selectedTime.getTime() !== props.time.getTime()) {
      form.setFieldValue(
        "timestamp",
        dateFormat(selectedTime, "yyyy-mm-dd HH:MM:ss"),
      );
    }
  }, [selectedTime]);

  const handleSubmit = () => {
    if (form.validate().hasErrors) {
      return;
    }
    setStateId(undefined);
    setTimestamp(new Date(form.values.timestamp).toISOString());
    props.exitLiveMode();
    props.handleStopEditing();
  };

  useHotkeys([
    ["Enter", () => handleSubmit()],
    ["Escape", props.handleStopEditing],
  ]);

  // Set a 48h window with max being state time + 24h or the current time if it is within that range
  const maxTime =
    props.time.getTime() + 1000 * 60 * 60 * 24 > new Date().getTime()
      ? new Date().getTime()
      : props.time.getTime() + 1000 * 60 * 60 * 24;
  const minTime = maxTime - 1000 * 60 * 60 * 24 * 2;

  const form = useForm({
    initialValues: {
      timestamp: dateFormat(props.time, "yyyy-mm-dd HH:MM:ss"),
    },

    validate: {
      timestamp: (value) =>
        !value || !isNaN(Date.parse(value)) ? null : "Invalid ISO timestamp",
    },
  });

  return (
    <Stack>
      <Group
        align={"center"}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <TextInput
          {...form.getInputProps("timestamp")}
          error={!form.isValid()}
          display={"flex"}
          onKeyDown={getHotkeyHandler([
            ["Enter", () => handleSubmit()],
            ["Escape", props.handleStopEditing],
          ])}
        />
        <Group>
          <ActionIcon
            icon={<IconCheck />}
            disabled={!form.isValid()}
            color={"green"}
            onClick={() => handleSubmit()}
          />
          <ActionIcon
            icon={<IconX />}
            onClick={props.handleStopEditing}
            color={"red"}
          />
        </Group>
      </Group>
      <Slider
        label={null}
        marks={sliderMarks(minTime, maxTime, 13)}
        defaultValue={props.time.getTime()}
        min={minTime}
        max={maxTime}
        onChange={(value) => {
          form.setFieldValue(
            "timestamp",
            dateFormat(new Date(value), "yyyy-mm-dd HH:MM:ss"),
          );
          setSelectedTime(new Date(form.values.timestamp));
        }}
        mb={10}
      />
    </Stack>
  );
};

const sliderMarks = (
  minTime: number,
  maxTime: number,
  tickNumber: number,
): SliderMark[] => {
  const increment = Math.round((maxTime - minTime) / (tickNumber - 1));

  return [...Array(tickNumber).keys()].slice(1).reduce(
    (acc, curr) => {
      let time = minTime + curr * increment;
      if (curr === tickNumber - 1) {
        time = maxTime;
      }
      acc.push({ value: time });
      return acc;
    },
    [{ value: minTime }],
  );
};

type SliderMark = {
  value: number;
};

const IdSelector = (props: {
  stateId: UUID;
  handleStopEditing: () => void;
  exitLiveMode: () => void;
}) => {
  const [, setTimestamp] = useHashParam(HashParams.Timestamp);
  const [, setStateId] = useHashParam(HashParams.StateId);

  const handleSubmit = () => {
    if (form.validate().hasErrors) {
      return;
    }
    setTimestamp(undefined);
    setStateId(form.values.stateId);
    props.exitLiveMode();
    props.handleStopEditing();
  };

  useHotkeys([
    ["Enter", () => handleSubmit()],
    ["Escape", props.handleStopEditing],
  ]);

  const form = useForm({
    initialValues: {
      stateId: props.stateId,
    },

    validate: {
      stateId: (value) =>
        !value || uuidRegex.test(value) ? null : "Invalid UUID",
    },
  });

  return (
    <Group
      align={"center"}
      sx={{ width: "100%", justifyContent: "space-between" }}
    >
      <TextInput
        {...form.getInputProps("stateId")}
        error={!form.isValid()}
        onKeyDown={getHotkeyHandler([
          ["Enter", () => handleSubmit()],
          ["Escape", props.handleStopEditing],
        ])}
        w={270}
      />
      <Group>
        <ActionIcon
          disabled={!form.isValid()}
          icon={<IconCheck />}
          color={"green"}
          onClick={() => handleSubmit()}
        />
        <ActionIcon
          icon={<IconX />}
          color={"red"}
          onClick={props.handleStopEditing}
        />
      </Group>
    </Group>
  );
};
