import SDK from "@hubportal/sdk";
import { NavLink as BaseNavLink } from "@mantine/core";
import {
  IconBuildingStore,
  IconChisel,
  IconHammer,
  IconMap2,
  IconPaperBag,
  IconRobot,
} from "@tabler/icons";
import {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ExtractRouteParams,
  Link as RouteLink,
  Route,
  Switch,
  useLocation,
  useRouter,
} from "wouter";
import { NavbarSectionLink, usePageContext } from "./app";
import { Nullish } from "./custom-schemas";
import { useHashParam } from "./hooks";
import { HubSlug, OrderID } from "./types";
import { RegionHubsView } from "./views/hubs-map";
import { HashParams, HubStateSearchResolver, HubView } from "./views/hubs/hubs";
import { HubStateTool } from "./views/hubstate-tool";
import { OrdersView, OrderView } from "./views/orders";
import { Simulator } from "./views/simulator";
import { TokenTool } from "./views/token-tool";

type Path = `/${string}`;

type ExtendedPath<P extends Path, S extends Path> = `${P}${S}`;

type RouteDesc = {
  icon: ReactNode;
  label: string;
  title: string;
  render: () => ReactNode;
};

type ComplementedRoute<S extends {}, NN extends string, PP extends Path> = S & {
  [k in NN]: {
    path: PP;
    render: (params: ExtractRouteParams<PP>) => ReactNode;
    link: (
      params: ExtractRouteParams<PP>,
      hashParams?: Record<string, any>,
    ) => string;
    title: NN;
  };
};

type ComplementedRoutes<
  R extends {},
  N extends string,
  P extends Path,
  S extends {},
> = R & {
  [k in N]: {
    path: P;
    sub: S;
    link: () => string;
  } & RouteDesc;
};

class RouteBuilder<
  R extends {},
  N extends string,
  P extends Path,
  S extends {},
> {
  private routes: R;
  private name: N;
  private path: P;
  private desc: RouteDesc;
  private subRoutes: S;
  constructor(routes: R, name: N, path: P, desc: RouteDesc, subRoutes: S) {
    this.routes = routes;
    this.name = name;
    this.path = path;
    this.desc = desc;
    this.subRoutes = subRoutes;
  }
  sub<NN extends string, PP extends Path>(
    name: NN,
    path: ExtendedPath<P, PP>,
    render: (
      params: ExtractRouteParams<PP>,
      mapViewSelection: string,
      setMapViewSelection: (selection: Nullish<string>) => void,
    ) => ReactNode,
    title: NN,
  ): RouteBuilder<R, N, P, ComplementedRoute<S, NN, PP>> {
    return new RouteBuilder(this.routes, this.name, this.path, this.desc, {
      ...this.subRoutes,
      [name]: {
        path,
        render,
        link: (
          params: ExtractRouteParams<PP>,
          hashParams: Record<string, any>,
        ) => {
          const pathWithParams = Object.entries(params).reduce(
            (p, [k, v]) => p.replace(`:${k}`, `${v}`),
            path as string,
          );

          const hash = !hashParams
            ? ""
            : "#" + new URLSearchParams(hashParams).toString();

          return pathWithParams + hash;
        },
        title,
      },
    } as any);
  }
  route<NN extends string, PP extends Path>(
    name: NN,
    path: PP,
    info: RouteDesc,
  ): RouteBuilder<ComplementedRoutes<R, N, P, S>, NN, PP, {}> {
    return this.done().route(name, path, info);
  }
  done(): RoutesBuilder<ComplementedRoutes<R, N, P, S>> {
    return new RoutesBuilder({
      ...this.routes,
      [this.name]: {
        path: this.path,
        sub: this.subRoutes,
        link: () => this.path,
        ...this.desc,
      },
    } as any);
  }
  build(): ComplementedRoutes<R, N, P, S> {
    return this.done().done();
  }
}

class RoutesBuilder<R extends {}> {
  private routes: R;
  constructor(routes: R) {
    this.routes = routes;
  }
  static start(): RoutesBuilder<{}> {
    return new RoutesBuilder({});
  }
  route<N extends string, P extends Path>(
    name: N,
    path: P,
    info: RouteDesc,
  ): RouteBuilder<R, N, P, {}> {
    return new RouteBuilder(this.routes, name, path, info, {});
  }
  done(): Readonly<R> {
    return this.routes;
  }
}

export const routes = RoutesBuilder.start()
  .route("hubs", "/dispatching", {
    icon: <IconBuildingStore size={16} />,
    label: "Hubs",
    title: "Hub: " + SDK.getHub().slug,
    render: () => {
      const [, setLocation] = useLocation();
      setLocation(routes.hubs.sub.bySlug.link({ slug: SDK.getHub().slug }));
      return <></>;
    },
  })
  .sub(
    "bySlug",
    "/dispatching/hubs/:slug",
    (params, mapViewSelection, setMapViewSelection) => {
      setMapViewSelection(mapViewSelection);
      return <HubView slug={params.slug} />;
    },
    "Hub:",
  )
  .sub(
    "searchHubStateId",
    "/dispatching/hubs/search/hub_state/:stateId",
    (params) => {
      return <HubStateSearchResolver stateId={params.stateId} />;
    },
    "State:",
  )
  .route("orders", "/dispatching/orders", {
    icon: <IconPaperBag size={16} />,
    label: "Orders",
    title: "Recent Orders",
    render: () => <OrdersView />,
  })
  .sub(
    "byId",
    "/dispatching/orders/:idOrNumber",
    (params) => <OrderView idOrNumber={params.idOrNumber} />,
    "Order:",
  )
  .route("map", "/dispatching/map", {
    icon: <IconMap2 size={16} />,
    label: "Map",
    title: "Map View",
    render: () => <RegionHubsView />,
  })
  .sub(
    "byPrefix",
    "/dispatching/map/:prefix",
    (params) => <RegionHubsView prefix={params.prefix} />,
    "Map View:",
  )
  .route("hubstate-tool", "/dispatching/hubstate-tool", {
    icon: <IconHammer size={16} />,
    label: "Hub State Tool",
    title: "Hub State Tool",
    render: () => <HubStateTool />,
  })
  .route("delivery-option-tool", "/dispatching/delivery-option-tool", {
    icon: <IconChisel size={16} />,
    label: "Delivery Option Decoder",
    title: "Delivery Option Decoder",
    render: () => <TokenTool />,
  })
  .route("simulator", "/dispatching/simulator", {
    label: "Simulator",
    render: () => <Simulator />,
    icon: <IconRobot size={16} />,
    title: "Simulator",
  })
  .build();

export const MainLinks = () => {
  const [location, setLocation] = useLocation();
  const router = useRouter();

  const links = Object.values(routes).map(({ label, path, icon, sub }) => {
    const paths = [path, ...Object.values(sub).map((s) => s.path as string)];
    return (
      <NavbarSectionLink
        icon={icon}
        label={label}
        key={label}
        onClick={() => setLocation(path)}
        active={paths.some((path) => router.matcher(path, location)[0])}
      />
    );
  });
  return <div>{links}</div>;
};

export const NavLink = (params: {
  icon: ReactNode;
  label: ReactNode;
  onClick: MouseEventHandler;
  active?: boolean;
}) => {
  const page = usePageContext();

  return (
    <BaseNavLink
      label={page.compactShell ? "-" : params.label}
      icon={params.icon}
      onClick={params.onClick}
      active={params.active}
      data-dd-action-name={params.label + " navlink"}
    />
  );
};

export const ContentRouter = () => {
  const [pageTitle, setPageTitle] = useState<string>();
  const didMountRef = useRef(false);
  const [mapViewSelection, setMapViewSelection] = useHashParam(
    HashParams.MapViewSelection,
  );

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      if (
        pageTitle &&
        pageTitle !== document.title &&
        document.URL.match("dispatching")
      ) {
        document.title = pageTitle;
      }
    });
    observer.observe(document.querySelector("title")!, {
      childList: true,
    });

    if (pageTitle && !didMountRef.current) {
      document.title = pageTitle;
      didMountRef.current = true;
    }

    return () => {
      observer.disconnect();
      didMountRef.current = false;
    };
  }, [pageTitle]);

  const entries = Object.values(routes).flatMap((r) => {
    return [
      <Route key={r.path} path={r.path}>
        {() => {
          setPageTitle(r.title);
          return r.render();
        }}
      </Route>,
      ...Object.values(r.sub).map((sr) => (
        <Route key={sr.path} path={sr.path}>
          {(params) => {
            setPageTitle(sr.title + " " + Object.values(params)[0]);
            return sr.render(params, mapViewSelection, setMapViewSelection);
          }}
        </Route>
      )),
    ];
  });

  return <Switch>{entries}</Switch>;
};

export type Linkable<F extends (...args: any) => string> = {
  link: F;
};

export type LinkParameters<T extends (...args: any) => any> =
  T extends () => string
    ? {}
    : T extends (arg: infer P) => string
    ? { params: P }
    : never;

export const Link = <F extends (...args: any) => string>(
  params: {
    route: Linkable<F>;
    children: ReactNode;
  } & LinkParameters<F>,
) => {
  return (
    <RouteLink href={params.route.link((params as any).params)}>
      {params.children}
    </RouteLink>
  );
};

export const OrderLink = (props: { orderId: OrderID }) => (
  <Link
    route={routes.orders.sub.byId}
    params={{ idOrNumber: props.orderId }}
    data-dd-action-name="Order link"
  >
    {props.orderId}
  </Link>
);

export const CountryLink = (props: { country: string }) => (
  <Link
    route={routes.map.sub.byPrefix}
    params={{ prefix: props.country }}
    data-dd-action-name="Country link"
  >
    {props.country}
  </Link>
);

export const CityLink = (props: { city: string }) => (
  <Link
    route={routes.map.sub.byPrefix}
    params={{ prefix: props.city }}
    data-dd-action-name="City link"
  >
    {props.city}
  </Link>
);

export const HubLink = (props: { slug: HubSlug }) => (
  <Link
    route={routes.hubs.sub.bySlug}
    params={{ slug: props.slug }}
    data-dd-action-name="Hub link"
  >
    {props.slug}
  </Link>
);
