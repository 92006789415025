// This file has been generated by ts-gen; DO NOT EDIT!
import { z } from "zod";
import { custom } from "./custom-schemas";

// custom github.com/goflink/dispatching/internal/hubstate.Bottleneck
export const Bottleneck = custom.Bottleneck;
export type Bottleneck = z.infer<typeof Bottleneck>;

// custom github.com/goflink/dispatching/internal/dispatching/api.DeliveryMethod
export const DeliveryMethod = custom.DeliveryMethod;
export type DeliveryMethod = z.infer<typeof DeliveryMethod>;

// custom github.com/goflink/dispatching/internal/ordersx.DeliveryType
export const DeliveryType = custom.DeliveryType;
export type DeliveryType = z.infer<typeof DeliveryType>;

// custom time.Duration
export const Duration = custom.Duration;
export type Duration = z.infer<typeof Duration>;

// custom github.com/goflink/dispatching/internal/stacking.Event
export const Event = custom.Event;
export type Event = z.infer<typeof Event>;

// custom github.com/goflink/dispatching/internal/pickerqueue.Group
export const Group = custom.Group;
export type Group = z.infer<typeof Group>;

// custom gorm.io/datatypes.JSON
export const JSON = custom.JSON;
export type JSON = z.infer<typeof JSON>;

// custom github.com/labd/commercetools-go-sdk/platform.Order
export const RawCTOrder = custom.RawCTOrder;
export type RawCTOrder = z.infer<typeof RawCTOrder>;

// custom github.com/goflink/dispatching/internal/primitives.OrderIDSet
export const OrderIDSet = custom.OrderIDSet;
export type OrderIDSet = z.infer<typeof OrderIDSet>;

// custom github.com/goflink/dispatching/internal/fulfillment.PhaseDurations
export const PhaseDurations = custom.PhaseDurations;
export type PhaseDurations = z.infer<typeof PhaseDurations>;

// custom github.com/goflink/dispatching/internal/hubstate.RiderStatus
export const RiderStatus = custom.RiderStatus;
export type RiderStatus = z.infer<typeof RiderStatus>;

// custom github.com/goflink/dispatching/internal/fulfillment.TemperatureCategories
export const TemperatureCategories = custom.TemperatureCategories;
export type TemperatureCategories = z.infer<typeof TemperatureCategories>;

// custom github.com/goflink/dispatching/internal/fulfillment.TemperatureCategory
export const TemperatureCategory = custom.TemperatureCategory;
export type TemperatureCategory = z.infer<typeof TemperatureCategory>;

// custom time.Time
export const Time = custom.Time;
export type Time = z.infer<typeof Time>;

// custom github.com/goflink/dispatching/internal/fulfillment.Timestamp
export const Timestamp = custom.Timestamp;
export type Timestamp = z.infer<typeof Timestamp>;

// custom github.com/goflink/dispatching/internal/fulfillment.TimestampEvents
export const TimestampEvents = custom.TimestampEvents;
export type TimestampEvents = z.infer<typeof TimestampEvents>;

// custom github.com/google/uuid.UUID
export const UUID = custom.UUID;
export type UUID = z.infer<typeof UUID>;

// custom github.com/goflink/dispatching/internal/hubstate.Vehicle
export const Vehicle = custom.Vehicle;
export type Vehicle = z.infer<typeof Vehicle>;

// CRMSteeringFlags are the flags to tweak the CRM live demand steering
//
// go type: github.com/goflink/dispatching/internal/flags.CRMSteeringFlags
export const CRMSteeringFlags = z.object({
  PositiveThreshold: z.number(),
  NegativeThreshold: z.number(),
});
export type CRMSteeringFlags = z.infer<typeof CRMSteeringFlags>;

// ContainerCounts is a bag of container types, providing a count for each type
//
// go type: github.com/goflink/dispatching/internal/fulfillment.ContainerCounts
export const ContainerCounts = z
  .array(z.number())
  .nullish()
  .transform((a) => a ?? []);
export type ContainerCounts = z.infer<typeof ContainerCounts>;

// ContainerID is the ID of a container for an order
//
// go type: github.com/goflink/dispatching/internal/primitives.ContainerID
export const ContainerID = z.string();
export type ContainerID = z.infer<typeof ContainerID>;

// ContainerType represents the type of container used for an order
//
// go type: github.com/goflink/dispatching/internal/fulfillment.ContainerType
export const ContainerType = z.number();
export type ContainerType = z.infer<typeof ContainerType>;

// Coordinates represents a location on the globe in terms of latitude and longitude.
//
// go type: github.com/goflink/dispatching/internal/geo.Coordinates
export const Coordinates = z.object({
  latitude: z.number(),
  longitude: z.number(),
});
export type Coordinates = z.infer<typeof Coordinates>;

// Customer groups together information about the customer that placed an order
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Customer
export const Customer = z.object({
  IsNewCustomer: z.boolean(),
  EmailAddress: z.string(),
  FirstName: z.string(),
  LastName: z.string(),
  StreetAndNumber: z.string(),
  PostalCode: z.string(),
  City: z.string(),
  Country: z.string(),
  // DeliveryTag is an enum values that indicates the type of delivery, e.g. delivering to work, home, or outdoor.
  // It is set by the customer when selecting a delivery address.
  DeliveryTag: z.string(),
});
export type Customer = z.infer<typeof Customer>;

// DeliveryType indicates the mode of delivery from the customers point of view
// Note that this is currently only used to discriminated `asap_delivery` and `planned_delivery`,
// which fall under the delivered by flink umbrella.
//
// swagger:enum DeliveryType
//
// go type: github.com/goflink/dispatching/internal/fulfillment.DeliveryType
export const DeliveryOptionType = z.string();
export type DeliveryOptionType = z.infer<typeof DeliveryOptionType>;

// DeliveryUrgency is a measure of how urgently we'd like to deliver a delivery.
//
// go type: github.com/goflink/dispatching/internal/timeestimation.DeliveryUrgency
export const DeliveryUrgency = z.number();
export type DeliveryUrgency = z.infer<typeof DeliveryUrgency>;

// A DeliveryWindow for a planned delivery
//
// go type: github.com/goflink/dispatching/internal/fulfillment.DeliveryWindow
export const DeliveryWindow = z.object({
  // Start of the delivery window in the case of planned_delivery
  start: Time,
  // End of the delivery window in the case of planned_delivery
  end: Time,
});
export type DeliveryWindow = z.infer<typeof DeliveryWindow>;

// DeploymentFlags holds flags that are used to gradually roll out changes that are eventually be expected to be applied unconditionally
//
// go type: github.com/goflink/dispatching/internal/flags.DeploymentFlags
export const DeploymentFlags = z.object({
  UseOpenStreetmapRouter: z.boolean(),
  LogPickingTimelinePredictions: z.boolean(),
  UseJustInTimeStacking: z.boolean(),
  UseCommunityOutsourcing: z.boolean(),
});
export type DeploymentFlags = z.infer<typeof DeploymentFlags>;

// EventStatus indicates whether the event has occurred, is overdue, or is estimated to occur in the future.
//
// swagger:enum EventStatus
//
// go type: github.com/goflink/dispatching/internal/dispatching/api.EventStatus
export const EventStatus = z.string();
export type EventStatus = z.infer<typeof EventStatus>;

// Fee represents the financial amount that the extermal service provider is charging us to deliver the order
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Fee
export const Fee = z.object({
  Amount: z.number(),
  Currency: z.string(),
});
export type Fee = z.infer<typeof Fee>;

// FieldMetadata contains the metadata of a type containing feature flags.
//
// go type: github.com/goflink/dispatching/internal/flags.FieldMetadata
export const FieldMetadata = z.object({
  Key: z.string(),
  Description: z.string().nullish(),
  Source: z.string().nullish(),
});
export type FieldMetadata = z.infer<typeof FieldMetadata>;

// GlobalFlags are flags that are not actually hub specific
//
// go type: github.com/goflink/dispatching/internal/flags.GlobalFlags
export const GlobalFlags = z.object({});
export type GlobalFlags = z.infer<typeof GlobalFlags>;

// Grams provides a standard, intuitive measure of weight
//
// go type: github.com/goflink/dispatching/internal/primitives.Grams
export const Grams = z.number();
export type Grams = z.infer<typeof Grams>;

// HubRiderCounts contains probabilistic counts of riders at a hub.
//
// The counts are represented as floats rather than as integers,
// because for some riders we may be uncertain about whether they are actually on shift.
// If we have 2 riders that we're quite sure are available, and 3 riders where we think it's 75% likely that they're available,
// we can count this as 2 + 0.75 * 3 = 4.25 riders.
//
// go type: github.com/goflink/dispatching/internal/hubstate.HubRiderCounts
export const HubRiderCounts = z.object({
  OnTrip: z.number(),
  Available: z.number(),
  ImmediatelyAvailable: z.number(),
});
export type HubRiderCounts = z.infer<typeof HubRiderCounts>;

// HubSlug is the hub's slug, a short, unique, human-readable identifier such as "de_ber_mit1".
//
// **Currently** slugs have the form <country_code>_<city_code>_<local_hub_code>,
// but callers should not rely on the internal structure of the slug, and instead treat it as an id.
//
// go type: github.com/goflink/dispatching/internal/primitives.HubSlug
export const HubSlug = z.string();
export type HubSlug = z.infer<typeof HubSlug>;

// Meters provides a standard, intuitive measure of distance
//
// go type: github.com/goflink/dispatching/internal/primitives.Meters
export const Meters = z.number();
export type Meters = z.infer<typeof Meters>;

// Minutes provides a standard, intuitive measure of time
//
// go type: github.com/goflink/dispatching/internal/primitives.Minutes
export const Minutes = z.number();
export type Minutes = z.infer<typeof Minutes>;

// ModelStructureVersion represents the version of the model structure used to create estimates.
//
// go type: github.com/goflink/dispatching/internal/primitives.ModelStructureVersion
export const ModelStructureVersion = z.number();
export type ModelStructureVersion = z.infer<typeof ModelStructureVersion>;

// OfferID is the ID of a trip offer
//
// go type: github.com/goflink/dispatching/internal/primitives.OfferID
export const OfferID = z.string();
export type OfferID = z.infer<typeof OfferID>;

// OrderID is the primary ID of an order, currently a UUID assigned by commercetools
//
// go type: github.com/goflink/dispatching/internal/primitives.OrderID
export const OrderID = z.string();
export type OrderID = z.infer<typeof OrderID>;

// OrderNumber is an order number, a unique string assigned to an order that is more human-readable than an order ID
//
// go type: github.com/goflink/dispatching/internal/primitives.OrderNumber
export const OrderNumber = z.string();
export type OrderNumber = z.infer<typeof OrderNumber>;

// OrderVersion is the version of an order. This is used during order updates to enable optimistic locking.
//
// go type: github.com/goflink/dispatching/internal/primitives.OrderVersion
export const OrderVersion = z.number();
export type OrderVersion = z.infer<typeof OrderVersion>;

// OutsourcedTripConfirmation holds information about the outsourced trip booked with an external provider.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.OutsourcedTripConfirmation
export const OutsourcedTripConfirmation = z.object({
  // ConfirmedAt is the time when the trip was confirmed by the external provider.
  ConfirmedAt: Time,
  // HandoverID is the ID that is used by the external rider to identify the orders to pick up. When the rider
  // arrives at the hub, they'll present this ID to the hub staff and the staff will use it to find the packed items.
  HandoverID: z.string(),
  // DeliveryETA is the provided-reported ETA for the dropoff
  DeliveryETA: Time,
  // PickupETA is the provider-reported ETA for picking up the order
  PickupETA: Time,
});
export type OutsourcedTripConfirmation = z.infer<
  typeof OutsourcedTripConfirmation
>;

// OutsourcingError records the error received when trying to generate the quote for the shipment
//
// go type: github.com/goflink/dispatching/internal/fulfillment.OutsourcingError
export const OutsourcingError = z.object({
  Msg: z.string(),
  Code: z.string().nullish(),
  IsRetriable: z.boolean(),
  ErroredAt: Time,
});
export type OutsourcingError = z.infer<typeof OutsourcingError>;

// OutsourcingProviderName is the name of a delivery outsourcing provider
//
// go type: github.com/goflink/dispatching/internal/primitives.OutsourcingProviderName
export const OutsourcingProviderName = z.string();
export type OutsourcingProviderName = z.infer<typeof OutsourcingProviderName>;

// Phase indicates which phase of the order fulfillment flow an order is in.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Phase
export const Phase = z.number();
export type Phase = z.infer<typeof Phase>;

// Price standard representation of a price
//
// go type: github.com/goflink/dispatching/internal/primitives.Price
export const Price = z.object({
  CentAmount: z.number(),
  Currency: z.string(),
});
export type Price = z.infer<typeof Price>;

// QuoteID is the ID of a quote of an delivery outsourcing provider
//
// go type: github.com/goflink/dispatching/internal/primitives.QuoteID
export const QuoteID = z.string();
export type QuoteID = z.infer<typeof QuoteID>;

// Range represents a range of values
//
// go type: github.com/goflink/dispatching/internal/flags.Range
export const Range = <N extends z.ZodTypeAny>(N: N) =>
  z.object({
    Low: N,
    High: N,
  });

// ReportedLegETA is a timestamped estimate of the remaining time to complete the active leg of a trip.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.ReportedLegETA
export const ReportedLegETA = z.object({
  Timestamp: Time,
  LegIndex: z.number(),
  Remaining: Duration,
});
export type ReportedLegETA = z.infer<typeof ReportedLegETA>;

// RiderEquipmentFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.RiderEquipmentFlags
export const RiderEquipmentFlags = z.object({
  Boxes: z.number(),
  Saddlebags: z.number(),
});
export type RiderEquipmentFlags = z.infer<typeof RiderEquipmentFlags>;

// RiderID is an ID for a rider
//
// go type: github.com/goflink/dispatching/internal/primitives.RiderID
export const RiderID = z.string();
export type RiderID = z.infer<typeof RiderID>;

// RouteTravelMode is the routing type to be sent to the directions provider
//
// go type: github.com/goflink/dispatching/internal/routing.RouteTravelMode
export const RouteTravelMode = z.number();
export type RouteTravelMode = z.infer<typeof RouteTravelMode>;

// SKU identifies the variant of a product
//
// go type: github.com/goflink/dispatching/internal/primitives.SKU
export const SKU = z.string();
export type SKU = z.infer<typeof SKU>;

// Set is a collection of elements
//
// go type: github.com/goflink/dispatching/internal/utils.Set
export const Set = <T extends z.ZodTypeAny>(T: T) =>
  z
    .record(T, z.object({}))
    .nullish()
    .transform((m) => m ?? {});

// StackingDetails contains any details about the stacking run that is valuable enough to be in the hubstate.
//
// go type: github.com/goflink/dispatching/internal/hubstate.StackingDetails
export const StackingDetails = z.object({
  FrozenZoneAlgo: z.string(),
  NonFrozenZoneAlgo: z.string(),
});
export type StackingDetails = z.infer<typeof StackingDetails>;

// StackingEngineFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.StackingEngineFlags
export const StackingEngineFlags = z.object({
  MergeWindowSize: z.number(),
  SumOfSquaredRelativeITSDisagreementFactor: z.number(),
  RootAdditionalSumOfSquaredDeliveryUrgencyFactor: z.number(),
  RootMeanSquaredUnstackedDeliveryUrgencyFactor: z.number(),
  RootSumOfSquaredUnstackedDeliveryUrgencyFactor: z.number(),
  StackedUrgencyStandardDeviationFactor: z.number(),
  SumOfSquaredDeliveryUrgencyPermutationPriorityFactor: z.number(),
  PriorityBoostPerOrder: z.number(),
  IdealTripStartPriorityBoostFactor: z.number(),
  MeanSoloITSPriorityBoostFactor: z.number(),
});
export type StackingEngineFlags = z.infer<typeof StackingEngineFlags>;

// State summarises the fulfillment state of an order.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.State
export const State = z.number();
export type State = z.infer<typeof State>;

// StringSlice is a wrapper around a list of strings, so we can specifically marshal/unmarshal data into a csv
//
// go type: github.com/goflink/dispatching/internal/utils.StringSlice
export const StringSlice = z
  .array(z.string())
  .nullish()
  .transform((a) => a ?? []);
export type StringSlice = z.infer<typeof StringSlice>;

// TimeEstimationFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.TimeEstimationFlags
export const TimeEstimationFlags = z.object({
  ModelVersion: z.string(),
  EnablePDTExperiment: z.boolean(),
  TrackPDTExperiment: z.boolean(),
  PessimisticDeliveryProgressEstimates: z.boolean(),
  LogConsumerFacingEstimates: z.boolean(),
  DeliveryWindowTargetEndScaler: z.number(),
  PlannedOrderBaselineFactor: z.number(),
  PriorityOrderBaselineFactor: z.number(),
  NoRushOrderBaselineFactor: z.number(),
  UseNewPDTModel: z.boolean(),
});
export type TimeEstimationFlags = z.infer<typeof TimeEstimationFlags>;

// TrackingInformation mirrors how trip identity is modelled in commercetools.
//
// Once we have rid ourselves of M-TRIBES, we may be able to phase out this type,
// ignore the tracking provider, and just use primitives.TripID directly.
//
// go type: github.com/goflink/dispatching/internal/ordersx.TrackingInformation
export const TrackingInformation = z.object({
  Provider: z.string(),
  // ID is the ID of the trip. It might be a TripID, if the order was created while using
  // the rider app, or a BookingID, if created with MTribes.
  // MTribes BookingIDs are still relevant if you are looking at old order in your app.
  ID: z.string(),
});
export type TrackingInformation = z.infer<typeof TrackingInformation>;

// TripID is the ID of a trip in the in-house rider app
//
// go type: github.com/goflink/dispatching/internal/primitives.TripID
export const TripID = z.string();
export type TripID = z.infer<typeof TripID>;

// TripOffersFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.TripOffersFlags
export const TripOffersFlags = z.object({
  ExpirationTimeInSeconds: z.number(),
});
export type TripOffersFlags = z.infer<typeof TripOffersFlags>;

// TripProposalCounts is a structure with counts for trip proposals meeting various criteria
//
// go type: github.com/goflink/dispatching/internal/hubstate.TripProposalCounts
export const TripProposalCounts = z.object({
  // Proposals for which orders are packed
  Claimable: z.number(),
  // Proposals we want to reserve for outsourcing. Only proposals with certain criteria are outsourcable. We want to
  // outsource the proposals that are least suitable for stacking.
  Outsourcable: z.number(),
});
export type TripProposalCounts = z.infer<typeof TripProposalCounts>;

// VehicleID is an ID for a vehicle
//
// go type: github.com/goflink/dispatching/internal/primitives.VehicleID
export const VehicleID = z.string();
export type VehicleID = z.infer<typeof VehicleID>;

// VehicleType is what kind of Vehicle it is.
//
// go type: github.com/goflink/dispatching/internal/hubstate.VehicleType
export const VehicleType = z.number();
export type VehicleType = z.infer<typeof VehicleType>;

// Window based on absolute times
//
// go type: github.com/goflink/dispatching/internal/datetime.Window
export const Window = z.object({
  // Start time at which the window starts
  start: Time,
  // End time at which the window ends
  end: Time,
});
export type Window = z.infer<typeof Window>;

// orderPhaseEstimates ...
//
// go type: github.com/goflink/dispatching/internal/ui.orderPhaseEstimates
export const orderPhaseEstimates = z.object({
  QueueingForPickerPhase: Duration,
  PickingPhase: Duration,
  QueueingForRiderPhase: Duration,
  RiderPreparationPhase: Duration,
  RidingPhase: Duration,
  HandingOffPhase: Duration,
});
export type orderPhaseEstimates = z.infer<typeof orderPhaseEstimates>;

// BottleneckRange is a range of time where a bottleneck was present
//
// go type: github.com/goflink/dispatching/internal/hubstate.BottleneckRange
export const BottleneckRange = z.object({
  Start: Time,
  Bottleneck: Bottleneck,
});
export type BottleneckRange = z.infer<typeof BottleneckRange>;

// DeliveryOption information encoded into the delivery token
//
// swagger:model
//
// go type: github.com/goflink/dispatching/internal/fulfillment.DeliveryOption
export const DeliveryOption = z.object({
  // Created time of this token
  created: Time,
  // Hub this token was created for
  hub: HubSlug,
  // DeliveryType indicates whether this token represents
  // a `PLANNED` or `ASAP` delivery.
  type: DeliveryType,
  // Start of the delivery window in the case of planned_delivery
  start: Time,
  // End of the delivery window in the case of planned_delivery
  end: Time,
  // PDT of the delivery in case of an asap_delivery
  pdt: Duration,
});
export type DeliveryOption = z.infer<typeof DeliveryOption>;

// DeliveryProgressEvent represents a past or future event.
//
// Information on the status of a delivery milestone event.
//
// go type: github.com/goflink/dispatching/internal/dispatching/api.DeliveryProgressEvent
export const DeliveryProgressEvent = z.object({
  // Time is the actual or estimated time of the event.
  // For an event for which we cannot make a reasonable estimate, because the order has been cancelled or
  // abandoned (no interaction today).
  // we'll for now return 2030-01-01T00:00:00Z.
  time: Time,
  // Status indicates whether the event has already occurred, is overdue, or is estimated to occur in the future.
  status: EventStatus,
});
export type DeliveryProgressEvent = z.infer<typeof DeliveryProgressEvent>;

// DeliveryProgressHubInfo details about the hub
//
// go type: github.com/goflink/dispatching/internal/dispatching/api.DeliveryProgressHubInfo
export const DeliveryProgressHubInfo = z.object({
  slug: HubSlug,
  // Coordinates where the hub is located
  coordinates: Coordinates,
});
export type DeliveryProgressHubInfo = z.infer<typeof DeliveryProgressHubInfo>;

// Equipment Datastructure to hold information about the vehicles carrying equipment
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Equipment
export const Equipment = z.object({
  ContainerType: ContainerType,
});
export type Equipment = z.infer<typeof Equipment>;

// HubDeliveryArea has the turfs of a particular hub
//
// go type: github.com/goflink/dispatching/internal/hubs.HubDeliveryArea
export const HubDeliveryArea = z.object({
  default_location: Coordinates,
  slug: HubSlug,
  turfs: z
    .array(
      z
        .array(Coordinates)
        .nullish()
        .transform((a) => a ?? []),
    )
    .nullish()
    .transform((a) => a ?? []),
});
export type HubDeliveryArea = z.infer<typeof HubDeliveryArea>;

// HubStateHistorySummary a summary view for a particular hubstate (update).
//
// go type: github.com/goflink/dispatching/internal/hubstate/database.HubStateHistorySummary
export const HubStateHistorySummary = z.object({
  ID: UUID,
  Hub: HubSlug,
  Timestamp: Time,
  CommandName: z.string().nullish(),
  CommandData: JSON.nullish(),
  Revision: z.number(),
});
export type HubStateHistorySummary = z.infer<typeof HubStateHistorySummary>;

// InputParameters are parameters that were used to derive a hub state.
//
// go type: github.com/goflink/dispatching/internal/hubstate.InputParameters
export const InputParameters = z.object({
  // SmoothedTripProposalCount is the smoothed trip proposal count used when deriving this hub state.
  SmoothedTripProposalCount: z.number(),
  // SmoothedRiderCounts are the smoothed rider counts that were used when deriving this hub state (e.g. for stacking).
  SmoothedRiderCounts: HubRiderCounts,
  // SmoothedPickersNeeded is the smoothed pickers needed value.
  SmoothedPickersNeeded: z.number(),
  // SmoothedOrdersInPicking is the smoothed number of orders that are in picking
  SmoothedOrdersInPicking: z.number(),
});
export type InputParameters = z.infer<typeof InputParameters>;

// KitchenTimestamps Timestamps for food preparation in the external kitchen.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.KitchenTimestamps
export const KitchenTimestamps = z.object({
  PreparationRequestedAt: Timestamp,
  PreparationRequestedFor: Timestamp,
  Prepared: Timestamp,
});
export type KitchenTimestamps = z.infer<typeof KitchenTimestamps>;

// LineItem of an order
//
// go type: github.com/goflink/dispatching/internal/fulfillment.LineItem
export const LineItem = z.object({
  SKU: SKU,
  OriginalUnitPrice: Price,
  DiscountedUnitPrices: z
    .array(Price)
    .nullish()
    .transform((a) => a ?? []),
  TemperatureCategories: z
    .array(TemperatureCategory)
    .nullish()
    .transform((a) => a ?? []),
});
export type LineItem = z.infer<typeof LineItem>;

// ModelVersion represents the version of models used to create estimates.
// It is composed of a two independent parts: a structure version and the build version.
// The structure version is incremented when the structure of the model changes.
// The build version is an optional lexicographically sortable string that is incremented when the model is rebuilt.
// Right now, it is the date of the build in the format YYYYMMDD.
//
// go type: github.com/goflink/dispatching/internal/primitives.ModelVersion
export const ModelVersion = z.object({
  Structure: ModelStructureVersion,
  Build: z.string(),
});
export type ModelVersion = z.infer<typeof ModelVersion>;

// Offer an offer of a TripProposal that had been made to a rider
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Offer
export const Offer = z.object({
  ID: OfferID,
  Rider: RiderID,
  OfferedAt: Time,
  ExpiresAt: Time,
});
export type Offer = z.infer<typeof Offer>;

// OrderItems contains information about the items that the customer ordered
//
// go type: github.com/goflink/dispatching/internal/fulfillment.OrderItems
export const OrderItems = z.object({
  BySKU: z
    .record(SKU, z.number())
    .nullish()
    .transform((m) => m ?? {}),
  MissingGrossWeight: z.number(),
  WithFallbackWeight: z.number(),
  TotalWeight: Grams,
  // Which type of temperature-sensitive items does this order contain
  TemperatureCategories: TemperatureCategories,
});
export type OrderItems = z.infer<typeof OrderItems>;

// OrderSummary has basic information about an order
//
// go type: github.com/goflink/dispatching/internal/ui.OrderSummary
export const OrderSummary = z.object({
  ID: OrderID,
  Hub: HubSlug,
  CreatedAt: Time,
});
export type OrderSummary = z.infer<typeof OrderSummary>;

// OutsourcingConfirmation is the data that is written by the delivery-outsourcing service to commercetools when
// a quote to outsource an order delivery is booked and confirmed by an external partner (e.g. Uber Eats).
// Besides details about the quote itself, it also lists the orders that are part of the quote and the handover ID that
// the external partner uses to identify the orders at the hub that they are responsible for.
//
// go type: github.com/goflink/dispatching/internal/ordersx.OutsourcingConfirmation
export const OutsourcingConfirmation = z.object({
  // QuoteID is the ID of the quote that was used to book this delivery.
  quote_id: QuoteID,
  // ProviderName is the name of the external provider that will deliver the orders.
  provider_name: OutsourcingProviderName,
  // HandoverID is the ID of the handover that was created for this delivery.
  // The external riders present this id to the staff when arriving at the hub to pick up the orders.
  handover_id: z.string(),
  // OrderIDs is the list of orders that will be delivered by the provider, in the same sequence as we
  // requested them to be delivered.
  order_ids: z
    .array(OrderID)
    .nullish()
    .transform((a) => a ?? []),
  // CreatedAt is the time at which the quote was first issued by the provider.
  created_at: Time,
  // ConfirmedAt is the time at which the quote was booked and confirmed by the provider.
  confirmed_at: Time,
});
export type OutsourcingConfirmation = z.infer<typeof OutsourcingConfirmation>;

// OutsourcingFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.OutsourcingFlags
export const OutsourcingFlags = z.object({
  QueueingTimeCap: Minutes,
  ReturningRiderHorizon: Minutes,
  MaximumUnstartedOrders: z.number(),
  MaximumUnpickedOrders: z.number(),
  EnabledProviders: StringSlice,
  CutoffTimeBeforeRetractions: Minutes,
  RemovePickLogicForOutsourceDecisionMaking: z.boolean(),
  UberMinimumDistanceMeters: z.number(),
  WoltMinimumDistanceMeters: z.number(),
  UberMaximumWeightGrams: z.number(),
  QuoteErrorRemovalWindow: Minutes,
});
export type OutsourcingFlags = z.infer<typeof OutsourcingFlags>;

// OutsourcingQuote contains the conditions to which we outsource the trip. The ID of the quote is used as input when
// creating an outsourced trip. A quote expires after a certain time.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.OutsourcingQuote
export const OutsourcingQuote = z.object({
  ID: QuoteID,
  ProviderName: OutsourcingProviderName,
  CreatedAt: Time,
  ExpiresAt: Time,
  // ProviderFee is the estimate cost given to us by the outsourcing provider for completing the delivery
  ProviderFee: Fee,
  // HasBeenSelected indicates whether this quote (in the list of quotes it is contained in) is the one selected for the delivery
  HasBeenSelected: z.boolean(),
});
export type OutsourcingQuote = z.infer<typeof OutsourcingQuote>;

// PathMetadata contains the path a field and the metadata of for that field.
//
// go type: github.com/goflink/dispatching/internal/flags.PathMetadata
export const PathMetadata = z.object({
  Path: z
    .array(z.string())
    .nullish()
    .transform((a) => a ?? []),
  Metadata: FieldMetadata,
});
export type PathMetadata = z.infer<typeof PathMetadata>;

// PickerQueueFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.PickerQueueFlags
export const PickerQueueFlags = z.object({
  Behavior: z.string(),
  MinimumRiderQueueLength: z.number(),
  CriticalHorizon: Minutes,
  ComfortableHorizon: Minutes,
  EarliestColdFoodReadiness: Minutes,
  OuterHorizon: Minutes,
  PickersNeededVersion: z.number(),
  EnableBackstopGroup: z.boolean(),
});
export type PickerQueueFlags = z.infer<typeof PickerQueueFlags>;

// PlannedOrdersFlags are the flags to tweak the planned orders window capacities
//
// go type: github.com/goflink/dispatching/internal/flags.PlannedOrdersFlags
export const PlannedOrdersFlags = z.object({
  RiderCapacity: z.number(),
  OrderCostWeight: z.number(),
  FirstWindowBuffer: Minutes,
});
export type PlannedOrdersFlags = z.infer<typeof PlannedOrdersFlags>;

// PlannedWindow basic capacity info for a planned delivery window
//
// go type: github.com/goflink/dispatching/internal/ui.PlannedWindow
export const PlannedWindow = z
  .object({
    // FreeCapacity the free capacity of that window, in terms of Order Cost.
    // If FreeCapacity == 0 we won't accept new orders for that window.
    free_capacity: z.number(),
    // TotalCapacity the total capacity of that window, in terms of Order Cost.
    // This can be used together with FreeCapacity to gauge how "full" the window
    // is at this point in time.
    total_capacity: z.number(),
  })
  .merge(Window);
export type PlannedWindow = z.infer<typeof PlannedWindow>;

// Rider state of a RIDER
//
// go type: github.com/goflink/dispatching/internal/hubstate.Rider
export const Rider = z.object({
  ID: RiderID,
  Status: RiderStatus,
  // StatusChange records when the rider entered the current status.
  StatusChange: Time,
  // LastTripInteraction records interactions with a trip.
  // It is set when moving into or out of the `OnTrip` status.
  LastTripInteraction: Time,
  // PlannedShifts are the planned shifts for the rider. A rider can have multiple shifts per day
  PlannedShifts: z
    .array(Window)
    .nullish()
    .transform((a) => a ?? []),
  // VehicleID optional field to Vehicle assigned to this rider
  VehicleID: VehicleID.nullish(),
});
export type Rider = z.infer<typeof Rider>;

// RouteLegDetail contains the distance and duration information for a leg in a route (point A to point B)
//
// go type: github.com/goflink/dispatching/internal/fulfillment.RouteLegDetail
export const RouteLegDetail = z.object({
  // Duration is the routed duration estimate for a (potentially hypothetical) leg from the source directly to the destination of this route.
  Duration: Duration.nullish(),
  // Distance is the routed distance estimate for a (potentially hypothetical) leg from the source directly to the destination of this route.
  Distance: Meters.nullish(),
  // Cost is the cost in euros that is incurred from the rider for this leg
  Cost: z.number().nullish(),
});
export type RouteLegDetail = z.infer<typeof RouteLegDetail>;

// StackingFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.StackingFlags
export const StackingFlags = z.object({
  MaximumSize: z.number(),
  RiderWeightLimitKg: z.number(),
  Engine: StackingEngineFlags,
  RiderEquipment: RiderEquipmentFlags,
  LowRiderLoad: z.number(),
  HighRiderLoad: z.number(),
  MinimumGreatCircleDistanceSavingsRatio: Range(z.number()),
  MinimumRoutingTimeSavingsRatio: Range(z.number()),
  MaximumStackRoutedTimeIncreasePerIndirectDelivery: Range(Minutes),
  DetractorCutoffFactor: Range(z.number()),
  UseFulfillmentTimePermutations: z.boolean(),
  EmitStackMetrics: z.boolean(),
  EnabledExtraScorers: StringSlice,
  DisableOutsourcingInStacking: z.boolean(),
});
export type StackingFlags = z.infer<typeof StackingFlags>;

// TemperatureSensitiveOrdersFlags Flags concerning the handling of orders with hot, cold, or frozen food
//
// go type: github.com/goflink/dispatching/internal/flags.TemperatureSensitiveOrdersFlags
export const TemperatureSensitiveOrdersFlags = z.object({
  PrioritizeColdFood: z.boolean(),
  DelayColdFood: z.boolean(),
  HotFoodPreparationDuration: Minutes,
});
export type TemperatureSensitiveOrdersFlags = z.infer<
  typeof TemperatureSensitiveOrdersFlags
>;

// TripSelectionFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.TripSelectionFlags
export const TripSelectionFlags = z.object({
  EarliestTripStartBuffer: Minutes,
  PickingTimeFinishBuffer: Minutes,
  TripEstimationEndBuffer: Minutes,
});
export type TripSelectionFlags = z.infer<typeof TripSelectionFlags>;

// scheduledTripProposal has the hypthetic claim schedule
//
// go type: github.com/goflink/dispatching/internal/ui.scheduledTripProposal
export const scheduledTripProposal = z.object({
  // OrderIDs that make up the proposal
  order_ids: z
    .array(OrderID)
    .nullish()
    .transform((a) => a ?? []),
  // ExpectedClaimAt is when we expect the trip to be claimed
  expected_claim: Time,
  // ExpectedRiderID is the rider we expect to claim this proposal
  rider_id: RiderID,
  // Stuck indicates stuckedness
  stuck: z.boolean(),
});
export type scheduledTripProposal = z.infer<typeof scheduledTripProposal>;

//
// go type: github.com/goflink/dispatching/internal/stacking.score
export const score = Minutes;
export type score = z.infer<typeof score>;

// Bottlenecks is the current bottleneck of a hub and the historic bottlenecks
//
// go type: github.com/goflink/dispatching/internal/hubstate.Bottlenecks
export const Bottlenecks = z.object({
  Historic: z
    .array(BottleneckRange)
    .nullish()
    .transform((a) => a ?? []),
  Current: BottleneckRange,
});
export type Bottlenecks = z.infer<typeof Bottlenecks>;

// CanonicalTimestamps contains a single Timestamp for each order fulfillment event
//
// go type: github.com/goflink/dispatching/internal/fulfillment.CanonicalTimestamps
export const CanonicalTimestamps = z.object({
  Events: TimestampEvents,
  // EarlyClaimedAt with the introduction of outsourced orders it is possible that we receive a rider claim event
  // at any time before or after picking started/ packed. We will record the timestamp and handle the affected events
  // accordingly.
  EarlyClaimedAt: Time,
});
export type CanonicalTimestamps = z.infer<typeof CanonicalTimestamps>;

// CityDeliveryArea contains hub data for a whole city
//
// go type: github.com/goflink/dispatching/internal/hubs.CityDeliveryArea
export const CityDeliveryArea = z.object({
  id: z.string(),
  name: z.string(),
  delivery_areas: z
    .array(HubDeliveryArea)
    .nullish()
    .transform((a) => a ?? []),
});
export type CityDeliveryArea = z.infer<typeof CityDeliveryArea>;

// DeliveryOptionValidation the result of validating a delivery option token validation.
//
// go type: github.com/goflink/dispatching/internal/dispatching/api.DeliveryOptionValidation
export const DeliveryOptionValidation = z.object({
  // Valid indicates that the token ist still valid.
  valid: z.boolean(),
  // Message explaining validation result.
  message: z.string(),
  // DeliveryOption if the token is still valid this field describes the
  delivery_option: DeliveryOption,
});
export type DeliveryOptionValidation = z.infer<typeof DeliveryOptionValidation>;

// DeliveryProgressEvents contains real timestamps for events that have occurred and estimates for events that haven't yet occurred.
//
// Information on the various delivery milestone events.
//
// go type: github.com/goflink/dispatching/internal/dispatching/api.DeliveryProgressEvents
export const DeliveryProgressEvents = z.object({
  // Created indicates when the order was created
  created: DeliveryProgressEvent,
  // PickerAccepted indicates when picking started
  picker_accepted: DeliveryProgressEvent,
  // Packed indicates when picking was completed
  packed: DeliveryProgressEvent,
  // RidingStarted indicates our best understanding of when the order was on-route to the customers address.
  //
  // Stacked orders will not be reported as having riding `riding_started` until the rider starts to ride to
  // the particular order's delivery address. For example: For the second order in a stack `riding_started` is
  // estimated and reported as the point at which the rider leaves the first delivery address.
  //
  // Also, this is not the same as the problematic order-on-route timestamp in commercetools.
  riding_started: DeliveryProgressEvent,
  // Arrived indicates when the order arrived at the customer's house.
  arrived: DeliveryProgressEvent,
  // Delivered indicated that the order has been handed over to the customer.
  delivered: DeliveryProgressEvent,
});
export type DeliveryProgressEvents = z.infer<typeof DeliveryProgressEvents>;

// DirectRouteDetails contains the information about the trip's route information for that leg
//
// go type: github.com/goflink/dispatching/internal/fulfillment.DirectRouteDetails
export const DirectRouteDetails = z.object({
  // To is the details from the previous node in the route to the current location.
  To: RouteLegDetail.nullish(),
  // From is the details from the current location to the next node on the route
  From: RouteLegDetail.nullish(),
  // RiderHandlingCost contains the total rider handling (aka not-riding) time cost for the route in euros
  RiderHandlingCost: z.number().nullish(),
});
export type DirectRouteDetails = z.infer<typeof DirectRouteDetails>;

// DispatchingFlags ...
//
// go type: github.com/goflink/dispatching/internal/flags.DispatchingFlags
export const DispatchingFlags = z.object({
  MaxPickersNeededDisplay: z.number(),
  PlannedOrders: PlannedOrdersFlags,
  IdealTripStartStrategy: z.string(),
  Outsourcing: OutsourcingFlags,
  OffersEnabled: z.boolean(),
  TripOffers: TripOffersFlags,
  SmoothingLag: Minutes,
  CRMSteering: CRMSteeringFlags,
  TemperatureSensitive: TemperatureSensitiveOrdersFlags,
  ClaimabilityAtHubCountHorizon: Minutes,
  ClaimabilityTargetScaler: z.number(),
});
export type DispatchingFlags = z.infer<typeof DispatchingFlags>;

// ExtendedOrderInfo is order information that we return for the benefit of caller of our API,
// but which we don't actually use for any of our logic.
// By keeping this out of the main order representation, we keep that representation smaller and to-the-point.
// We also take advantage of the fact that this information is de facto immutable over the lifetime of the order.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.ExtendedOrderInfo
export const ExtendedOrderInfo = z.object({
  OrderNumber: OrderNumber,
  Customer: Customer,
  ExternalProvider: z.string(),
  LineItems: z
    .record(SKU, LineItem)
    .nullish()
    .transform((m) => m ?? {}),
});
export type ExtendedOrderInfo = z.infer<typeof ExtendedOrderInfo>;

// JustInTimeStackingFlags hold flags to tweak and control the just in time stacking process
//
// go type: github.com/goflink/dispatching/internal/flags.JustInTimeStackingFlags
export const JustInTimeStackingFlags = z.object({
  DurationUntilRiderArrival: Minutes,
  AllowRestackingWithinMinutesAfterFreezing: Minutes,
  AllowRestackingMaxStacksize: z.number(),
  TripSelection: TripSelectionFlags,
});
export type JustInTimeStackingFlags = z.infer<typeof JustInTimeStackingFlags>;

// OutsourcingQuoteAttempt records the error and quotes when attempting to create the quote
//
// go type: github.com/goflink/dispatching/internal/fulfillment.OutsourcingQuoteAttempt
export const OutsourcingQuoteAttempt = z.object({
  // Quotes are the list of quotes given to us from delivery-outsourcing.
  // Dispatching will select which quote to use for fulfilling the order
  Quotes: z
    .array(OutsourcingQuote)
    .nullish()
    .transform((a) => a ?? []),
  // Error is the error that delivery-outsourcing returns, if it exists. That way we can know now to call d-o again
  Error: OutsourcingError.nullish(),
});
export type OutsourcingQuoteAttempt = z.infer<typeof OutsourcingQuoteAttempt>;

// PlannedWindowResponse response type for a planned window response
//
// go type: github.com/goflink/dispatching/internal/ui.PlannedWindowResponse
export const PlannedWindowResponse = z.object({
  timestamp: Time,
  hub: HubSlug,
  windows: z
    .array(PlannedWindow)
    .nullish()
    .transform((a) => a ?? []),
});
export type PlannedWindowResponse = z.infer<typeof PlannedWindowResponse>;

// factoredScore combines a promoter score and a detractor score.
// These can then be blended into a single score in a use-case specific way.
// In particular, we have different blending approaches for cutoff and priority use cases.
//
// go type: github.com/goflink/dispatching/internal/stacking.factoredScore
export const factoredScore = z.object({
  // PromoterScore is a positive score for this trip. The more positive the PromoterScore, the more benefits this trip has for Flink.
  Promoter: score,
  // DetractorScore is a negative score for this trip. The more negative the DetractorScore, the more downside this trip has for customers.
  Detractor: score,
});
export type factoredScore = z.infer<typeof factoredScore>;

// historicOrderStateSummary has the hubStateHistorySummary and the eta of an order at a given timepoint
//
// go type: github.com/goflink/dispatching/internal/ui.historicOrderStateSummary
export const historicOrderStateSummary = z.object({
  state_history_summary: HubStateHistorySummary,
  estimates: PhaseDurations,
});
export type historicOrderStateSummary = z.infer<
  typeof historicOrderStateSummary
>;

// priorityScore is a score used for trip/merge prioritisation.
//
// go type: github.com/goflink/dispatching/internal/stacking.priorityScore
export const priorityScore = score;
export type priorityScore = z.infer<typeof priorityScore>;

// CountryDeliveryArea contains hub data for a whole country
//
// go type: github.com/goflink/dispatching/internal/hubs.CountryDeliveryArea
export const CountryDeliveryArea = z.object({
  id: z.string(),
  name: z.string(),
  cities: z
    .array(CityDeliveryArea)
    .nullish()
    .transform((a) => a ?? []),
});
export type CountryDeliveryArea = z.infer<typeof CountryDeliveryArea>;

// DeliveryProgress contains details of the progress of the delivery of an order.
//
// go type: github.com/goflink/dispatching/internal/dispatching/api.DeliveryProgress
export const DeliveryProgress = z.object({
  // ModelVersion of the model used to estimate delivery progress.
  //
  // Required: true
  model_version: ModelVersion,
  // DeliveryMethod describes how the goods get to the customer. Depending on the `delivery_method` different
  // pieces of information may or may not be available.
  //
  // Possible Values:
  // <ul>
  //  <li> `ASAPDeliveryMethod` indicates that Flink will deliver this order to the customer as soon as possible.
  //       The delivery can be performed by a Flink rider or by an outsourced rider commissioned by Flink.</li>
  //  <li> `PlannedDeliveryMethod` indicates that Flink will deliver this order to the customer as soon as possible.
  //       The delivery can be performed by a Flink rider or by an outsourced rider commissioned by Flink.
  //       In this case we do provide the `planned_delivery_window` information.
  //       Please note that estimates should only be shown when the delivery window has started.</li>
  //  <li> `PickupDeliveryMethod` indicates that this order will be picked up at the hub by the customer. As the picking
  //       does not start until the customers come to the hub, no meaningful estimates are returned here.</li>
  // 	<li> `ExternalDeliveryMethod` indicates that the order will be delivered to the end customer, but the delivery
  // 	     is organised by the marketplace partner that the order came in through. Flink will (eagerly) pack the
  //       order for pickup at the hub.
  //       As Flink does not "own" the relationship with the customer and has no insight into how the delivery is organised,
  //       we do not return meaningful estimates in this case.</li>
  // </ul>
  delivery_method: DeliveryMethod,
  // Hub information
  hub: DeliveryProgressHubInfo,
  // Delivery coordinate
  delivery_coordinates: Coordinates,
  // PlannedDeliveryWindow for planned deliveries the time window that was chosen by the customer.
  //
  // <b>Note:</b> This is only returned for planned delivery orders where `delivery_method = "PlannedDeliveryMethod"`.
  //
  // required: false
  planned_delivery_window: Window.nullish(),
  // ETAInterval is a confidence interval for the ETA (`events.arrived.time`)
  //
  // <b>Note:</b> PTis value is only provided for `DeliveryMethod`s that have a meaningful ETA. These
  // are:
  // ASAP
  // PLANNED (after the planned delivery window has started, before that the ETA might still be wildly off and
  // as of now will also not be displayed to the customer)
  //
  // <b>Note further:</b> TODO: This value isn't populated yet.
  //
  // required: false
  eta_interval: Window.nullish(),
  // PromisedDeliveryTime is the delivery time based on adding the last delivery duration that was shown to the user
  // at order creation time to the order creation time.
  //
  // Note that the last time the user sees this value can be a long time before they place the order. It is also
  // important to note that this time is passed to the cart service at cart creation time and managed by
  // the respective app.
  promised_delivery_time: Time,
  // InitialEstimatedDeliveryTime is the first estimate for the delivery time after the order has been placed
  //
  // Note that this is work in progress and, as a stopgap solution, the field is populated with the estimated
  // delivery time at checkout, which is just before the order gets placed.
  initial_eta: Time,
  // Events contains real timestamps for events that have occurred already and
  // estimates for events that haven't occurred yet.
  events: DeliveryProgressEvents,
});
export type DeliveryProgress = z.infer<typeof DeliveryProgress>;

// HubFlags is a collection with all the flags used in the dispatching code
//
// go type: github.com/goflink/dispatching/internal/flags.HubFlags
export const HubFlags = z.object({
  Global: GlobalFlags,
  Dispatching: DispatchingFlags,
  PickerQueue: PickerQueueFlags,
  Stacking: StackingFlags,
  TimeEstimation: TimeEstimationFlags,
  DeploymentFlags: DeploymentFlags,
  JustInTimeStacking: JustInTimeStackingFlags,
});
export type HubFlags = z.infer<typeof HubFlags>;

// Order is the core order representation used in our system, including in our hub state abstraction.
// It excludes information about the delivery of the order, which ends up on a separate Trip structure.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Order
export const Order = z.object({
  ID: OrderID,
  DeliveryCoords: Coordinates,
  Hub: HubSlug,
  PDT: Duration,
  ETAAtCheckout: Duration,
  DeliveryOptionType: DeliveryType,
  // If this is a planned delivery this field contains the delivery window, otherwise it is nil
  delivery_window: DeliveryWindow.nullish(),
  Items: OrderItems,
  ContainerIDs: z
    .array(ContainerID)
    .nullish()
    .transform((a) => a ?? []),
  Timestamps: CanonicalTimestamps,
  // Timestamps for the external kitchen. If non-nil, the order has items being prepared in an external kitchen.
  KitchenTimestamps: KitchenTimestamps.nullish(),
  // IsPartiallyExternallyPrepared true if the order has items being prepared by an external provider and need to be picked up.
  IsPartiallyExternallyPrepared: z.boolean(),
  // FrozenAt is the time at which an order was frozen into a stack. This is currently used in "stacking for claimability"
  // i.e. have the top priority trips immutable to ensure a better stacker/picking handover process
  FrozenAt: Time.nullish(),
});
export type Order = z.infer<typeof Order>;

// deliverySetSummary summarises a set of deliveries, regardless of the delivery sequence
//
// go type: github.com/goflink/dispatching/internal/stacking.deliverySetSummary
export const deliverySetSummary = z.object({
  DeliveryCount: z.number(),
  ItemCount: z.number(),
  Weight: Grams,
  ContainerCounts: ContainerCounts,
  PriorityBoost: priorityScore,
  CanOnlyBeMergedWithFullyPacked: z.boolean(),
  IsFullyPacked: z.boolean(),
  ExpectedRemainingPickingDuration: Duration,
  ExpectedRiderPreparationDuration: Duration,
});
export type deliverySetSummary = z.infer<typeof deliverySetSummary>;

// Delivery is an Order augmented with an estimate from the router of how long it would take to cycle directly from the hub to the order's delivery location.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Delivery
export const Delivery = z
  .object({
    // RoutedFromHub is the routed duration estimate for a (potentially hypothetical) leg from the hub directly to the delivery location.
    RoutedFromHub: Duration.nullish(),
    // RoutedFromHub is the routed duration estimate for a (potentially hypothetical) leg from the delivery location directly back to the hub.
    RoutedToHub: Duration.nullish(),
    // RoutedFromHubDistance is the routed distance estimate for a (potentially hypothetical) leg from the hub directly to the delivery location.
    RoutedFromHubDistance: Meters.nullish(),
    // RoutedToHubDistance is the routed distance estimate for a (potentially hypothetical) leg from the delivery location directly back to the hub.
    RoutedToHubDistance: Meters.nullish(),
    // QuoteAttempt is the information on us getting a delivery quote
    QuoteAttempt: OutsourcingQuoteAttempt.nullish(),
    // DirectRouteDetails contains the distance/time/cost information of this delivery if it were hypothetically delivered directly from the hub and the rider returns immediately after delivery
    DirectRouteDetails: DirectRouteDetails.nullish(),
  })
  .merge(Order);
export type Delivery = z.infer<typeof Delivery>;

// FlagsWithMetadata contains the flags that are used to control the behavior of the
// application plus the metadata for each field that is fetched from a remote
// source.
//
// go type: github.com/goflink/dispatching/internal/ui.FlagsWithMetadata
export const FlagsWithMetadata = z.object({
  Flags: HubFlags,
  Meta: z
    .array(PathMetadata)
    .nullish()
    .transform((a) => a ?? []),
});
export type FlagsWithMetadata = z.infer<typeof FlagsWithMetadata>;

// Order is our representation of a Flink order together with delivery information and information we only need at the edge of our system.
// TODO: this type should be removed and we should use the proto one returned by the orders service.
//
// go type: github.com/goflink/dispatching/internal/ordersx.Order
export const CTOrder = z
  .object({
    Version: OrderVersion,
    DeliveryType: DeliveryType,
    TrackingInformation: TrackingInformation,
    StackedOrderIDs: z
      .array(OrderID)
      .nullish()
      .transform((a) => a ?? []),
    RiderID: RiderID,
    RiderBackAtHubAt: Time,
    OutsourcingConfirmation: OutsourcingConfirmation.nullish(),
    State: State,
  })
  .merge(Order);
export type CTOrder = z.infer<typeof CTOrder>;

// PickingTask represents a picking task to be performed
//
// go type: github.com/goflink/dispatching/internal/pickerqueue.PickingTask
export const PickingTask = z.object({
  Group: Group,
  LatestIdealized: Window,
  Order: Order.nullish(),
});
export type PickingTask = z.infer<typeof PickingTask>;

// CombinedOrder combines our order and commercetool's order types
//
// go type: github.com/goflink/dispatching/internal/ui.CombinedOrder
export const CombinedOrder = z.object({
  Dispatching: CTOrder,
  Commercetools: RawCTOrder,
  DeliveryProgress: DeliveryProgress.nullish(),
  // OrderCommands a list of hub state summaries that have commands which touched the given order
  OrderHistory: z
    .array(historicOrderStateSummary)
    .nullish()
    .transform((a) => a ?? []),
  ExtendedOrderInfo: ExtendedOrderInfo,
});
export type CombinedOrder = z.infer<typeof CombinedOrder>;

// An IndirectDelivery is a delivery that will be made after the rider has already performed other deliveries on a trip.
// I.e., an IndirectDelivery is a non-first Delivery in a DeliverySequence.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.IndirectDelivery
export const IndirectDelivery = z
  .object({
    // RoutedFromPrevious is the routed duration estimate from the previous delivery.
    RoutedFromPrevious: Duration.nullish(),
    // RoutedFromPreviousDistance is the routed distance estimate from the previous delivery.
    RoutedFromPreviousDistance: Meters.nullish(),
  })
  .merge(Delivery);
export type IndirectDelivery = z.infer<typeof IndirectDelivery>;

//
// go type: github.com/goflink/dispatching/internal/stacking.delivery
export const delivery = z
  .object({
    // GreatCircleDistanceFromHub is the great-circle distance between the delivery location and the hub.
    GreatCircleDistanceFromHub: Meters,
    RoutedDirectToHub: Duration,
    RoutedDirectFromHub: Duration,
    ExpectedSoloRiderPreparationDuration: Duration,
    ExpectedDirectEnRouteToArrivalDuration: Duration,
    ExpectedRemainingPickingDuration: Duration,
    UnstackedSquaredDeliveryUrgency: z.number(),
  })
  .merge(Delivery);
export type delivery = z.infer<typeof delivery>;

// DeliverySequence is a sequence of deliveries to be made.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.DeliverySequence
export const DeliverySequence = z.object({
  // FirstDelivery is the first delivery in this sequence.
  FirstDelivery: Delivery,
  // IndirectDeliveries are the deliveries that will be made after the rider has completed the first delivery.
  IndirectDeliveries: z
    .array(IndirectDelivery)
    .nullish()
    .transform((a) => a ?? []),
});
export type DeliverySequence = z.infer<typeof DeliverySequence>;

//
// go type: github.com/goflink/dispatching/internal/stacking.deliveryInSequence
export const deliveryInSequence = z
  .object({
    // ExpectedClaimedToArrivalDuration is the expected duration from the time the delivery is claimed to the time it arrives at the delivery location.
    ExpectedClaimedToArrivalDuration: Duration,
    // IdealTripStart is the time at which this delivery would ideally like its trip to start, so that its expected delivery time matches its PDT.
    IdealTripStart: Time,
    DeliveryUrgency: DeliveryUrgency,
    AdditionalSquaredDeliveryUrgency: z.number(),
  })
  .merge(delivery);
export type deliveryInSequence = z.infer<typeof deliveryInSequence>;

// OutsourcedTrip is a trip that has been outsourced to an external delivery provider (e.g. Uber). It starts as a
// regular trip proposal but is removed from the trip proposal collection once we have made the the decision to
// outsource and accepted a quote from an external provider.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.OutsourcedTrip
export const OutsourcedTrip = z
  .object({
    // Quote references the quote of an external delivery provider. It expires after some time and is
    // accepted once we successfully booked an external trip.
    Quote: OutsourcingQuote,
    // Confirmation references information of the outsourced trip made with a delivery provider.
    // It is set when we successfully booked an external trip.
    Confirmation: OutsourcedTripConfirmation.nullish(),
    // FulfillmentTripID is the ID for the trip in the fulfillment service. The fulfillment systems tracks outsourced
    // trips in order to support the corresponding delivery events (e.g. arrived, completed) and to provide location
    // updates. It is set once the handover has happened and the rider is moving to the first delivery location.
    FulfillmentTripID: TripID.nullish(),
    // ReportedLegETA is the most recent estimated time of leg ETA reported by the Rider App.
    ReportedLegETA: ReportedLegETA.nullish(),
    // StartedAt is the time when the external riders leaves the hubs towards the delivery location.
    StartedAt: Time,
    // CompletedAt is the time when the external rider delivered the last order, they do not return to the hub. This
    // is unlike Flink-delivered trips.
    CompletedAt: Time,
    // CanceledAt is the time when the trip was canceled. In this case, the cancellation is driven by the external
    // provider and not by the cancellation of individual orders.
    CanceledAt: Time,
  })
  .merge(DeliverySequence);
export type OutsourcedTrip = z.infer<typeof OutsourcedTrip>;

// Trip represents a trip that has been started by a rider.
// Trips can be based on previous proposals by the dispatching system but this is not enforced.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.Trip
export const Trip = z
  .object({
    // ID unique identifier of a trip set by the fulfillment service.
    ID: TripID,
    // RiderID unique identifier of the rider delivering the orders in a trip
    RiderID: RiderID,
    // StartedAt is set when a trip is claimed by a rider
    StartedAt: Time,
    // ReportedLegETA is the most recent estimated time of leg ETA reported by the Rider App.
    ReportedLegETA: ReportedLegETA.nullish(),
    // CompletedAt is set when the rider has returned to the hub
    CompletedAt: Time,
  })
  .merge(DeliverySequence);
export type Trip = z.infer<typeof Trip>;

// TripProposal is a proposal from the dispatching system of a sequence of deliveries that could for the basis of a trip.
// TripProposals can be thought of as a Trip that has not yet been claimed by a rider.
//
// go type: github.com/goflink/dispatching/internal/fulfillment.TripProposal
export const TripProposal = z
  .object({
    // Locked indicates that this proposal must not be modified by the stacker.
    Locked: z.boolean(),
    // Offer indicates that this proposal has been offered to an internal rider.
    Offer: Offer.nullish(),
    // TentativeRiderID is the ID of the rider that has tentatively been assigned to this trip proposal.
    TentativeRiderID: RiderID.nullish(),
  })
  .merge(DeliverySequence);
export type TripProposal = z.infer<typeof TripProposal>;

// scoredTripProposal is the mergeable trip representation used by ScorerSavingsAndAdditionalOvershoot
//
// go type: github.com/goflink/dispatching/internal/stacking.scoredTripProposal
export const scoredTripProposal = z
  .object({
    // Deliveries contains information about the deliveries that will be performed as a part of this trip.
    Deliveries: z
      .array(deliveryInSequence)
      .nullish()
      .transform((a) => a ?? []),
    DeliverySequence: DeliverySequence,
    TotalRouterDurationToLastOrder: Duration,
    RiderTimeSavings: Duration,
  })
  .merge(deliverySetSummary);
export type scoredTripProposal = z.infer<typeof scoredTripProposal>;

// HubState represents the current state of a hub
//
// Queries to be computed from a HubState are:
// - NextOrderToPick: returns the next order that should be picked by the pickers, // if there is an order that should be picked at this time.
// - TripsReadyForRider: returns the trips that are ready for a rider to claim them.
// - RecentQueuingForPickerTime: returns a recent average of the time spent queueing for a picker by P0 orders that have recently started being picked.
// - RecentQueuingForRiderTime: returns a recent average of the time spent queueing for a rider by D0 orders that have recently been claimed by a rider.
// - RiderCounts: returns probabilistic (discounted based on how likely we think it is that the riders are actually still active) counts of the hub's active riders.
//
// go type: github.com/goflink/dispatching/internal/hubstate.HubState
export const HubState = z.object({
  // ID is a unique identifier for the state
  //
  // We use this ID to reference a specific state instance outside the service.
  ID: UUID,
  // Hub is the hub this state represents.
  Hub: HubSlug,
  // Revision is a counter of how many times this state has been updated.
  Revision: z.number(),
  // Timestamp is the time when the state was last updated. When a command is applied to the state, this field
  // is set to the current timestamp prior to the command being actually applied. This means that commands can
  // use it to reference time.
  Timestamp: Time,
  // Coordinates is the geolocation of this hub.
  Coordinates: Coordinates,
  // FulfillmentStartTime is the time at which fulfillment should start at this hub.
  // If this value is non-zero, fulfillment is paused until this time is reached.
  FulfillmentStartTime: Time,
  // InputParameters contains input parameters that were used to derive this hub state (e.g. for stacking).
  // They are used to calculate smoothed values when augmenting this state or creating the next state.
  // Most code interested in using smoothed values should use the updated smoothed values from the augmented state.
  InputParameters: InputParameters,
  // SelectedStackingDetails is the set of details on how tripproposals that the hubstate is using were generated
  SelectedStackingDetails: StackingDetails,
  // TripProposals is the list of all current trip proposals for a hub. Trip proposals are ephemeral and remain
  // candidates for stacking. Once a trip proposal is accepted by a rider (claimed) it is removed from this list.
  TripProposals: z
    .array(TripProposal)
    .nullish()
    .transform((a) => a ?? []),
  // Trips are trips that have been started by riders.
  // They can be based on previous proposals by the dispatching system but this is not enforced.
  // Trips are tracked for 2 hours after they start (even after they are completed),
  // so this collection can be used for operations like determining recent queuing times.
  Trips: z
    .array(Trip)
    .nullish()
    .transform((a) => a ?? []),
  // RecentlyUnclaimedTrips keeps track of all trip IDs that have been recently unclaimed. We use this to check whether we
  // are trying to apply any trip commands (e.g. claim trip) to a trip that does not exit anymore. This helps to cope
  // with out-of-order pubsub events and other issues that might arise from that. The collection is cleaned after some
  // time.
  RecentlyUnclaimedTrips: z
    .record(TripID, Time)
    .nullish()
    .transform((m) => m ?? {}),
  // OutsourcedTrips is the list of all trips that are outsourced to external delivery providers (e.g. Uber).
  // They start as regular trip proposals but are moved to this collection as soon as they are selected to be outsourced.
  // The selection is based on conditions at the hub like the rider/order ratio. Outsourced orders will in
  // general remain in this collection for their lifetime, but might be move back to trip proposals if
  // we fail to book the outsourced trip.
  OutsourcedTrips: z
    .array(OutsourcedTrip)
    .nullish()
    .transform((a) => a ?? []),
  // RecentlyCancelledOutsourcedTrips keeps track of all outsourced trip IDs that have been recently cancelled. This prevents us from
  // reusing a cancelled outsourced trip. For instance, if we receive a quote or its confirmation after its cancellation.
  // The collection is cleaned after some time.
  RecentlyCancelledOutsourcedTrips: z
    .record(QuoteID, Time)
    .nullish()
    .transform((m) => m ?? {}),
  // ClickAndCollectOrders are orders that the customer will pick up in the hub.
  // They are not dispatched to pickers (pickers manually start scanning them using a QR code),
  // and they don't participate in trips as the customer will take the order from the hub.
  ClickAndCollectOrders: z
    .array(Order)
    .nullish()
    .transform((a) => a ?? []),
  // InStorePaymentOrders are orders that the customer places at a kiosk in a flink hub.
  // They are immediately dispatched to pickers ahead of all other orders,
  // and they don't participate in trips as the customer will take the order from the hub.
  InStorePaymentOrders: z
    .array(Order)
    .nullish()
    .transform((a) => a ?? []),
  // ExternalDeliveryOrders has the orders that will be delivered by external service providers (e.g. Uber Eats).
  // They are dispatched to pickers similarly to the orders that are part of trips, but collected and delivered
  // by external riders. As a result we don't throttle picking of these orders based on the availability of orders
  // for Flink riders. That is the case with the Carrefour Sprint orders formerly fulfilled by Cajoo that are delivered
  // by Uber Eats.
  ExternalDeliveryOrders: z
    .array(Order)
    .nullish()
    .transform((a) => a ?? []),
  // RecentlyFinalizedOrders has the order IDs of all orders that aren't delivered by Flink (click-and-collect, Uber Eats, ...) that
  // have been recently fulfilled, or IDs of orders that have been recently cancelled. This collection does not include regular orders,
  // which are maintained in the `Trips` collection.
  // The orders are kept in the structure long enough to have related events expire.
  RecentlyFinalizedOrders: z
    .record(OrderID, Time)
    .nullish()
    .transform((m) => m ?? {}),
  // Riders aggregates timestamps around riders, like when a rider last claimed a trip or when they became offline.
  // The data itself does not inform the status of the rider, but the timestamps can be used to estimate the number
  // of riders at the hub that are either available, working, or their way back to the hub.
  // The entries in this map also expire. The newest timestamp is used to take this decision.
  Riders: z
    .record(RiderID, Rider)
    .nullish()
    .transform((m) => m ?? {}),
  // Vehicles maintains a list of vehicles at the hub.
  Vehicles: z
    .record(VehicleID, Vehicle)
    .nullish()
    .transform((m) => m ?? {}),
  // ThrottledMerges is the counts of merges that had been throttled during stacking due to trip proposal constraints
  ThrottledMerges: TripProposalCounts,
  // Bottlenecks determines where the bottleneck is in the hub
  Bottlenecks: Bottlenecks,
  // DoNotCombineOrderSets : set of orders that should not be considered valid to stack automatically
  DoNotCombineOrderSets: z
    .array(Set(OrderID))
    .nullish()
    .transform((a) => a ?? []),
});
export type HubState = z.infer<typeof HubState>;

//
// go type: github.com/goflink/dispatching/internal/stacking.scored
export const scored = z.object({
  // Trip is the trip resulting from the merge
  Trip: scoredTripProposal,
  // Score is a factoredScore score with scores for the benefits and deficiencies of this trip.
  Score: factoredScore,
  // PermutationPriorityBoost is a boost to the priority of this trip to be used when selecting which permutation of a given order set should be selected.
  // It is not included in the score used to select which merge should be selected when considering different order sets.
  PermutationPriorityBoost: priorityScore,
  // ConstituentPriorityBoost is a score of how much of a priority boost this trip should have, based on its constituent deliveries.
  // Computing the PriorityScore for a merge between two trips, the PromoterScore and DeficiencyScore are additive but the ConstituentPriorityBoost is not.
  ConstituentPriorityBoost: priorityScore,
  // CanBeMerged indicates whether this can be merged again
  CanBeMerged: z.boolean(),
  // TotalFulfillmentTime for a given trip the sum of riding time as fulfillment time for all orders
  FulfillmentTime: Duration.nullish(),
  // TotalFulfillmentTime for a given trip the sum of riding time as fulfillment time for all orders
  RidingTime: Duration.nullish(),
});
export type scored = z.infer<typeof scored>;

// HistoricHubState represents a specific state instance that is exported
//
// go type: github.com/goflink/dispatching/internal/hubstate/database.HistoricHubState
export const HistoricHubState = z.object({
  ID: UUID,
  Hub: HubSlug,
  Timestamp: Time,
  State: HubState,
  CommandName: z.string().nullish(),
  CommandData: JSON.nullish(),
  PreviousID: UUID.nullish(),
  RiderCounts: HubRiderCounts.nullish(),
  HeldFromPicking: OrderIDSet.nullish(),
});
export type HistoricHubState = z.infer<typeof HistoricHubState>;

// merge represents the result of merging two trips.
//
// go type: github.com/goflink/dispatching/internal/stacking.merge
export const merge = z
  .object({
    // CutoffScore is a score used to determine whether we are willing to perform this merge or not.
    // If this score is positive, this merge is worth performing.
    // If it isn't, the downsides of this merge are considered to outweight its upsides.
    CutoffScore: score,
    // PriorityScore is a score of how eager we are to perform this merge.
    // This incorporates the MergeBenefitScore adding in the trip's ConstituentPriorityBoost.
    PriorityScore: priorityScore,
  })
  .merge(scored);
export type merge = z.infer<typeof merge>;

// stackingRetraceResponse has information to retrace and all
//
// go type: github.com/goflink/dispatching/internal/ui.stackingRetraceResponse
export const stackingRetraceResponse = z.object({
  original_state: HubState,
  trace: z
    .array(Event)
    .nullish()
    .transform((a) => a ?? []),
  restacked: z
    .array(TripProposal)
    .nullish()
    .transform((a) => a ?? []),
  throttled: TripProposalCounts,
  selected_algorithm: z.string(),
  frozen_selected_algorithm: z.string(),
  available_stacking_algorithms: z
    .array(z.string())
    .nullish()
    .transform((a) => a ?? []),
});
export type stackingRetraceResponse = z.infer<typeof stackingRetraceResponse>;

// statePlus has the hubState + interesting (from a UI perspective) derived information
//
// go type: github.com/goflink/dispatching/internal/ui.statePlus
export const statePlus = z.object({
  State: HubState,
  withheld_from_picking: OrderIDSet,
  prevailing_bottleneck: Bottleneck.nullish(),
  pickers_needed: z.number().nullish(),
});
export type statePlus = z.infer<typeof statePlus>;
